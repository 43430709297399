import React, { useEffect, useReducer, useState } from 'react';
import './AddFile.css';

function AddFile() {

    const [images, setImages] = useState([]);
    const [reducerValue, forceUpdate] = useReducer(x => x+1, 0);

    useEffect(() => {
        
    }, [reducerValue])

    const upload = async (event) => {
        const files = [...event.target.files].map(file => {
          const reader = new FileReader();
          return new Promise(resolve => {
            reader.onload = () => resolve(reader.result);
            reader.readAsDataURL(file);
          });
        });
        const res = await Promise.all(files);

        if (res.length === event.target.files.length) {
            for (let index = 0; index < res.length; index++) {
                images.push({key: index, name: event.target.files[index].name, data: res[index]});        
            }            
        } else {
            alert("Some error occured while selecting files");
        }

        // setImages(uploadedimages);

        // images.map(value => {
        //     console.log(value.name);
        //     console.log(value.data);
        // })

        forceUpdate();       
    }
 
    const openImage = (image) => {
        var win = window.open();
        win.document.write('<img src="' + image  + '"style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100vw; height:100vh;" allowfullscreen></img>');
    }

    const removeImage = (key) => {
        setImages(() => {
            return images.filter((value) => {
                return value.key != key;
            })
        })
        
        forceUpdate();
    }

  return (  
    <div>
        <main className='container px-10 mt-20'>
            <div>
                <form>
                    <input type='file' onChange={upload} className='border-1 rounded-md border-gray-900' multiple="multiple" accept='image/png, image/jpeg, image/webp' />
                </form>

                <div id='uploadedimages' className=' mt-10 grid grid-cols-2 md:grid-cols-4 gap-1 '>                    
                    {
                        images.map((value) => {
                            return(
                                <div className='flex flex-col'>
                                    <div                                         
                                        className='w-fit bg-gray-200 hover:bg-gray-400 p-2 m-2 rounded-md hover:scale-[1.02] transform transition duration-500'
                                    >
                                        <button onClick={() => {openImage(value.data)}}>{value.name}</button>
                                        <button onClick={() => {removeImage(value.key)}} className='mx-1 px-1 rounded-sm bg-gray-300 hover:bg-gray-100'>X</button>
                                    </div> 
                                </div>
                            )
                        })   
                    } 
                </div>
                
            </div>
        </main>
    </div>
  )
}

export default AddFile
