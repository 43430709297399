import React from 'react';
import $ from 'jquery';
import Footer from '../../Footer/Footer';
import Navbar from '../../Navbar/Navbar';

import img1 from '../../../assets/iprojects/SIPJR.png';
import {Helmet} from "react-helmet";
import logo from '../../../assets/logo.png';

function SIPJunior() {

    $(document).ready(() => {
        document.body.scrollTop = 0; 
        document.documentElement.scrollTop = 0;

    })

  return (
    <div className='noselect' 
        // style={{height: '70rem'}}
    >
        <Helmet>
            <title>TAHK - SIP Junior</title>
            <meta
                name="description"
                content="A Sustainibility camp for kids"
            />
            <meta property="og:url" content={document.location.href} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content="TAHK - SIP Junior" />
            <meta property="og:description" content="A Sustainibility camp for kids" />
            <meta property="og:image" content={logo}/>
            <link rel="image_src" href={logo} />
        </Helmet>

        <Navbar/>

       
        <div class="py-28 bg-white ">  
        <div class="container m-auto px-6 text-gray-600 md:px-12 xl:px-6">
            <div class="space-y-6 md:space-y-0 md:flex md:gap-6 lg:items-center lg:gap-12">
                <div class="md:5/12 lg:w-5/12">
                <img src={img1} className='rounded-md shadow-lg' alt="image" loading="lazy" width="" height=""/>
                </div>
                <div class="md:7/12 lg:w-7/12">
                <h2 class="text-2xl text-sky-800 font-bold md:text-4xl">SIP Jr.</h2>
                <p class="mt-6 text-gray-600">We are not inheriting the planet from our ancestors but borrowed it from future generations and thereby making us responsible to nurture the next generation on the sustainability pathway. Make future owners mindful & take charge of their future natural habitat. 4 weeks of fun-learning camp for your kids to learn the values & importance of their mother nature. 
                    <br/>Fun games & activities with the peer learning experience for your little one to help them connect with nature & learn about sustainable living. 
                </p>
                <p class="mt-6 text-gray-600">
                    TAHK SIP Jr. Kids Camp started with summer camp filled with fun and learning Sustainability making it world's first of its kind program launched by former Mrs India on World Environment Day June 5, 2021 as world's first summer camp for engaging kids into sustainability. 
                     <br/><br/>
                     <a href='https://docs.google.com/forms/d/e/1FAIpQLSe0T98UP2Bc3UKSIhiGEe-D-R0F6wlwUo9HaWIwBYUjkIq79g/viewform' target='_blank' className='text-sky-600 hover:underline'> 
                        Click here
                     </a> 
                     &nbsp;to engage your kids in <p className='inline text-blue-600'>S</p><p className='inline text-amber-700'>I</p><p className='inline text-blue-600'>P</p> <p className='inline text-red-600'>Jr.</p> ~ Four Weeks Kids Camp!
                </p>
                {/* <p class="mt-4 text-gray-600"> Nobis minus voluptatibus pariatur dignissimos libero quaerat iure expedita at? Asperiores nemo possimus nesciunt dicta veniam aspernatur quam mollitia.</p> */}
                </div>
            </div>
        </div>
        </div>       
        
        <div id='footer' className='mt-12'>
        <Footer/>
        </div>
        
        
    </div>
  )
}

export default SIPJunior