import React, { useEffect, useReducer, useState } from 'react';

// import { CKEditor } from '@ckeditor/ckeditor5-react';
// import Editor from 'customckeditor5/build/ckeditor'; 
import { v4 as uuid } from "uuid";
import { CKEditor } from 'ckeditor4-react';
import PostService from '../../../services/PostService';
import OrganizationService from '../../../services/OrganizationService';
import NotFound404 from '../../NotFound404/NotFound404';
import countries from './CountryWiseName';

// import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

function AddPost() {

    const [media, setMedia] = useState([]);
    const [urls, setUrls] = useState([]);
    const [uploadedimages, setUploadedImages] = useState([]);
    const [isAuthorized, setIsAuthorized] = useState(false);
    const [country, setCountry] = useState('');
    const [reducerValue, forceUpdate] = useReducer(x => x+1, 0);

    const [content, setContent] = useState('');

    const jwtDecode = (token) => {
        var base64Payload = token.split('.')[1];
        var payload = atob(base64Payload);
        return JSON.parse(payload.toString());
    }

    useEffect(() => {
        OrganizationService.validateToken().then(async (response) => {
            if(response.data.status === 200) {
                const accessToken = sessionStorage.getItem('accessToken');
                const payload = await jwtDecode(accessToken);
                if(payload.role === 'admin') {
                    setCountry('');
                } else if(payload.role === 'viewer') {
                    setCountry(countries[payload.country] ? countries[payload.country] : payload.country);
                }                
                setIsAuthorized(true);
            } else {
                setIsAuthorized(false);
            }
        }, [])
        
        // document.body.scrollTop = 0; 
        // document.documentElement.scrollTop = 0;
    }, [reducerValue])

    const update = async (event) => {
        const files = [...event.target.files];
        
        const uploadedFiles = files.map(file => {
          const reader = new FileReader();
          return new Promise(resolve => {
            reader.onload = () => resolve(reader.result);
            reader.readAsDataURL(file);
          });
        });
        const res = await Promise.all(uploadedFiles);

        if (res.length === event.target.files.length) {
            const prevLength = uploadedimages.length
            for (let index = 0; index < res.length; index++) {
                uploadedimages.push({key: index + prevLength, name: event.target.files[index].name, data: res[index]});   
                media.push({key: index + prevLength, file: files[index]})     
            }            
        } else {
            alert("Some error occured while selecting files");
        }

        forceUpdate();       
    }

    const addUrl = () => {
        const url = document.getElementById('url');
        if(url.value !== ''){
            const vdoId = youtube_parser(url.value);
            if(vdoId){
                const videoUrl = "https://www.youtube.com/embed/" + vdoId;
                // console.log(videoUrl);
                const prevLength = urls.length;
                urls.push({key: prevLength+1, url: videoUrl});
                url.value = ''
            }
            else {
                alert("Not a youtube video url");
            }
        }

        forceUpdate();
    }

    function youtube_parser(url){
        var regExp = /^https?\:\/\/(?:www\.youtube(?:\-nocookie)?\.com\/|m\.youtube\.com\/|youtube\.com\/)?(?:ytscreeningroom\?vi?=|youtu\.be\/|vi?\/|user\/.+\/u\/\w{1,2}\/|embed\/|watch\?(?:.*\&)?vi?=|\&vi?=|\?(?:.*\&)?vi?=)([^#\&\?\n\/<>"']*)/i;
        var match = url.match(regExp);
        return (match && match[1].length==11)? match[1] : false;
    }

    // const checkUrl = (event) => {
    //     const url = event.target.value;
    //     const urlError = document.getElementById('urlError');
    //     if(url === '') {
    //             if(!urlError.classList.contains('hidden'))
    //                 urlError.classList.add('hidden');
    //     } else {
    //         if(urlError.classList.contains('hidden'))
    //             urlError.classList.remove('hidden');
    //     }
    // }

    // const upload = (event) => {
    //     const files = [...event.target.files];
    //     console.log(files);
    //     setImages(files);
    //     forceUpdate();  
    // }
 
    const openImage = (image) => {
        var win = window.open();
        win.document.write('<img src="' + image  + '"style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100vw; height:100vh;" allowfullscreen></img>');
    }

    const removeImage = (key) => {
        setMedia(() => {
            return media.filter((value) => {
                return value.key != key;
            })
        })

        setUploadedImages(() => {
            return uploadedimages.filter((value) => {
                return value.key != key;
            })
        })
        
        forceUpdate();
    }

    const removeUrl = (key) => {
        setUrls(() => {
            return urls.filter((value) => {
                return value.key != key;
            })
        })

        forceUpdate();
    }

    const post = async () => {
        const title = document.getElementById('title').value;
        if(title === '') {
            if( document.getElementById('noTitle').classList.contains('hidden')){
                document.getElementById('noTitle').classList.remove('hidden')
                document.getElementById('title').classList.replace('mb-4', 'mb-2')
            }            
        } else {
            // const data = {
            //                 title: title,
            //                 content: content,
            //                 media: {images: media}
            //              }
            // console.log(data);
            const formData = new FormData();
            
            media.map((image) => {
                formData.append('media',image.file);
            })         
            formData.append('postId', uuid())
            formData.append('title', title);
            formData.append('content', content);
            formData.append('country', country);
            formData.append('youtubeUrls', JSON.stringify(urls));
            await PostService.addPost(formData).then((res) => {
                // console.log(res);
                if(res.status === 200){
                    // console.log(res.data);
                    alert("Post added successfully!");
                    window.location.reload();
                }
            });
        }
    }

    const checkTitle = (event) => {
        const title = document.getElementById('title')
        const noTitle = document.getElementById('noTitle')
        if(event.target.value === '') {
            if( noTitle.classList.contains('hidden')){
                noTitle.classList.remove('hidden')
                title.classList.replace('mb-4', 'mb-2')
            }
                ;
        } else {
            if( !noTitle.classList.contains('hidden')){
                noTitle.classList.add('hidden');
                title.classList.replace('mb-2', 'mb-4')
            }
                
        }
    }

  return (
    <div>

        {
            isAuthorized ?
        

        <div>
        {/* <!-- component --> */}
        <div class="heading text-center font-bold text-2xl m-5 text-gray-800">New Post</div>
        <div class="editor my-5 mx-auto w-10/12 flex flex-col text-gray-800 border border-gray-300 p-4 shadow-lg max-w-5xl">
            
            <input id='title' onChange={checkTitle} class="title bg-gray-100 border border-gray-300 p-2 mb-4 outline-none" spellCheck="false" placeholder="Title" type="text"/>
            <p id='noTitle' className='text-red-600 hidden mb-4'>Title is required</p>

            <CKEditor
                onChange={evt => setContent( evt.editor.getData() )}
                config={{
                    removeButtons : 'Underline,JustifyCenter,Styles,Format,About,Anchor,Image,Table,Indent,Outdent,Blockquote,RemoveFormat,Paste,PasteText,PasteFromWord,HorizontalRule,Source', 
                    // removePLugins: 'blockquote,save,flash,iframe,tabletools,pagebreak,templates,about,showblocks,newpage,language,print,div'
                }}
            />

            {/* <!-- icons --> */}
            <div class="icons flex text-gray-500 m-2">
                {/* <svg class="mr-2 cursor-pointer hover:text-gray-700 border rounded-full p-1 h-7" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" /><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" /></svg>
                <svg class="mr-2 cursor-pointer hover:text-gray-700 border rounded-full p-1 h-7" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
                <svg class="mr-2 cursor-pointer hover:text-gray-700 border rounded-full p-1 h-7" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13" /></svg>
                <div class="count ml-auto text-gray-400 text-xs font-semibold">0/300</div> */}
            </div>


            {/* Images upload */}
            <main className='px-2 mt-8'>
                <div>
                    
                        {/* <input type='file' onChange={update} className='w-full md:w-1/2 lg:w-1/3 border-1 rounded-md border-gray-900' multiple="multiple" accept='image/png, image/jpeg, image/jpg, image/webp' />
                        <div className=' inline-flex m-4'>
                            <label htmlFor='utubeUrl'>YouTube Url:</label>
                            <input type='text' placeholder='' className='m-4 w-full md:w-1/2 lg:w-full border-1 rounded-md border-gray-900'/>
                        </div> */}


                      <div class=" md:flex md:justify-between">
                        <div class="mb-4 md:mr-2 md:mb-0">
                          <label class="block mb-2 text-sm font-bold text-pink-500">
                            Images
                          </label>
                          <input
                            class="w-full mb-3 text-sm leading-tight text-gray-700 border border-red-500 rounded shadow appearance-none focus:outline-none focus:shadow-outline"                            
                            type='file' onChange={update} multiple="multiple" accept='image/png, image/jpeg, image/jpg, image/webp' 
                          />
                        </div>
                        <div class="md:ml-2">
                          <label class="block mb-2 text-sm font-bold text-pink-500" for="pin">
                            YouTube Url
                          </label>
                          <input
                            id='url'
                            class="w-full px-3 py-2 mb-3 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                            type="text"
                            placeholder="https://www.youtube.com/......"
                          />
                          {/* <p id='urlError' class="hidden text-xs italic text-red-500">Not a YouTube video url</p> */}
                          <button 
                            onClick={addUrl}
                            class="text-md btn rounded-md border border-indigo-500 p-1 px-4 font-semibold cursor-pointer text-white ml-auto bg-rose-600 hover:bg-rose-500 hover:shadow-md ">
                                Add
                          </button>
                        </div>
                      </div>

                    

                    <div id='uploadedimages' className=' mt-10 grid grid-cols-2 lg:grid-cols-3 gap-1 '>                    
                        {
                            uploadedimages.map((value) => {
                                return(
                                    <div className='flex flex-col'>
                                        <div                                         
                                            className='w-fit bg-gray-200 hover:bg-gray-400 p-2 m-2 rounded-md hover:scale-[1.02] transform transition duration-500'
                                        >
                                            <button onClick={() => {openImage(value.data)}}>{value.name.substring(0, 15)}...</button>
                                            <button onClick={() => {removeImage(value.key)}} className='mx-1 px-1 rounded-sm bg-gray-300 hover:bg-gray-100'>X</button>
                                        </div> 
                                    </div>
                                )
                            })   
                        }
                        {
                            urls.map((value, index) => {
                                return(
                                    <div className='flex flex-col'>
                                        <div                                         
                                            className='w-fit bg-gray-200 hover:bg-gray-400 p-2 m-2 rounded-md hover:scale-[1.02] transform transition duration-500'
                                        >
                                            <a href={value.url} target='_blank'> {value.url.substring(0, 15)}... </a>
                                            <button onClick={() => {removeUrl(value.key)}} className='mx-1 px-1 rounded-sm bg-gray-300 hover:bg-gray-100'>X</button>
                                        </div> 
                                    </div>
                                )
                            })   
                        } 
                    </div>   
                    
                </div>
            </main>

            {/* <!-- buttons --> */}
            <div class="buttons flex">
                {/* <div class="btn border border-gray-300 p-1 px-4 font-semibold cursor-pointer text-gray-500 ml-auto">Cancel</div> */}
                <div 
                    onClick={post}
                    class="text-lg btn rounded-md border border-indigo-500 p-1 px-4 font-semibold cursor-pointer text-white ml-auto bg-cyan-600 hover:bg-cyan-700 hover:shadow-md hover:scale-105 transition duration-300">
                        Post
                </div>
            </div>
        </div>
        </div>

        :

            <NotFound404/>

        }
    </div>
  )
}

export default AddPost