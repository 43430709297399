import axios from "axios";


// const apiPostUrl = "http://localhost:8080/posts";

const apiPostUrl = "https://server.tahk.in/posts";

// const gcloudApiUserUrl = "https://tahk-server-dot-acoustic-spot-347802.el.r.appspot.com/admin";

const PostService = {
    
    addPost: (data) => {
        return axios.post(`${apiPostUrl}/addpost`, data, {
            headers: {
                accessToken: sessionStorage.getItem('accessToken'),
                'content-type': 'multipart/form-data'
            }
        });
    },

    allPosts: () => {
        return axios.get(`${apiPostUrl}/allposts`);
    },

    postByPostId: (id) => {
        return axios.get(`${apiPostUrl}/${id}`)
    }

}


export default PostService;