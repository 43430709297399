import React from 'react';import $ from 'jquery';
import {Helmet} from "react-helmet";
import logo from '../../../assets/logo.png';

import Footer from '../../Footer/Footer';

import Navbar from '../../Navbar/Navbar';

import wasteManagement from '../../../assets/iprojects/Wastemanagementtahk.png'

function WasteManagement() {

    $(document).ready(() => {
        document.body.scrollTop = 0; 
        document.documentElement.scrollTop = 0;

    })

  return (
    <div className='noselect'>
        <Navbar/>

            <Helmet>
                <title>TAHK</title>
                <meta
                    name="description"
                    content="TAHK - Waste Management"
                />
                <meta property="og:url" content={document.location.href} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="TAHK - Waste Management" />
                <meta property="og:description" content="Invest the Waste is first of its' kind term introduced by Tree And Human Knot to reinvent Sustainability with recycle, reuse the waste for better waste management and look upto greater green cover replacing the landfills" />
                <meta property="og:image" content={logo}/>
                <link rel="image_src" href={logo} />
            </Helmet>
       
        <div class="py-28 bg-white">  
        <div class="container m-auto px-6 text-gray-600 md:px-12 xl:px-6">
            <div class="space-y-6 md:space-y-0 md:flex md:gap-6 lg:items-center lg:gap-12">
                <div class="md:5/12 lg:w-5/12">
                <img src={wasteManagement} className='rounded-md shadow-md' alt="image" loading="lazy" width="" height=""/>
                </div>
                <div class="md:7/12 lg:w-6/12">
                <h2 class="text-2xl text-sky-800 font-bold md:text-4xl">Waste Management</h2>
                <p class="mt-6 text-gray-600">We aim to spread awareness & establish sustainable measures to Prevent, Reuse, Reduce, Handle, Monitor, Treat & Recycle waste.
                    Change begins at home,
                    Start with yours
                    Your waste --&#62; Your Responsibility
                    Rain Water Harvesting (Upcoming)

                </p>
                {/* <p class="mt-4 text-gray-600"> Nobis minus voluptatibus pariatur dignissimos libero quaerat iure expedita at? Asperiores nemo possimus nesciunt dicta veniam aspernatur quam mollitia.</p> */}
                </div>
            </div>
        </div>
        </div>

        <div id='footer' className='mt-12'>
        <Footer/>
        </div>
    </div>
  )
}

export default WasteManagement