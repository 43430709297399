import axios from "axios";


// const apiAdminUrl = "http://localhost:8080/admin";

const apiAdminUrl = "https://server.tahk.in/admin";

// const gcloudApiUserUrl = "https://tahk-server-dot-acoustic-spot-347802.el.r.appspot.com/admin";

const AdminService = {
    
    registerViewer: (userData) => {
        return axios.post(`${apiAdminUrl}/registerviewer`, userData, {
            headers: {
                accessToken: sessionStorage.getItem('accessToken')
            }
        });
    },

    deleteViewer: (userData) => {
        return axios.post(`${apiAdminUrl}/deleteviewer`, userData, {
            headers: {
                accessToken: sessionStorage.getItem('accessToken')
            }
        });
    },

    allViewers: () => {
        return axios.get(`${apiAdminUrl}/allviewers`, {
            headers: {
                accessToken: sessionStorage.getItem('accessToken')
            }
        });
    },

    allUsers: () => {
        return axios.get(`${apiAdminUrl}/allusers`, {
            headers: {
                accessToken: sessionStorage.getItem('accessToken')
            }
        });
    },

    validateToken: () => {
        return axios.get(`${apiAdminUrl}/validatetoken`, {
            headers: {
                accessToken: sessionStorage.getItem('accessToken')
            }
        })
    },

    updateAdminPassword: (data) => {
        return axios.post(`${apiAdminUrl}/updatepassword`, data, {
            headers: {
                accessToken: sessionStorage.getItem('accessToken')
            }
        });
    }
}


export default AdminService;