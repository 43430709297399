import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import Navbar from '../Navbar/Navbar';
import PostService from '../../services/PostService';
import { Helmet } from 'react-helmet';
import Footer from '../Footer/Footer';
import Slider from '../Test/Slider/Slider';

import {FacebookShareButton, LinkedinShareButton, TwitterShareButton, WhatsappShareButton, TelegramShareButton} from 'react-share' 

import './Post.css';
import NotFound404 from '../NotFound404_general/NotFound404';

import logo from '../../assets/logo.png';

var parse = require('html-react-parser');


function Post() {
    const { postId } = useParams();

    const [post, setPost] = useState({});

    const [postExists, setPostExists] =  useState(false);

    const url = document.location.href;

    // const url = 'https://theprint.in/world/judge-jury-and-social-media-trial-why-johnny-depp-lost-libel-suit-in-uk-but-won-in-us/984386/';

    useEffect(() => {
        PostService.postByPostId(postId).then((response) => {
            // console.log(response.data);
            if(response.data !== null){
                setPost(response.data);
                setPostExists(true);
            } else 
                setPost(null);      
        })
        document.body.scrollTop = 0; 
        document.documentElement.scrollTop = 0;
    },[])   

    const getTime = (date) => {
        const postDate = new Date(date);
        const present = new Date();
        const interval = present.getTime() - postDate.getTime();
  
        const seconds = interval / 1000;
        if(seconds < 60)
          return Math.floor(seconds) + " sec ago";
  
        const minutes = seconds/60;
        if(minutes < 60)
          return Math.floor(minutes) + " min ago";
  
        const hours = minutes / (60);
        if(hours < 24)
         return Math.floor(hours) + " hours ago";
  
        const days = hours / 24;
  
        if(days < 6)
          return day[postDate.getDay() - 1];
  
        return "" + postDate.getDate() + "/" + (postDate.getMonth() + 1) + "/" + postDate.getFullYear();
        
      }
  
      const day = [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday"
      ]

      const removeHtml = (str) => {
        //   console.log(str.replace( /(<([^>]+)>)/ig, ' '));
        return str.replace( /(<([^>]+)>)/ig, ' ');
      }

      const openModal = () => {
        document.getElementById('modal').classList.remove('hidden');
      }
    
      const closeModal = () => {
          document.getElementById('modal').classList.add('hidden');
      }

  return (
    <div>
        {
            post ?

            <div>
            { postExists && 

              <Helmet>
                  <title>TAHK</title>
                  <meta
                      name="description"
                      content="TAHK - Diary"
                  />
                  <meta property="og:url" content={document.location.href} />
                  <meta property="og:type" content="website" />
                  <meta property="og:title" content={post.title} />
                  <meta property="og:description" content={removeHtml(post.content)} />
                  {
                    post.media[0] && 
                        <meta property="og:image" content={post.media[0].data}/> 
                  }
                  <meta property="og:image:width" content="1200"/>
                  <meta property="og:image:height" content="675"/>
                  {
                    post.media[0] && 
                    <link rel="image_src" href={post.media[0].data} />
                  }
              </Helmet>
            }   
      
              <Navbar/>
          <div class="flex h-[100vh] noselect bg-gradient-to-b from-cyan-50 to-cyan-100 ">
          {/* //   <!-- Desktop sidebar --> */}
            <aside class="z-20 hidden w-80 overflow-y-auto  md:block flex-shrink-0">
      
            </aside>
      
            <div class="flex flex-col flex-1">
              
              <main class="h-full pb-16 overflow-y-auto">
      
              {/* <!-- Main Contents --> */}
      
              <div class="block md:grid md:grid-flow-row-dense md:grid-cols-3 md:grid-rows-1 mt-5 ">
              <div class="col-span-2">
                            { postExists && 
                  
                              <div key={post.id} class="post bg-white shadow rounded-lg mt-10">
                                  {/* <div class="flex flex-row px-2 py-3 mx-3">
                                      <div class="w-auto h-auto rounded-full border-2 border-gray-100">
                                          <img class="w-12 h-12 object-cover rounded-full shadow cursor-pointer" alt="User avatar" src={logo}/>
                                      </div>
                                      <div class="flex flex-col mb-2 ml-4 mt-1">
                                          <div class="text-gray-600 text-sm font-semibold">TAHK {post.country}</div>
                                          <div class="flex w-full mt-1">
                                              <div class="text-gray-400 font-thin text-xs">
                                                  {getTime(post.createdAt)}
                                              </div>
                                          </div>
                                      </div>
                                  </div> */}

                                    <div class="flex items-center justify-between px-4">
                                        <div class="flex justify-between items-center py-3 ">
                                            <div class="w-auto h-auto rounded-full border-2 border-gray-100">
                                                <img class="w-12 h-12 object-cover rounded-full shadow cursor-pointer" alt="User avatar" src={logo}/>
                                            </div>
                                            <div class="ml-3">
                                            <h1 class="text-sm font-semibold text-gray-600 ">TAHK {post.country}</h1>
                                            <p class="text-xs font-thin mt-1 text-gray-600">{getTime(post.createdAt)}</p>
                                            </div>
                                        </div>
                                        <div onClick={openModal} className='transition ease-out duration-300 hover:bg-blue-50 bg-blue-100 px-2 py-2 rounded-full text-blue-400 cursor-pointer'>
                                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z"></path>
                                            </svg>
                                        </div>
                                    </div>

                                  <div class="border-b border-gray-100"></div>
      
                                  <div class="text-gray-400 font-medium text-sm mb-7 mx-3 px-2 py-3">
      
                                    <Slider images={post.media} urls={post.youtubeUrls} />                              
      
                                  </div>
      
                                  <div class="text-gray-600 font-semibold  mb-2 mx-3 px-2">{post.title}</div>
                                  <div class="text-gray-500 text-sm mb-6 mx-3 px-2">{parse(post.content)}</div>
                                  
                                  <div class="border-b border-gray-100"></div>
                              </div>
                            }
                
              </div>
      
              </div>
              </main>
            </div>
          </div>


            <div id='modal' class="modal hidden h-screen w-full fixed left-0 top-0 flex justify-center items-center bg-black bg-opacity-50">
                
                        <div class="bg-white rounded shadow-lg w-10/12 md:w-1/3 noselect">
                            
                            <div class="border-b px-4 py-2 flex justify-between items-center">
                                <h3 class="font-semibold text-lg">Share</h3>
                                <button onClick={closeModal} class="text-black close-modal">X</button>
                            </div> 

                            <div class="my-4 mx-4">

                                <div class="flex justify-around my-4">
                                    {/* <!--FACEBOOK ICON--> */}
                                    <FacebookShareButton url={url}>
                                    <div
                                    class="transition ease-out duration-300 border hover:bg-[#1877f2] w-12 h-12 fill-[#1877f2] hover:fill-white border-blue-200 rounded-full flex items-center justify-center shadow-xl hover:shadow-blue-500/50 cursor-pointer"
                                    >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                    >
                                        <path
                                        d="M13.397 20.997v-8.196h2.765l.411-3.209h-3.176V7.548c0-.926.258-1.56 1.587-1.56h1.684V3.127A22.336 22.336 0 0 0 14.201 3c-2.444 0-4.122 1.492-4.122 4.231v2.355H7.332v3.209h2.753v8.202h3.312z"
                                        ></path>
                                    </svg>
                                    </div>
                                    </FacebookShareButton>
                                    {/* <!--TWITTER ICON--> */}
                                    <TwitterShareButton url={url} via='http://tahk.in'>
                                    <div
                                    class="transition ease-out duration-300 border hover:bg-[#1d9bf0] w-12 h-12 fill-[#1d9bf0] hover:fill-white border-blue-200 rounded-full flex items-center justify-center shadow-xl hover:shadow-sky-500/50 cursor-pointer"
                                    >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                    >
                                        <path
                                        d="M19.633 7.997c.013.175.013.349.013.523 0 5.325-4.053 11.461-11.46 11.461-2.282 0-4.402-.661-6.186-1.809.324.037.636.05.973.05a8.07 8.07 0 0 0 5.001-1.721 4.036 4.036 0 0 1-3.767-2.793c.249.037.499.062.761.062.361 0 .724-.05 1.061-.137a4.027 4.027 0 0 1-3.23-3.953v-.05c.537.299 1.16.486 1.82.511a4.022 4.022 0 0 1-1.796-3.354c0-.748.199-1.434.548-2.032a11.457 11.457 0 0 0 8.306 4.215c-.062-.3-.1-.611-.1-.923a4.026 4.026 0 0 1 4.028-4.028c1.16 0 2.207.486 2.943 1.272a7.957 7.957 0 0 0 2.556-.973 4.02 4.02 0 0 1-1.771 2.22 8.073 8.073 0 0 0 2.319-.624 8.645 8.645 0 0 1-2.019 2.083z"
                                        ></path>
                                    </svg>
                                    </div>
                                    </TwitterShareButton>
                                    {/* <!--LINKEDIN ICON--> */}
                                    <LinkedinShareButton url={url} source='http://tahk.in'>
                                    <div
                                    class="transition ease-out duration-300 border hover:bg-[#0e76a8] w-12 h-12 fill-[#0e76a8] hover:fill-white border-sky-200 rounded-full flex items-center justify-center shadow-xl hover:shadow-sky-300 cursor-pointer"
                                    >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 28 30"
                                    >
                                        <path xmlns="http://www.w3.org/2000/svg" d="M9,25H4V10h5V25z M6.501,8C5.118,8,4,6.879,4,5.499S5.12,3,6.501,3C7.879,3,9,4.121,9,5.499C9,6.879,7.879,8,6.501,8z M27,25h-4.807v-7.3c0-1.741-0.033-3.98-2.499-3.98c-2.503,0-2.888,1.896-2.888,3.854V25H12V9.989h4.614v2.051h0.065 c0.642-1.18,2.211-2.424,4.551-2.424c4.87,0,5.77,3.109,5.77,7.151C27,16.767,27,25,27,25z"/>

                                    </svg>
                                    </div>
                                    </LinkedinShareButton>

                                    {/* <!--WHATSAPP ICON--> */}
                                    <WhatsappShareButton url={url}>
                                    <div
                                    class="transition ease-out duration-300 border hover:bg-[#25D366] w-12 h-12 fill-[#25D366] hover:fill-white border-green-200 rounded-full flex items-center justify-center shadow-xl hover:shadow-green-500/50 cursor-pointer"
                                    >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                    >
                                        <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M18.403 5.633A8.919 8.919 0 0 0 12.053 3c-4.948 0-8.976 4.027-8.978 8.977 0 1.582.413 3.126 1.198 4.488L3 21.116l4.759-1.249a8.981 8.981 0 0 0 4.29 1.093h.004c4.947 0 8.975-4.027 8.977-8.977a8.926 8.926 0 0 0-2.627-6.35m-6.35 13.812h-.003a7.446 7.446 0 0 1-3.798-1.041l-.272-.162-2.824.741.753-2.753-.177-.282a7.448 7.448 0 0 1-1.141-3.971c.002-4.114 3.349-7.461 7.465-7.461a7.413 7.413 0 0 1 5.275 2.188 7.42 7.42 0 0 1 2.183 5.279c-.002 4.114-3.349 7.462-7.461 7.462m4.093-5.589c-.225-.113-1.327-.655-1.533-.73-.205-.075-.354-.112-.504.112s-.58.729-.711.879-.262.168-.486.056-.947-.349-1.804-1.113c-.667-.595-1.117-1.329-1.248-1.554s-.014-.346.099-.458c.101-.1.224-.262.336-.393.112-.131.149-.224.224-.374s.038-.281-.019-.393c-.056-.113-.505-1.217-.692-1.666-.181-.435-.366-.377-.504-.383a9.65 9.65 0 0 0-.429-.008.826.826 0 0 0-.599.28c-.206.225-.785.767-.785 1.871s.804 2.171.916 2.321c.112.15 1.582 2.415 3.832 3.387.536.231.954.369 1.279.473.537.171 1.026.146 1.413.089.431-.064 1.327-.542 1.514-1.066.187-.524.187-.973.131-1.067-.056-.094-.207-.151-.43-.263"
                                        ></path>
                                    </svg>
                                    </div>
                                    </WhatsappShareButton>

                                    {/* <!--TELEGRAM ICON--> */}
                                    <TelegramShareButton url={url}>
                                    <div
                                    class="transition ease-out duration-300 border hover:bg-[#229ED9] w-12 h-12 fill-[#229ED9] hover:fill-white border-sky-200 rounded-full flex items-center justify-center shadow-xl hover:shadow-sky-500/50 cursor-pointer"
                                    >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                    >
                                        <path
                                        d="m20.665 3.717-17.73 6.837c-1.21.486-1.203 1.161-.222 1.462l4.552 1.42 10.532-6.645c.498-.303.953-.14.579.192l-8.533 7.701h-.002l.002.001-.314 4.692c.46 0 .663-.211.921-.46l2.211-2.15 4.599 3.397c.848.467 1.457.227 1.668-.785l3.019-14.228c.309-1.239-.473-1.8-1.282-1.434z"
                                        ></path>
                                    </svg>
                                    </div>
                                    </TelegramShareButton>
                                </div>

                                
                            </div>                           
                            
                        </div>
                        
            </div>
      
            <Footer/>
      
          </div>

            :

                <NotFound404/>


        }
    </div>
  )
}

export default Post