import React from 'react';
import $ from 'jquery';

import Footer from '../../Footer/Footer'
import Navbar from '../../Navbar/Navbar'

import img1 from '../../../assets/iprojects/GreenBirthday.jpg';
import {Helmet} from "react-helmet";
import logo from '../../../assets/logo.png';

function GreenBirthday() {

    $(document).ready(() => {
        document.body.scrollTop = 0; 
        document.documentElement.scrollTop = 0;

    })

  return (
    <div className='noselect'>

        <Navbar/>

            <Helmet>
                <title>TAHK - Green Birthday</title>
                <meta
                    name="description"
                    content="Very own initiative of TAHK to make every celebration with Nature. GreenBirthday is a surprised plan for Birthday owners to connect people with Nature"
                />
                <meta property="og:url" content={document.location.href} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="TAHK - Green Birthday" />
                <meta property="og:description" content="Very own initiative of TAHK to make every celebration with Nature. GreenBirthday is a surprised plan for Birthday owners to connect people with Nature" />
                <meta property="og:image" content={logo}/>
                <link rel="image_src" href={logo} />
            </Helmet>

       
        <div class="py-28 bg-white">  
        <div class="container m-auto px-6 text-gray-600 md:px-12 xl:px-6">
            <div class="space-y-6 md:space-y-0 md:flex md:gap-6 lg:items-center lg:gap-12">
                <div class="md:w-5/12 lg:w-3/12 ">
                <img src={img1} alt="image" className='rounded-md shadow-lg'  width="" height=""/>
                </div>
                <div class="md:w-7/12 lg:w-9/12">
                <h2 class="text-2xl text-sky-800 font-bold md:text-4xl">Green Birthday / Green Celebration </h2>
                <p class="mt-6 text-gray-600"><b>GreenBirthday</b> or now a Green celebration on every occasion started in October 2020 by creating surprise birthday event 
                    Don't just wish long life to your loved ones, But also gift them, long life 
                    Reaching a party venue with a plant in your hand might seem uncomfortable, but is sustainable if you can make every celebration a green celebration. Become an inspiration for so many, give something valuable.
                </p>
                {/* <p class="mt-4 text-gray-600"> Nobis minus voluptatibus pariatur dignissimos libero quaerat iure expedita at? Asperiores nemo possimus nesciunt dicta veniam aspernatur quam mollitia.</p> */}
                </div>
            </div>
        </div>
        </div>

        <div id='footer' className='mt-12'>
        <Footer/>
        </div>
    </div>
  )
}

export default GreenBirthday