import React, { useEffect, useState } from 'react'
import Navbar from '../Navbar/Navbar';
import PostService from '../../services/PostService';
import { Helmet } from 'react-helmet';

import {FacebookShareButton, LinkedinShareButton, TwitterShareButton, WhatsappShareButton, TelegramShareButton} from 'react-share' 

import './Posts.css';
import Footer from '../Footer/Footer';
import Slider from '../Test/Slider/Slider';

import logo from '../../assets/logo.png';

var parse = require('html-react-parser');

function Posts() {

    const [posts, setPosts] = useState([]);

    const [sharePostUrl, setSharePostUrl] = useState('');

    useEffect(() => {
        PostService.allPosts().then((response) => {
            console.log(response.data);
            if(Array.isArray(response.data)) {
                setPosts(response.data); 
            }
        })
        document.body.scrollTop = 0; 
        document.documentElement.scrollTop = 0;
    },[])

    const getTime = (date) => {
      const postDate = new Date(date);
      const present = new Date();
      const interval = present.getTime() - postDate.getTime();

      const seconds = interval / 1000;
      if(seconds < 60)
        return Math.floor(seconds) + " sec ago";

      const minutes = seconds/60;
      if(minutes < 60)
        return Math.floor(minutes) + " min ago";

      const hours = minutes / (60);
      if(hours < 24)
       return Math.floor(hours) + " hours ago";

      const days = hours / 24;

      if(days < 6)
        return day[postDate.getDay() - 1];

      return "" + postDate.getDate() + "/" + (postDate.getMonth() + 1) + "/" + postDate.getFullYear();
      
    }

    const day = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday"
    ]

    const openModal = () => {
      document.getElementById('modal').classList.remove('hidden');
    }

    const closeModal = () => {
      document.getElementById('modal').classList.add('hidden');
    }


  return (
    <div>
      <Helmet>
            <title>TAHK - Diary</title>
            <meta
                name="description"
                content="TAHK - Diary"
            />
            <meta property="og:url" content={document.location.href} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content="TAHK - Diary" />
            <meta property="og:description" content="TAHK - Diary" />
            <meta property="og:image" content={logo}/>
            <link rel="image_src" href={logo} />
        </Helmet>

        <Navbar/>
        <div class="flex h-[100vh] noselect bg-gradient-to-b from-cyan-50 to-cyan-100 ">
    {/* //   <!-- Desktop sidebar --> */}
      <aside class="z-20 hidden w-80 overflow-y-auto  md:block flex-shrink-0">
        {/* <div class=" h-14 flex justify-center">
             <img src="./images/logo.png" class="w-32 h-24 mt-10"/> 
        </div>
        <div class=" mt-24 p-2 flex justify-center">
          <a href="#" class="relative flex flex-row items-center h-11 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-transparent hover:border-indigo-500 pr-6">
            <span class="inline-flex justify-center items-center ml-4">
              <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"></path></svg>
            </span>
            <span class="ml-2 text-sm tracking-wide truncate">Home</span>
          </a>

        </div>

        <div class=" my-5 flex justify-center">
            <button class="inline-block py-2 w-4/5 px-4 text-gray-700 font-semibold text-sm no-underline border-b-1 border-gray-300 p-2 rounded-md font-medium  hover:bg-gray-100 focus:bg-gray-100 focus:shadow-outline">#Explore</button>
        </div> 

         <div class=" my-2 p-2 flex justify-center">
           <a href="#" class="relative flex flex-row items-center h-11 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-transparent hover:border-indigo-500 pr-6">
            <span class="inline-flex justify-center items-center ml-4">
              <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"></path></svg>
            </span>
            <span class="ml-2 text-sm tracking-wide truncate">Notifications</span>
            <span class="px-2 py-0.5 ml-auto text-xs font-medium tracking-wide text-red-500 bg-red-50 rounded-full">1.2k</span>
          </a>
        </div>

         <div class=" my-2 p-2 flex justify-center">
            <a href="#" class="relative flex flex-row items-center h-11 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-transparent hover:border-indigo-500 pr-6">
            <span class="inline-flex justify-center items-center ml-4">
              <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"></path></svg>
            </span>
            <span class="ml-2 text-sm tracking-wide truncate">Messages</span>
          </a>
        </div>

         <div class=" my-2 p-2 flex justify-center">
            <a href="#" class="relative flex flex-row items-center h-11 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-transparent hover:border-indigo-500 pr-6">
            <span class="inline-flex justify-center items-center ml-4">
              <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"></path></svg>
            </span>
            <span class="ml-2 text-sm tracking-wide truncate">Friends</span>
            <span class="px-2 py-0.5 ml-auto text-xs font-medium tracking-wide text-green-500 bg-green-50 rounded-full">(15)</span>
          </a>
        </div>

         <div class=" my-2 p-2 flex justify-center">
            <a href="#" class="relative flex flex-row items-center h-11 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-transparent hover:border-indigo-500 pr-6">
            <span class="inline-flex justify-center items-center ml-4">
              <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"></path></svg>
            </span>
            <span class="ml-2 text-sm tracking-wide truncate">Lists</span>
          </a>
        </div>

         <div class=" my-2 p-2 flex justify-center">
            <a href="#" class="relative flex flex-row items-center h-11 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-transparent hover:border-indigo-500 pr-6">
            <span class="inline-flex justify-center items-center ml-4">
              <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"></path></svg>
            </span>
            <span class="ml-2 text-sm tracking-wide truncate">Profile</span>
          </a>
        </div>

         <div class=" my-2 p-2 flex justify-center">
           <a href="#" class="relative flex flex-row items-center h-11 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-transparent hover:border-indigo-500 pr-6">
            <span class="inline-flex justify-center items-center ml-4">
              <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"></path>
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path>
              </svg>
            </span>
            <span class="ml-2 text-sm tracking-wide truncate">Settings</span>
          </a>
        </div> */}

      </aside>

      <div class="flex flex-col flex-1">
        {/* <header class="z-10 py-4  shadow-xs ">
          <div class="container flex items-center justify-between h-full px-6 mx-auto text-purple-600 dark:text-purple-300">
            <button class="p-1 -ml-1 mr-5 rounded-md md:hidden focus:outline-none focus:shadow-outline-purple">
              <svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                <path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path>
              </svg>
            </button>

            <input type="search" class="block md:hidden lg:hidden w-1/2 py-2 pl-4 px-4 pr-10 text-sm bg-gray-100 border border-transparent appearance-none rounded-tg placeholder-gray-400 focus:bg-gray-50 focus:outline-none focus:border-blue-500 focus:text-gray-900 focus:shadow-outline-blue" style="border-radius: 25px" placeholder="Search" autocomplete="off"/> 
            <div class="flex justify-between">
            <ul class="flex items-center flex-shrink-0 space-x-6">
              <li class="relative ">
                <button class="align-middle rounded-full border-2 border-gray-300 shadow-lg  focus:shadow-outline-purple focus:outline-none">
                  <img class="object-cover w-10 h-10 rounded-full hover-image-1 flex-shrink-0" src="https://picsum.photos/200/200?i=203" alt="" aria-hidden="true"/>
                </button>
              </li>

                     <li class="relative">
                <button class="align-middle rounded-full border-2 border-gray-300 shadow-lg  focus:shadow-outline-purple focus:outline-none">
                  <img class="object-cover w-10 h-10 rounded-full hover-image-1 flex-shrink-0" src="https://picsum.photos/200/200?i=205" alt="" aria-hidden="true"/>
                </button>
              </li>

                     <li class="relative">
                <button class="align-middle rounded-full border-2 border-gray-300 shadow-lg  focus:shadow-outline-purple focus:outline-none">
                  <img class="object-cover w-10 h-10 rounded-full hover-image-1 flex-shrink-0" src="https://picsum.photos/200/200?i=207" alt="" aria-hidden="true"/>
                </button>
              </li>

                     <li class="relative">
                <button class="align-middle rounded-full border-2 border-gray-300 shadow-lg  focus:shadow-outline-purple focus:outline-none">
                  <img class="object-cover w-10 h-10 rounded-full hover-image-1 flex-shrink-0" src="https://picsum.photos/200/200?i=209" alt="" aria-hidden="true"/>
                </button>
              </li>

                  <li class="relative">
                <button class="align-middle rounded-full border-2 border-gray-300 shadow-lg  focus:shadow-outline-purple focus:outline-none">
                  <img class="object-cover w-10 h-10 rounded-full hover-image-1 flex-shrink-0" src="https://picsum.photos/200/200?i=2011" alt="" aria-hidden="true"/>
                </button>
              </li>

            </ul>
            </div>

          </div>
        </header> */}
        <main class="h-full pb-16 overflow-y-auto">
          {/* <!-- Remove everything INSIDE this div to a really blank page --> */}

        {/* <!-- Main Contents --> */}

        <div class="block md:grid md:grid-flow-row-dense md:grid-cols-3 md:grid-rows-1 mt-5 ">
        <div class="col-span-2">

            {
                posts.slice(0).reverse().map((post, key) => {
                    return(
                        <div key={post.id} class="post bg-white shadow rounded-lg mt-10">
                            <div class="flex items-center justify-between px-4">
                                        <div class="flex justify-between items-center py-3 ">
                                            <div class="w-auto h-auto rounded-full border-2 border-gray-100">
                                                <img class="w-12 h-12 object-cover rounded-full shadow cursor-pointer" alt="User avatar" src={logo}/>
                                            </div>
                                            <div class="ml-3">
                                            <h1 class="text-sm font-semibold text-gray-600 ">TAHK {post.country}</h1>
                                            <p class="text-xs font-thin mt-1 text-gray-600">{getTime(post.createdAt)}</p>
                                            </div>
                                        </div>
                                        <div onClick={() => {setSharePostUrl(document.location.href.concat(`/${post.postId}`)); openModal()}} className='transition ease-out duration-300 hover:bg-blue-50 bg-blue-100 px-2 py-2 rounded-full text-blue-400 cursor-pointer'>
                                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z"></path>
                                            </svg>
                                        </div>
                                    </div>
                            <div class="border-b border-gray-100"></div>

                            <div class="text-gray-400 font-medium text-sm mb-7 mt-6 mx-3 px-2">

                              <Slider images={post.media} urls={post.youtubeUrls} />                              

                            </div>

                            <div class="text-gray-600 font-semibold  mb-2 mx-3 px-2">{post.title}</div>
                            <div class="text-gray-500 text-sm mb-6 mx-3 px-2">{parse(post.content)}</div>
                            <div class="border-b border-gray-100"></div>
                        </div>
                    )
                })
            }

                
                {/* <div class="flex justify-start mb-4 border-t border-gray-100">
                    <div class="flex w-full mt-1 pt-2 pl-5">
                        <span class="bg-white transition ease-out duration-300 hover:text-red-500 border w-8 h-8 px-2 pt-2 text-center rounded-full text-gray-400 cursor-pointer mr-2">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" width="14px" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z"></path>
                            </svg>
                        </span>
                        <img class="inline-block object-cover w-8 h-8 text-white border-2 border-white rounded-full shadow-sm cursor-pointer" src="https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=2&amp;w=256&amp;h=256&amp;q=80" alt=""/>
                        <img class="inline-block object-cover w-8 h-8 -ml-2 text-white border-2 border-white rounded-full shadow-sm cursor-pointer" src="https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&amp;auto=format&amp;fit=facearea&amp;facepad=2&amp;w=256&amp;h=256&amp;q=80" alt=""/>
                        <img class="inline-block object-cover w-8 h-8 -ml-2 text-white border-2 border-white rounded-full shadow-sm cursor-pointer" src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=crop&amp;w=634&amp;q=80" alt=""/>
                        <img class="inline-block object-cover w-8 h-8 -ml-2 text-white border-2 border-white rounded-full shadow-sm cursor-pointer" src="https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=2.25&amp;w=256&amp;h=256&amp;q=80" alt=""/>
                    </div>
                    <div class="flex justify-end w-full mt-1 pt-2 pr-5">
                        <span class="transition ease-out duration-300 hover:bg-blue-50 bg-blue-100 w-8 h-8 px-2 py-2 text-center rounded-full text-blue-400 cursor-pointer mr-2">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" width="14px" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z"></path>
                            </svg>
                        </span>
                        <span class="transition ease-out duration-300 hover:bg-gray-50 bg-gray-100 h-8 px-2 py-2 text-center rounded-full text-gray-100 cursor-pointer">
                            <svg class="h-4 w-4 text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M20.84 4.61a5.5 5.5 0 00-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 00-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 000-7.78z"></path>
                            </svg>
                        </span>
                    </div>
                </div> */}
                {/* <div class="flex w-full border-t border-gray-100">
                    <div class="mt-3 mx-5 flex flex-row text-xs">
                        <div class="flex text-gray-700 font-normal rounded-md mb-2 mr-4 items-center">Comments:<div class="ml-1 text-gray-400 text-ms"> 30</div></div>
                        <div class="flex text-gray-700 font-normal rounded-md mb-2 mr-4 items-center">Views: <div class="ml-1 text-gray-400 text-ms"> 60k</div></div>
                    </div>
                    <div class="mt-3 mx-5 w-full flex justify-end text-xs">
                        <div class="flex text-gray-700  rounded-md mb-2 mr-4 items-center">Likes: <div class="ml-1 text-gray-400 text-ms"> 120k</div></div>
                    </div>
                </div> */}
                {/* <div class="relative flex items-center self-center w-full max-w-xl p-4 overflow-hidden text-gray-600 focus-within:text-gray-400">
                    <img class="w-10 h-10 object-cover rounded-full shadow mr-2 cursor-pointer" alt="User avatar" src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;ixlib=rb-1.2.1&amp;auto=format&amp;fit=crop&amp;w=2000&amp;q=80"/>
                    <span class="absolute inset-y-0 right-0 flex items-center pr-6">
                        <button type="submit" class="p-1 focus:outline-none focus:shadow-none hover:text-blue-500">
                        <svg class="w-6 h-6 transition ease-out duration-300 hover:text-blue-500 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                        </svg>

                        </button>
                    </span>
                     <input type="search" class="w-full py-2 pl-4 pr-10 text-sm bg-gray-100 border border-transparent appearance-none rounded-tg placeholder-gray-400 focus:bg-white focus:outline-none focus:border-blue-500 focus:text-gray-900 focus:shadow-outline-blue" style={{borderRadius: "25px"}} placeholder="Post a comment..." autocomplete="off"/>
                </div> */}
          
        </div>


  {/* <div class="hidden md:block lg:block py-8  sticky top-6">

  <input type="search" class="ml-16 w-3/4 py-2 pl-4 px-4 pr-10 text-sm bg-gray-100 border border-transparent appearance-none rounded-tg placeholder-gray-400 focus:bg-gray-50 focus:outline-none focus:border-blue-500 focus:text-gray-900 focus:shadow-outline-blue" style={{borderRadius: "25px"}} placeholder="Search" autocomplete="off"/>
<card class=" w-96 rounded-lg shadow-lg">

    <header class="font-bold text-2xl px-5 py-4">
      Who to follow
    </header>

    <main class="px-5">

      <content class="grid grid-cols-6">

        <div class="">
          <img src="https://picsum.photos/200/200" class="h-8 w-8 rounded-full"/>
        </div>

        <div class="col-span-3 px-1 font-semibold flex flex-col">
          <div class="text-sm"> Sangwa Albine </div>
          <div class="text-xm text-gray-700 font-light"> @__svngwa._ </div>
        </div>

        <div class="col-span-2 py-2 justify-self-end">
          <button class=" text-blue-500 text-xs font-semibold text-md rounded-full py-1 px-4">
            .Follow
          </button>
        </div>

      </content>


         <content class="grid grid-cols-6 mt-6">

        <div class="">
          <img src="https://picsum.photos/200/200?i=200" class="h-8 w-8 rounded-full"/>
        </div>

        <div class="col-span-3 px-1 font-semibold flex flex-col ">
          <div class="text-sm"> Mbonyintege </div>
          <div class="text-xm text-gray-700 font-light"> @Cpwr</div>
        </div>

        <div class="col-span-2 py-2 justify-self-end">
          <button class=" text-blue-500 text-xs font-semibold text-md rounded-full py-1 px-4">
            .Follow
          </button>
        </div>

      </content>


    </main>

  </card>

  <div class="max-w-sm rounded-lg overflow-hidden shadow-sm m-4 mt-5">

    <div class="flex">
        <div class="flex-1">
            <p class="px-4 ml-2 mt-3 w-48 text-xs text-gray-400">1 . Trending</p>
            <h2 class="px-4 ml-2 w-48 font-bold ">#Microsoft363</h2>
            <p class="px-4 ml-2 mb-3 w-48 text-xs text-gray-400">5,466 posts</p>

        </div>
        <div class="flex-1 px-4 py-2 m-2">
            <a href="" class=" text-2xl rounded-full text-gray-400 hover:bg-gray-800 hover:text-blue-300 float-right">
                <svg class="m-2 h-5 w-5" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" stroke="currentColor" viewBox="0 0 24 24">
                    <path d="M19 9l-7 7-7-7"></path>
                </svg>
            </a>
        </div>
    </div>



    <div class="flex">
        <div class="flex-1">
            <p class="px-4 ml-2 mt-3 w-48 text-xs text-gray-400">2 . Politics . Trending</p>
            <h2 class="px-4 ml-2 w-48 font-bold ">#HI-Fashion</h2>
            <p class="px-4 ml-2 mb-3 w-48 text-xs text-gray-400">8,464 posts</p>

        </div>
        <div class="flex-1 px-4 py-2 m-2">
            <a href="" class=" text-2xl rounded-full text-gray-400 hover:bg-gray-800 hover:text-blue-300 float-right">
                <svg class="m-2 h-5 w-5" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" stroke="currentColor" viewBox="0 0 24 24">
                    <path d="M19 9l-7 7-7-7"></path>
                </svg>
            </a>
        </div>
    </div>



    <div class="flex">
        <div class="flex-1">
            <p class="px-4 ml-2 mt-3 w-48 text-xs text-gray-400">3 . Rock . Trending</p>
            <h2 class="px-4 ml-2 w-48 font-bold ">#Ferrari</h2>
            <p class="px-4 ml-2 mb-3 w-48 text-xs text-gray-400">5,586 Concepts</p>

        </div>
        <div class="flex-1 px-4 py-2 m-2">
            <a href="" class=" text-2xl rounded-full text-gray-400 hover:bg-gray-800 hover:text-blue-300 float-right">
                <svg class="m-2 h-5 w-5" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" stroke="currentColor" viewBox="0 0 24 24">
                    <path d="M19 9l-7 7-7-7"></path>
                </svg>
            </a>
        </div>
    </div>

    <div class="flex">
        <div class="flex-1 p-4">
            <h2 class="px-4 ml-2 w-48 font-semibold text-sm text-blue-400">Show more</h2>
        </div>
    </div>

</div>
  </div> */}
</div>


            <div id='modal' class="modal hidden h-screen w-full fixed left-0 top-0 flex justify-center items-center bg-black bg-opacity-50">
                
                        <div class="bg-white rounded shadow-lg w-10/12 md:w-1/3 noselect">
                            
                            <div class="border-b px-4 py-2 flex justify-between items-center">
                                <h3 class="font-semibold text-lg">Share</h3>
                                <button onClick={closeModal} class="text-black close-modal">X</button>
                            </div> 

                            <div class="my-4 mx-4">

                                <div class="flex justify-around my-4">
                                    {/* <!--FACEBOOK ICON--> */}
                                    <FacebookShareButton url={sharePostUrl}>
                                    <div
                                    class="transition ease-out duration-300 border hover:bg-[#1877f2] w-12 h-12 fill-[#1877f2] hover:fill-white border-blue-200 rounded-full flex items-center justify-center shadow-xl hover:shadow-blue-500/50 cursor-pointer"
                                    >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                    >
                                        <path
                                        d="M13.397 20.997v-8.196h2.765l.411-3.209h-3.176V7.548c0-.926.258-1.56 1.587-1.56h1.684V3.127A22.336 22.336 0 0 0 14.201 3c-2.444 0-4.122 1.492-4.122 4.231v2.355H7.332v3.209h2.753v8.202h3.312z"
                                        ></path>
                                    </svg>
                                    </div>
                                    </FacebookShareButton>
                                    {/* <!--TWITTER ICON--> */}
                                    <TwitterShareButton url={sharePostUrl} via='http://tahk.in'>
                                    <div
                                    class="transition ease-out duration-300 border hover:bg-[#1d9bf0] w-12 h-12 fill-[#1d9bf0] hover:fill-white border-blue-200 rounded-full flex items-center justify-center shadow-xl hover:shadow-sky-500/50 cursor-pointer"
                                    >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                    >
                                        <path
                                        d="M19.633 7.997c.013.175.013.349.013.523 0 5.325-4.053 11.461-11.46 11.461-2.282 0-4.402-.661-6.186-1.809.324.037.636.05.973.05a8.07 8.07 0 0 0 5.001-1.721 4.036 4.036 0 0 1-3.767-2.793c.249.037.499.062.761.062.361 0 .724-.05 1.061-.137a4.027 4.027 0 0 1-3.23-3.953v-.05c.537.299 1.16.486 1.82.511a4.022 4.022 0 0 1-1.796-3.354c0-.748.199-1.434.548-2.032a11.457 11.457 0 0 0 8.306 4.215c-.062-.3-.1-.611-.1-.923a4.026 4.026 0 0 1 4.028-4.028c1.16 0 2.207.486 2.943 1.272a7.957 7.957 0 0 0 2.556-.973 4.02 4.02 0 0 1-1.771 2.22 8.073 8.073 0 0 0 2.319-.624 8.645 8.645 0 0 1-2.019 2.083z"
                                        ></path>
                                    </svg>
                                    </div>
                                    </TwitterShareButton>
                                    {/* <!--LINKEDIN ICON--> */}
                                    <LinkedinShareButton url={sharePostUrl} source='http://tahk.in'>
                                    <div
                                    class="transition ease-out duration-300 border hover:bg-[#0e76a8] w-12 h-12 fill-[#0e76a8] hover:fill-white border-sky-200 rounded-full flex items-center justify-center shadow-xl hover:shadow-sky-300 cursor-pointer"
                                    >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 28 30"
                                    >
                                        <path xmlns="http://www.w3.org/2000/svg" d="M9,25H4V10h5V25z M6.501,8C5.118,8,4,6.879,4,5.499S5.12,3,6.501,3C7.879,3,9,4.121,9,5.499C9,6.879,7.879,8,6.501,8z M27,25h-4.807v-7.3c0-1.741-0.033-3.98-2.499-3.98c-2.503,0-2.888,1.896-2.888,3.854V25H12V9.989h4.614v2.051h0.065 c0.642-1.18,2.211-2.424,4.551-2.424c4.87,0,5.77,3.109,5.77,7.151C27,16.767,27,25,27,25z"/>

                                    </svg>
                                    </div>
                                    </LinkedinShareButton>

                                    {/* <!--WHATSAPP ICON--> */}
                                    <WhatsappShareButton url={sharePostUrl}>
                                    <div
                                    class="transition ease-out duration-300 border hover:bg-[#25D366] w-12 h-12 fill-[#25D366] hover:fill-white border-green-200 rounded-full flex items-center justify-center shadow-xl hover:shadow-green-500/50 cursor-pointer"
                                    >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                    >
                                        <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M18.403 5.633A8.919 8.919 0 0 0 12.053 3c-4.948 0-8.976 4.027-8.978 8.977 0 1.582.413 3.126 1.198 4.488L3 21.116l4.759-1.249a8.981 8.981 0 0 0 4.29 1.093h.004c4.947 0 8.975-4.027 8.977-8.977a8.926 8.926 0 0 0-2.627-6.35m-6.35 13.812h-.003a7.446 7.446 0 0 1-3.798-1.041l-.272-.162-2.824.741.753-2.753-.177-.282a7.448 7.448 0 0 1-1.141-3.971c.002-4.114 3.349-7.461 7.465-7.461a7.413 7.413 0 0 1 5.275 2.188 7.42 7.42 0 0 1 2.183 5.279c-.002 4.114-3.349 7.462-7.461 7.462m4.093-5.589c-.225-.113-1.327-.655-1.533-.73-.205-.075-.354-.112-.504.112s-.58.729-.711.879-.262.168-.486.056-.947-.349-1.804-1.113c-.667-.595-1.117-1.329-1.248-1.554s-.014-.346.099-.458c.101-.1.224-.262.336-.393.112-.131.149-.224.224-.374s.038-.281-.019-.393c-.056-.113-.505-1.217-.692-1.666-.181-.435-.366-.377-.504-.383a9.65 9.65 0 0 0-.429-.008.826.826 0 0 0-.599.28c-.206.225-.785.767-.785 1.871s.804 2.171.916 2.321c.112.15 1.582 2.415 3.832 3.387.536.231.954.369 1.279.473.537.171 1.026.146 1.413.089.431-.064 1.327-.542 1.514-1.066.187-.524.187-.973.131-1.067-.056-.094-.207-.151-.43-.263"
                                        ></path>
                                    </svg>
                                    </div>
                                    </WhatsappShareButton>

                                    {/* <!--TELEGRAM ICON--> */}
                                    <TelegramShareButton url={sharePostUrl}>
                                    <div
                                    class="transition ease-out duration-300 border hover:bg-[#229ED9] w-12 h-12 fill-[#229ED9] hover:fill-white border-sky-200 rounded-full flex items-center justify-center shadow-xl hover:shadow-sky-500/50 cursor-pointer"
                                    >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                    >
                                        <path
                                        d="m20.665 3.717-17.73 6.837c-1.21.486-1.203 1.161-.222 1.462l4.552 1.42 10.532-6.645c.498-.303.953-.14.579.192l-8.533 7.701h-.002l.002.001-.314 4.692c.46 0 .663-.211.921-.46l2.211-2.15 4.599 3.397c.848.467 1.457.227 1.668-.785l3.019-14.228c.309-1.239-.473-1.8-1.282-1.434z"
                                        ></path>
                                    </svg>
                                    </div>
                                    </TelegramShareButton>
                                </div>

                                
                            </div>                           
                            
                        </div>
                        
            </div>


        </main>
      </div>
    </div>

      <Footer/>

    </div>
  )
}

export default Posts