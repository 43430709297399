import React from 'react';
import Navbar from '../../Navbar/Navbar';
import $ from 'jquery';

import logo from '../../../assets/logo.png';
import {Helmet} from "react-helmet";

// images
import sdg1 from '../assets/sdg1.webp';

import Footer from '../../Footer/Footer';

function NoPoverty() {

    $(document).ready(() => {
        document.body.scrollTop = 0; 
        document.documentElement.scrollTop = 0;
    
      })

  return (
    <div className='bg-sky-50 noselect'>

        <Helmet>
                <title>TAHK - No Poverty</title>
                <meta
                    name="description"
                    content=" End poverty in all its forms everywhere"
                />
                <meta property="og:url" content={document.location.href} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="TAHK -  No Poverty" />
                <meta property="og:description" content=" End poverty in all its forms everywhere" />
                <meta property="og:image" content={logo}/>
                <link rel="image_src" href={logo} />
            </Helmet>

        <Navbar/>

        <section >
            <div class="max-w-7xl px-6 py-16 mx-0">
                <div class="items-center md:flex md:space-x-6">
                    <div class="md:w-1/2">
                        <div class="flex items-center justify-center">
                            <div class="max-w-md">
                                <img class="object-cover object-center w-full rounded-md shadow" style={{height: 500}}
                                    src={sdg1}/>
                            </div>
                        </div>
                    </div>
        
                    <div class="mt-8 md:mt-0 md:w-1/2">
                        <h2 class="max-w-xl text-2xl text-sky-800 font-bold md:text-4xl md:pl-4 uppercase">NO POVERTY</h2>
                        <p class="max-w-xl mt-4 text-lime-800 text-lg md:pl-4">In an attempt to achieve SDG 1, "No Poverty", TreeAndHumanKnot proposes to initiate reforestation with its Intrapreneurial projects. 
                            This will help in reducing poverty to some extent, thereby adding a green cover to the earth. 
                            Hence, reforestation will be instrumental in serving several causes at once. 
                            It can help in managing a microclimate which can act as a barrier against abnormal rainfall, cyclones and high 
                            temperature; thereby increasing the yield of crops and providing farmers a good stability in their income.
                            This can also help in reducing Climate Change which has a direct impact on the 'below poverty line’ people, who are the worst affected. 
                            TAHK is constantly striving forward to bring about this change and motivate young minds for creating an impact.
                        </p>
                        
                        
                    </div>
                </div>
            </div>
        </section>

        <div id='footer' className='mt-12'>
        <Footer/>
        </div>
    </div>
  )
}

export default NoPoverty