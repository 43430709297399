import React from 'react';
import Footer from '../Footer/Footer';
import Navbar from '../Navbar/Navbar';
import RazorpayService from '../../services/RayzorpayService';
import logo from '../../assets/logo.png';
import {useEffect, useState} from 'react';

import {Helmet} from "react-helmet";

function Certification() {

  useEffect(() => {
    document.body.scrollTop = 0; 
    document.documentElement.scrollTop = 0;
  }, []);  

  const [loading, setLoading] = useState(false);

  function loadRazorpay() {
    const script = document.createElement('script');
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.onerror = () => {
        alert("Razorpay SDK failed to load. Are you online?");
    }
    script.onload = async () => {
        try {
            setLoading(true);
            var params, orderId;
            await RazorpayService.razorpayParams().then((response) => {
                if(response.data.status === 400){
                    params = response.data;
                }                        
            });
            await RazorpayService.createOrder().then((response) => {
                if(response.data.status == 400)
                    orderId = response.data.order_id;
                else
                    alert(response.data.error);
            })
            const options = {
                "key": params.parameters.key_id, // Enter the Key ID generated from the Dashboard
                "amount": params.parameters.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
                "currency": "INR",
                "name": "TAHK",
                "description": "Sustainability Certification Program",
                "image": logo,
                "order_id": orderId, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
                // "handler": function (response){
                //     alert(response.razorpay_payment_id);
                //     alert(response.razorpay_order_id);
                //     alert(response.razorpay_signature)
                // },
                "prefill": {
                    "name": "",
                    "email": "",
                    "contact": ""
                },
                "notes": {
                    // "address": "Razorpay Corporate Office"
                },
                "theme": {
                    "color": "#3399cc"
                }
            };
            setLoading(false);
            const paymentObject = new window.Razorpay(options);
            paymentObject.open();
            // paymentObject.on('payment.failed', function (response){
            //     alert(response.error.code);
            //     alert(response.error.description);
            //     alert(response.error.source);
            //     alert(response.error.step);
            //     alert(response.error.reason);
            //     alert(response.error.metadata.order_id);
            //     alert(response.error.metadata.payment_id);
            // });

        } catch (error) {
            console.log(error);
            setLoading(false);
        }                
    }
    document.body.appendChild(script);
}

  return (
    <div className='noselect bg-sky-50'>

        <Helmet>
            <title>TAHK - Certification</title>
            <meta
                name="description"
                content="Sustainability Certification by TreeAndHumanKnot for working professionals to learn and explore about Sustainable Development goals and how to have practical implication for Net ZERO"
            />
            <meta property="og:url" content={document.location.href} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content="TAHK - Certification" />
            <meta property="og:description" content="Sustainability Certification by TreeAndHumanKnot for working professionals to learn and explore about Sustainable Development goals and how to have practical implication for Net ZERO" />
            <meta property="og:image" content={logo}/>
            <link rel="image_src" href={logo} />
        </Helmet>

        <div>
            <Navbar/>
        </div>

        
        <article class="py-12 px-4 ">
        <h1 class="text-4xl text-center mb-4 font-semibold font-heading  text-sky-800">Sustainability Certification Program at <br/><span className=' text-lime-600'>TreeAndHumanKnot</span></h1>
        {/* <p class="text-center">
            <span>October 22, by</span>
            <a class="ml-1 text-indigo-600 hover:underline" >Michael Scott</a>
        </p> */}
        
        <div class="max-w-3xl mx-auto">
            <p class="mb-4">
              The Global movement TreeAndHumanKnot having a vision to re-enforce sustainability on Planet Earth and thriving towards growth path with its innovative intrapreneurial projects and presence in <b>3 continents</b> is now introducing world's first of it's Sustainability Certification Program a practical approach for enthusiasts who intend to build career into Sustainability Space and add value and possibly transform every business with sustainable business models.
            </p>
            <p class="mb-1">
              <span className='text-lg font-medium text-yellow-700'>About the Movement:</span><br/>
            </p>
            <p class="mb-4">
              TreeAndHumanKnot is cultivated from the eternal bond between Nature & Mankind. We bring pragmatic solutions for sustainability while enjoining love, pleasure, and compassion through strategically planting trees to make-people, communities and companies agents of-change. Inspired by quick conversation with one of the former environmental ministers, TreeAndHumanKnot was pre-launched in August 2020 which has now turned into a global movement
            </p>
            <p class="mb-1">
              <span className='lg:mt-10 mt-8 text-xl font-semibold mb-3 text-yellow-700'>(virtual) Program Details:</span><br/>
            </p>
            <p class="mb-1">
              We believe this would enhance following for sustainability enthusiasts (as you learn by doing it):            
            </p>
            <ol className='mb-2 list-inside list-decimal'>
              <li>Introduction to Sustainability, Social Enterprise and industry requirements</li>
              <li>Practical projects on Sustainable Development Goals set by United Nations agreed by your Country</li>
              <li>Peer learning across the 3 continents, Social Media and digital marketing skills</li>
              <li>Sharpen market research and understanding of development sector, learn to raise financing </li>
              <li>Persuasion, psychology and persistence which are very crucial skills in their career/job/business or even personal life.</li>
            </ol>
            <p class="mb-1">
              An ideal candidate on completion of the certification would be able to learn practical approach to work on one or more functional role based on the certification level, personal commitment and project execution, which will include:
            </p>
            <ul class="mb-4 list-inside list-disc">            
              <li> Content Strategy & Digital Marketing</li>
              <li>Graphic Designing & video editing</li>
              <li>Human Resource Management</li>
              <li>Event management (online & offline)</li>
              <li>Public relations and community management</li>
              <li>Government relations and Liaising</li>
              <li>International relations</li>
              <li>Institutional affiliations and corporate alliances</li>
              <li>Sustainable Education Technology</li>
              <li>Business Partnership Roles</li>
              <li>Market research and analytics</li>
            </ul>

            <p class="mb-1">
              <span className='text-lg font-medium text-yellow-700'>Intrapreneurial Projects:</span><br/>
            </p>
            <ul class="mb-2 list-inside list-disc">            
              <li>Sustainability Events: Green Birthday / Celebration, workshops, mentorship</li>
              <li>Developing Fruit Orchard – TAHK Oxygen Parks</li>
              <li>Waste Management and rainwater harvesting</li>
              <li> <span className='text-red-500 font-semibold'>SIP</span> <span className='text-blue-500 font-semibold'>Jr.</span> involving kids into Sustainability </li>
            </ul>
            <p class="mb-4">
              One can even Initiate a new project
            </p>

            <p class="mb-1">
              <span className='text-lg font-medium text-yellow-700'>Ideal Candidate for the certification:</span><br/>
            </p>
            <p class="mb-4">
            Someone who has a passion towards sustainability and development sector, a zeal to learn while building entrepreneurial spirit, and is looking at utilizing skills for serving Planet Earth
            </p>

            <p class="mb-1">
              <span className='text-lg font-medium text-yellow-700'>Duration and level:</span><br/>
            </p>
            <p class="mb-4">
              <span className='font-semibold'>Level 1:</span> Basic Sustainability Certification Level 3 months<br/>
              <span className='font-semibold'>Level 2:</span> Sustainability Warrior 6 months<br/>
              Advanced: Sustainability Management Certification 12 months <br/>
              Tentative Start of the program: June 1, 2022 or as decided by the management in case of a request for a batch of 10 or more<br/>
              Specially curated program for a college/educational institution’s batch<br/>
            </p>

            <p class="mb-1">
              <span className='text-lg font-medium text-yellow-700'>Location:</span><br/>
            </p>
            <p class="mb-4">
              <span className='font-semibold text-sky-900'>Indonesia, South East Asia - Planet Earth</span><br/>
              The program is curated for specific countries based on authorization provided through it's official channels contact <a href='mailto:india@tahk.in' target='_blank' className='text-blue-600 hover:underline'>india@tahk.in</a> for more detail or to collaborate. 
            </p>

            <p class="mb-1">
              <span className='text-lg font-medium text-yellow-700'>Timing:</span> Flexible (Project Result oriented) 
            </p>
            <p class="mb-1">
              <span className='text-lg font-medium text-yellow-700'>Days:</span> Flexible for the practical project execution and for learning sessions based on schedule decided (Candidates can pick any day off with prior information)
            </p>
            
            

            <h1 className='lg:mt-10 mt-8 text-xl font-semibold mb-3 text-yellow-700'>
              Process Details (Rounds/Levels of selection process):
            </h1>
            <ol class="mb-4 list-inside list-decimal">            
              <li>Click on enroll now and <span className='font-semibold'>submit</span> the screening form by clicking on the apply button on this page. </li>
              <li>Write a white paper (precise note) on <span className='font-semibold'>TreeAndHumanKnot </span>and email. </li>
              <li>To write a white paper aspirants may please read Sustainable Development Goals set by United Nations and write a precise note, make an artwork or any form to reflect how TreeAndHumanKnot is contributing towards any of the Sustainable Development Goal (SDG) out of the 17 SDGs</li>
              {/* <li>Email the White Paper to respective country email IDs</li>             */}
            </ol>

            <p class="mb-1">
              <span className='text-lg font-medium text-yellow-700'>Reward Details:</span><br/>
            </p>
            <ul class="mb-2 list-inside list-disc">            
              <li>Sustainability Certificate of completion</li>
              <li>Immense learning on your first step in corporate world</li>
              <li>Opportunity to get featured on global media and pages for higher reach </li>
              <li>Candidate will also earn a volunteering certificate on fulfilling the minimum criteria at TreeAndHumanKnot</li>
            </ul>

            <p class="mb-1">
              <span className='text-lg font-medium text-yellow-700'>Additional information:</span><br/>
            </p>
            <ul class="mb-4 list-inside list-disc">            
              <li>NO geographical limits, candidates learn from anywhere.</li>
              <li>People looking for just another certificate should not apply as Sustainability Certification Program is world first of it's certification in to Sustainability as we believe it will enhance potential of candidates. </li>
              <li>An opportunity to get absorbed by the global cohort of the ThinkTank across it's ventures.</li>
              <li>Aspirant will be expected to have strong desire to learn, keen interest into Sustainability and humble enough to Tie yet another TreeAndHumanKnot.</li>
            </ul>

            <p class="mb-4 mt-12 font-serif text-sky-600 text-lg text-center">
              <button
                onClick={loadRazorpay}
                disabled={loading}
                className='h-10 px-8 opacity-200 font-semibold text-base text-gray-50 dark:text-gray-50 shadow-sm hover:shadow hover:text-gray-100 transition-colors duration-200 transform bg-sky-500 rounded-3xl hover:bg-sky-600  hover:border-gray-500 uppercase'
              >
                Click to Enroll Now
              </button>
            </p>
            

        </div>
        </article>

        <Footer/>

    </div>
  )
}

export default Certification