import React from 'react';
import Footer from '../Footer/Footer';
import Navbar from '../Navbar/Navbar';
import $ from 'jquery';
import './FAQs.css';
import logo from '../../assets/logo.png'

import {Helmet} from "react-helmet";

function FAQs() {

  $(document).ready(() => {
    document.body.scrollTop = 0; 
    document.documentElement.scrollTop = 0;
  })

  const faqs = [
    {
      id: 1,
      question: 'What is TreeAndHumanKnot or TAHK?',
      answer: 'TreeAndHumanKnot is a sustainability movement which is driving to re-enforce sustainable living on the planet by working on concrete solutions while cultivating love and compassion in the eternal bond between Nature & Mankind that is a knot between a tree and a human.'
    },
    {
      id: 2,
      question: 'What does TAHK do?',
      answer: 'TreeAndHumanKnot promotes initiatives, projects and advice to individuals and professionals to achieve full sustainability. The transition to more productive and sustainable systems and practices is integrated to adopt a common vision for sustainable management and translate it into action.'
    },
    {
      id: 3,
      question: "What are TreeAndHumanKnot's projects?",
      answer: 'Planting trees, Green Birthday, Developing Fruit Orchard – TAHK Cluster Challenge, SIP Jr., Educational programs, Rural conservation as development, Waste Management and rainwater harvesting and many more to be added by selfless nature lovers.'
    },
    {
      id: 4,
      question: 'Why is it important to take part in these projects?',
      answer: 'Starting from such simple gestures, this is the first act we can all do to regenerate our environmental system and give nature back its space. It is based on generosity and recognition of nature for the fundamental role it plays in our existence on the planet, highlighting a common feeling of love and care for our ecosystem.'
    },
    {
      id: 5,
      question: 'Where is TreeAndHumanKnot located?',
      answer: 'TreeAndHumanKnot is headquartered in India, and also present in Italy, Peru and Turkey backed by selfless nature lovers and is expanding worldwide.'
    },
    {
      id: 6,
      question: 'What is the vision and mission of TreeAndHumanKnot?',
      answer: 'TreeAndHumanKnot wants to re-enforce the sustainable living on Planet Earth with the goal of tying one lakh TreeAndHumanKnots by 2030.'
    },
    {
      id: 7,
      question: 'What are the core values of TreeAndHumanKnot?',
      answer: 'Commitment, Consistency and Care for Nature.'
    },
    {
      id: 8,
      question: 'What consulting services are offered by TreeAndHumanKnot?',
      answer: 'TreeAndHumanKnot offers corporate social responsibility and sustainability for corporations.'
    },
    {
      id: 9,
      question: 'What is CSR with sustainability?',
      answer: 'Corporate Social Responsibility with sustainability is a mission promoted by TreeAnd HumanKnot GivingFirst Ideology of RisingIndia ThinkTank where the consulting cohort promotes sustainable strategies delivering expertly-crafted results for our ambitious clients. It is fixated on producing data-led transformational experiences that push the boundaries of the sustainability domain. It is based on being social, effective productivity, inclusive participation and moral responsibility.'
    },
    {
      id: 10,
      question: "In which areas does TAHK's Corporate Solutions operate?",
      answer: "Tahk's Corporate Solutions provides sustainable strategies on tree plantations with it’s unique TAHK O2 parks model and environment, agriculture and rural development/conservation, healthcare, educational and knowledge enhancement (education)."
    },
    {
      id: 11,
      question: 'Why should I register my social enterprise, NGO, trust or community with TreeAndHumanKnot?',
      answer: 'TreeAndHumanKnot offers innovative ideas backed by technology and sustainable business models, modern management principles, encouraging performances, work with international community to create an impact'
    },
    {
      id: 12,
      question: "What is SIP Jr. Camp?",
      answer: "SIPJr. is a kids campus program which promotes special interactive activities for children in order to develop awareness about sustainability. A unique program to attain a fellowship for Mother Nature! An innovative Kids Camp known as TAHK SIP Jr. filled with fun and learning Sustainability making it the world's first of its kind program, launched by former Mrs. India on World Environment Day June 5, 2021."
    },
    {
      id: 13,
      question: 'How can I join TreeAndHumanKnot?',
      answer: 'If you want to join TreeAndHumanKnot you can “Join the movement” as tahk.in eventually will become your own page. or you can write an email to india@tahk.in'
    },
    {
      id: 14,
      question: "What is TAHK's Sustainable Internship Program (SIP)?",
      answer: "TAHK's Sustainable Internship Program (SIP) is a curricular stage for students to bring practical knowledge about social enterprise, Sustainable Development Goals set by United Nations agreed by our country, social media and digital marketing skills, sharpen market research and understanding of development sector how to raise financing in a small way. Visit join us page to know more about Sustainable Internship Program"
    },
    {
      id: 15,
      question: 'What is Technology Internship Program (TIP)?',
      answer: 'Technology Internship Program (TIP) is a stage for students and professional to learn about     programming, web/application development, Artificial Intelligence Machine learning Internet of things or other technology field eventually enhance the reach of tahk.in'
    },
    {
      id: 16,
      question: 'How can I connect with TreeAndHumanKnot?',
      answer: 'You can connect with TreeAndHumanKnot by signing up and create your circle here'
    }
  ]

  const resumeFaq = [
    {
      id: 'r1',
      question: 'What information should I include?',
      answer: 'We want to hear about your latest accomplishment, your strengths, and how specific skills might be applied to a position at ThreeAndHumanKnot. Be sure to highlight your accomplishments- which field do you know well?  Do you have ideas about how you contribute to ThreeAndHumanKnots? Include them as attachments or links to give us a better sense of your style.'
    },
    {
      id: 'r2',
      question: "I've submitted the application form, Now what?",
      answer: 'TAHK team at your country location will review your application for each opening and reach out to those who appear to be a strong match with the position. After sending an application you need to submit white paper for completion to the respective country email ID which will be sent to our HR team.'
    },
    {
      id: 'r3',
      question: 'What is White Paper? What should I write?',
      answer: 'You need to understand about TreeAndHumanKnot and share your thoughts on how it can help Sustainability.You may add how you can contribute to extend this movement in your home country or overall TAHK movement.'
    }
  ]

  const careerFaq = [
    {
      id: 'c1',
      question: 'Does ThreeAndHumanKnot provide a flexible schedule?',
      answer: "Duration:45 days or 240 hours; 60 days or 320 hours; 90 days or 480 hours for advanced level, which can be extended up to 6 months for candidates who want to extend their careers with TAHK's Internal Entrepreneurship Projects. Peer learning around the world makes a great platform for Sustainability enthusiasts looking to take their first steps into the social/development sector."
    },
    {
      id: 'c2',
      question: 'Does ThreeAndHumanKnot offer students, graduates, interns?',
      answer: 'Yes, ThreeAndHumanKnot provides an equal opportunity to student, graduates, interns who applied voluntarily for their chosen functions based on their interest and competence.'
    },
    {
      id: 'c3',
      question: 'What is SIP Jr. Camp?',
      answer: "SIP Junior is a unique program to attain a fellowship for Mother Nature! An innovative Kids Camp known as TAHK SIP Jr. filled with fun and learning Sustainability making it the world's first of its kind program, launched by former Mrs. India on World Environment Day June 5, 2021."
    }
  ]

  return (
    <div className='noselect bg-sky-50'>
        <Helmet>
            <title>TAHK - FAQs</title>
            <meta
                name="description"
                content="Frequently Asked Questions about TreeAndHumanKnot"
            />
            <meta property="og:url" content={document.location.href} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content="TAHK - FAQs" />
            <meta property="og:description" content="Frequently Asked Questions about TreeAndHumanKnot" />
            <meta property="og:image" content={logo}/>
            <link rel="image_src" href={logo} />
        </Helmet>

        <div>
            <Navbar/>
        </div>

        
        <article class="py-12 px-2 md:px-4 ">
        <h1 class="text-4xl text-center mb-4 font-semibold font-heading text-sky-900">Frequently Asked Questions (FAQ's)</h1>
        {/* <p class="text-center">
            <span>October 22, by</span>
            <a class="ml-1 text-indigo-600 hover:underline" >Michael Scott</a>
        </p> */}
        
        <div class="max-w-5xl mx-auto">

            <main class="w-full p-8 mx-auto">
            <section class="shadow bg-gray-50 row">
                <div class="tabs">                    

                    {faqs.map((value, key) => {
                        return (
                          <div class="border-b tab">
                              <div class="border-l-2 border-transparent relative">
                                  <input class="inputelement w-full absolute z-10 cursor-pointer opacity-0 h-5 top-6" type="checkbox" id={value.id}/>
                                  <label class="flex justify-between items-center p-5 pl-8 pr-8 cursor-pointer select-none tab-label" for={value.id}>
                                      <span class="text-grey-darkest font-thin text-xl">
                                          {value.question}
                                      </span>
                                      <div class="rounded-full border border-grey w-7 h-7 flex items-center justify-center test">
                                          
                                          <svg aria-hidden="true" class="" data-reactid="266" fill="none" height="24" stroke="#606F7B" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewbox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                                              <polyline points="6 9 12 15 18 9">
                                              </polyline>
                                          </svg>
                                      </div>
                                  </label>
                                  <div class="tab-content">
                                      <div class="pl-8 pr-8 pb-5 text-grey-darkest">
                                          <p className='pl-4'>{value.answer}</p>
                                      </div>
                                  </div>
                              </div>
                          </div>
                        );
                   })}
                </div>
              </section>
            </main>
            
            <h1 class="text-3xl mx-auto mb-3 mt-12 text-center font-semibold font-heading text-gray-800">
              Help with getting hired
            </h1>

            <p class="mb-4 text-black text-center">
              TreeAndHumanKnot is cultivated from the eternal bond between Nature & Mankind.  We bring pragmatic solutions for sustainability while enjoining love, pleasure, and compassion through strategically planting trees to make-people, communities and companies agents-of-change.
              <br/>
              We all are volunteers to Mother Earth and should Tie yet another TreeAndHumanKnot.  
              <br/>
              Giving first with Social Enterprise TreeAndHumanKnot, which is cultivated from the eternal bond between Nature & Mankind.

            </p>

            <h1 class="text-2xl mx-auto mb-3 mt-12 text-center font-normal font-heading text-gray-700">
              Refine Your Resume
            </h1>

            <main class="w-full p-8 mx-auto">
            <section class="shadow bg-gray-50 row">
                <div class="tabs">                    

                    {resumeFaq.map((value, key) => {
                        return (
                          <div class="border-b tab">
                              <div class="border-l-2 border-transparent relative">
                                  <input class="inputelement w-full absolute z-10 cursor-pointer opacity-0 h-5 top-6" type="checkbox" id={value.id}/>
                                  <label class="flex justify-between items-center p-5 pl-8 pr-8 cursor-pointer select-none tab-label" for={value.id}>
                                      <span class="text-grey-darkest font-thin text-xl">
                                          {value.question}
                                      </span>
                                      <div class="rounded-full border border-grey w-7 h-7 flex items-center justify-center test">
                                          
                                          <svg aria-hidden="true" class="" data-reactid="266" fill="none" height="24" stroke="#606F7B" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewbox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                                              <polyline points="6 9 12 15 18 9">
                                              </polyline>
                                          </svg>
                                      </div>
                                  </label>
                                  <div class="tab-content">
                                      <div class="pl-8 pr-8 pb-5 text-grey-darkest">
                                          <p className='pl-4'>{value.answer}</p>
                                      </div>
                                  </div>
                              </div>
                          </div>
                        );
                   })}
                </div>
              </section>
            </main>

            <h1 class="text-2xl mx-auto mb-3 mt-12 text-center font-normal font-heading text-gray-700">
              Find a career fit
            </h1>

            <main class="w-full p-8 mx-auto">
            <section class="shadow bg-gray-50 row">
                <div class="tabs">                    

                    {careerFaq.map((value, key) => {
                        return (
                          <div class="border-b tab">
                              <div class="border-l-2 border-transparent relative">
                                  <input class="inputelement w-full absolute z-10 cursor-pointer opacity-0 h-5 top-6" type="checkbox" id={value.id}/>
                                  <label class="flex justify-between items-center p-5 pl-8 pr-8 cursor-pointer select-none tab-label" for={value.id}>
                                      <span class="text-grey-darkest font-thin text-xl">
                                          {value.question}
                                      </span>
                                      <div class="rounded-full border border-grey w-7 h-7 flex items-center justify-center test">
                                          
                                          <svg aria-hidden="true" class="" data-reactid="266" fill="none" height="24" stroke="#606F7B" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewbox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                                              <polyline points="6 9 12 15 18 9">
                                              </polyline>
                                          </svg>
                                      </div>
                                  </label>
                                  <div class="tab-content">
                                      <div class="pl-8 pr-8 pb-5 text-grey-darkest">
                                          <p className='pl-4'>{value.answer}</p>
                                      </div>
                                  </div>
                              </div>
                          </div>
                        );
                   })}
                </div>
              </section>
            </main>

        </div>
        </article>

        <Footer/>

    </div>
  )
}

export default FAQs