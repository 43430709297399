import React from 'react';
import Footer from '../Footer/Footer';
import Navbar from '../Navbar/Navbar';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import logo from '../../assets/logo.png'

import {Helmet} from "react-helmet";

function Institutions() {

  $(document).ready(() => {
    document.body.scrollTop = 0; 
    document.documentElement.scrollTop = 0;

  })

  return (
    <div className='noselect bg-sky-50'>

        <Helmet>
            <title>TAHK - Institutions</title>
            <meta
                name="description"
                content="TreeAndHumanKnot collaborates with major schools and universities across the planet for extending Sustainable Development Goals"
            />
            <meta property="og:url" content={document.location.href} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content="TAHK - Institutions" />
            <meta property="og:description" content="TreeAndHumanKnot collaborates with major schools and universities across the planet for extending Sustainable Development Goals" />
            <meta property="og:image" content={logo}/>
            <link rel="image_src" href={logo} />
        </Helmet>

        <div>
            <Navbar/>
        </div>

        
        <article class="py-12 px-4 ">
        <h1 class="text-4xl text-center mb-4 font-semibold font-heading text-sky-800">Institutions</h1>
        {/* <p class="text-center">
            <span>October 22, by</span>
            <a class="ml-1 text-indigo-600 hover:underline" >Michael Scott</a>
        </p> */}
        
        <div class="max-w-3xl mx-auto">            

            <p class="mb-2">
              Vision of <span className=' font-semibold text-amber-600'>TAHK</span> can only be achieved with united efforts and accordingly we are open to collaborate with various institutions, government bodies working towards Sustainability in any form.
            </p>

            <p class="mb-2">
              We have also initiated world’s first Sustainable Internship Program on March 13, 2021 and having bright students from prestigious institutions all over the globe for our various programs, enable us to invite potential institutions which would like to nurture their students who aspire to learn Sustainability 
            </p>

            <p class="mb-2 italic">
              "The <Link to='/sip' className='font-semibold text-sky-600'>Sustainable Internship Program (SIP)</Link> for a global social movement (also supporting SDGs by the United Nations)." 
            </p>

            <p class="mb-4 italic font-serif">
              Virtual Interns for SIP would be closely working with the global cohort for selected functional areas attributable to any of the verticals as described on <Link to='/sip' className='text-sky-600'>tahk.in/sip</Link> page
            </p>

            <h1 class="text-xl mx-auto mb-2 font-medium font-heading text-gray-800">
              We Honor Talent, that includes various prestigious Universities across the world
            </h1>

            <ul class="mb-4 list-inside list-disc">
            
            <li>Indian Institute Of Management–Calcutta</li>
            <li>Indian Institute Of Management–Bangalore</li>
            <li>Indira Gandhi National Tribal University</li>
            <li>Indian Institute of Management Jammu</li>
            <li>MIT World Peace University, Pune</li>
            <li>Luiss Guido Carli</li>
            <li>Università Degli Studi Di Pisa, Italy</li>
            <li>Universidad Católica San Pablo</li>
            <li>Universidad Científica del Sur</li>
            <li>Universidad San Ignacio de Loyola</li>
            <li>Universidad de Lima</li>
            <li>Universidad Nacional Mayor de San Marcos</li>
            <li>Instituto Toulouse Lautrec</li>
            <li>Universidad Nacional Agraria la Molina</li>
            <li>Pontificia Universidad Católica del Perú</li>
            <li>Universidad Peruana de Ciencias Aplicadas</li>
            <li>Faculty of Electrical Engineering, University of Sarajevo</li>
            <li>The Business School, University of Jammu</li>
            <li>Queen's University Belfast</li>
            <li>VIT University</li>
            <li>Punjab University</li>
            <li>Jaipuria Institute of Management, Jaipur</li>
            <li>Lovely Professional University</li>
            <li>Mumbai University</li>
            <li>Sri Sathya Sai Institute Of Higher Learning</li>
            <li>University of Delhi</li>
            <li>NITTE School of Management</li>
            <li>SCMS School Of Engineering And Technology</li>

            </ul>

            <h1 class="text-lg mx-auto mb-2 font-medium font-heading text-gray-800">
              TAHK Italia institutional collaborations
            </h1>
            <ul class="mb-4 list-inside list-disc">        
           
              <li>Università degli Studi di Cagliari</li>
              <li>Università Ca' Foscari di Venezia</li>
              <li>Università degli Studi di Milano</li>
              <li>Università di Bologna</li>
              <li>Università degli Studi di Padova</li>
              <li>LUISS Guido Carli</li>
              <li>Università degli Studi di Cassino</li>
              <li>Università di Pavia</li>
              <li>Università di Salerno</li>
              <li>Università Tor Vergata</li>
              <li>Università di Parma</li>
              <li>Università LIUC Cattaneo</li>
              <li>UNiversità degli studi di Genova</li>
              <li>Università degli studi della Basilicata</li>
              <li>Università degli studi di Modena e Reggio Emilia</li>
              <li>Università degli studi di Bergamo</li>
              <li>Università di Ferrara</li>
              <li>Università di Napoli Parthenope</li>
              <li>Università di Palermo</li>
              <li>UNiversità di Pavia</li>
              <li>Università di Pisa</li>
              <li>Università di Salerno</li>
              <li>Univeisità di Verona</li>
            </ul>

            <p class="mb-4 text-black">
              If your University is not listed in the above list, please reach out to your University and <a href='mailto:india@tahk.in' className='text-blue-600 hover:underline'>email</a> us as well.
            </p>

            <p class="mb-4">
              We are also grateful that to be honored as <b>mentor</b> by <span className='font-serif'>Ca' Foscari University of Venice, Italy</span> for mentoring students seeking International Experience into sustainability space.
            </p>
            
        </div>
        </article>

        <Footer/>

    </div>
  )
}

export default Institutions