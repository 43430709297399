import React from 'react';
import Footer from '../Footer/Footer';
import Navbar from '../Navbar/Navbar';
import { Link } from 'react-router-dom';
import {useEffect} from 'react';
import {InlineWidget, PopupModal, PopupButton, PopupWidget} from 'react-calendly'
import logo from '../../assets/logo.png'

import {Helmet} from "react-helmet";


function SIP() {

  useEffect(() => {
    document.body.scrollTop = 0; 
    document.documentElement.scrollTop = 0;
  });  

  return (
    <div className='noselect bg-sky-50'>

      <Helmet>
            <title>TAHK - SIP</title>
            <meta
                name="description"
                content="World's first Sustainable Internship Program recognised by global universities to nurture the next generation"
            />
            <meta property="og:url" content={document.location.href} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content="TAHK - Sustainable Internship Program" />
            <meta property="og:description" content="World's first Sustainable Internship Program recognised by global universities to nurture the next generation" />
            <meta property="og:image" content={logo}/>
            <link rel="image_src" href={logo} />
        </Helmet>

        <div>
            <Navbar/>
        </div>

        
        <article class="py-12 px-4 ">
        <h1 class="text-4xl text-center mb-4 font-semibold font-heading capitalize text-sky-800">sustainable internship program (SIP)</h1>
        {/* <p class="text-center">
            <span>October 22, by</span>
            <a class="ml-1 text-indigo-600 hover:underline" >Michael Scott</a>
        </p> */}
        
        <div class="max-w-3xl mx-auto">
            <p class="mb-4">
              The Global movement <b>TreeAndHumanKnot</b> with a vision to re-enforce sustainability on Planet Earth by 2030 and thriving to grow with its innovative intrapreneurial projects.  <span className='italic font-medium font-serif'>Virtual Interns for SIP would be closely working with the global cohort for selected functional areas as Serving Mother Earth is the profound service.</span>
            </p>
            <p class="mb-1">
              <span className='text-lg font-medium text-yellow-700'>SIP will enhance following for students (as you learn by doing it):</span><br/>
            </p>
            
            <ol className='mb-4 list-inside list-decimal'>
              <li>Introduction to Social Enterprise</li>
              <li>Sustainable Development Goals set by United Nations agreed by your Country</li>
              <li>Peer learning across the 3 continents, Social Media and digital marketing skills</li>
              <li>Sharpen market research and understanding of development sector</li>
              <li>Persuasion, psychology and persistence which are very crucial skills in their career/job/business or even personal life.</li>
            </ol>

            <p class="mb-1">
              <span className='text-lg font-medium text-yellow-700'>Advantage for Universities:</span><br/>
            </p>
            <ul class="mb-4 list-inside list-disc">            
              <li>Sustainability Workshop for your institution</li>
              <li>International Mentorship Program for students</li>
            </ul>

            <p class="mb-4">
              <span className='text-base font-semibold text-gray-800'>Duration: </span>Level 1 (240 Hours) to Sustainability Warrior (6 months) and leadership<br/>
              <span className='text-base font-semibold text-gray-800'>Location: </span>No geographical limits, candidates can work from where they can work best<br/>
              <span className='text-base font-semibold text-gray-800'>Work Timing: </span>Flexible (Result oriented)<br/>
              <span className='text-base font-semibold text-gray-800'>Workdays: </span>Flexible (Candidates can pick any day off with prior information)<br/>
            </p>

            <p class="mb-1">
              <span className='text-lg font-medium text-yellow-700'>Rewards/Perks</span><br/>
            </p>
            <ul class="mb-4 list-inside list-disc">            
              <li>Flexible work days and work hours</li>
              <li>A platform for immense learning which you will surely cherish throughout your career</li>
              <li>An opportunity to eventually join NextStartups by RisingIndia ThinkTank</li>
            </ul>

            <p class="mb-1">
              <span className='text-lg font-medium text-yellow-700'>Functional Roles</span><br/>
            </p>
            <p class="mb-1">
              Chose according to your interests or the skills you want to develop:
            </p>
            <ul class="mb-4 list-inside list-disc">            
              <li>Content Strategy & Digital Marketing</li>
             	<li>Graphic Designing, video production & editing</li>
              <li>Human Resource Management</li>
              <li>Event management (online & offline)</li>
              <li>Public relations and community management</li>
              <li>Government relations and Liaising</li>
              <li>International relations </li>
              <li>Institutional affiliations and corporate alliances</li>
              <li>Soil science and Agriculture Technology</li>
              <li>Market research and analytics</li>              
            </ul>

            <h1 className='lg:mt-10 mt-8 text-xl font-semibold mb-3 text-yellow-700'>
              Process Details (Rounds/Levels of selection process):
            </h1>
            <ol class="mb-4 list-inside list-decimal">            
              <li><span className='font-semibold'>Submit</span> the screening form by clicking on the apply button on this page, on submission expect an email from tahkindia@gmail.com (add this email to contacts to avoid getting it into spammed) </li>
              <li>Write a white paper (precise note) on <span className='font-semibold'>TAHK</span> and please also add 'your strengths which can enable you' to contribute in your chosen function </li>
              <li>To write a white paper aspirants may please read Sustainable Development Goals set by United Nations and write a precise note, make an artwork or any form to reflect how TreeAndHumanKnot is contributing towards any of the Sustainable Development Goal (SDG) out of the 17 SDGs</li>
              <li>Email the White Paper to respective country email IDs</li>            
            </ol>
            <p class="mb-4">
              <span className='text-base font-semibold text-gray-800'>India: </span>&nbsp;<a href='mailto:tahkindia@gmail.com' className='italic text-blue-500 hover:underline'>tahkindia@gmail.com</a><br/>
              <span className='text-base font-semibold text-gray-800'>Italy: </span>&nbsp;<a href='mailto:tahk.italia@gmail.com' className='italic text-blue-500 hover:underline'>tahk.italia@gmail.com</a><br/>
              <span className='text-base font-semibold text-gray-800'>Peru: </span>&nbsp;<a href='mailto:tahk.peru@gmail.com' className='italic text-blue-500 hover:underline'>tahk.peru@gmail.com</a><br/>
              <span className='text-base font-semibold text-gray-800'>Turkey: </span>&nbsp;<a href='mailto:tahk.turkey@gmail.com' className='italic text-blue-500 hover:underline'>tahk.turkey@gmail.com</a><br/>
              <span className='text-base font-semibold text-gray-800'>UK(EU): </span>&nbsp;<a href='mailto:tahk.united@gmail.com' className='italic text-blue-500 hover:underline'>tahk.united@gmail.com</a><br/>
              <span className='text-base font-semibold text-gray-800'>USA: </span>&nbsp;<a href='mailto:tahk.usa@gmail.com' className='italic text-blue-500 hover:underline'>tahk.usa@gmail.com</a><br/>
              <span className='text-base font-semibold text-gray-800'>Pakistan: </span>&nbsp;<a href='mailto:tahk.pakistan@gmail.com' className='italic text-blue-500 hover:underline'>tahk.pakistan@gmail.com</a><br/>
              <span className='text-base font-semibold text-gray-800'><span className='text-red-500'>SIP</span><span className='text-blue-600'>Jr.</span>: </span>&nbsp;<a href='mailto:sip@risingindia.in' className='italic text-blue-500 hover:underline'>sip@risingindia.in</a><br/>
              <span className='text-base font-semibold text-gray-800'>ROW: </span>&nbsp;<a href='mailto:india@tahk.in' className='italic text-blue-500 hover:underline'>india@tahk.in</a><br/>
            </p>

            <p class="mb-4 font-serif">
              In case you do not get a response after sending your white paper within 24 hours. You may please&nbsp;
              <PopupButton
                url="https://calendly.com/risingindia/"
                rootElement={document.getElementById("root")}
                text="schedule an interview"
                className='text-sky-600 hover:text-sky-700 hover:underline'
                />
                &nbsp;to connect with founding team.
            </p>

            <p class="mb-8 font-serif text-amber-600 italic">
              SUSTAINABLE INTERNSHIP PROGRAM IS EXCLUSIVE TO ANY OTHER ENGAGEMENT BUT YOU CAN ALWAYS JOIN VOLUNTEERING LEADERSHIP PROGRAM
            </p>

            <p class="mb-4 mt-12 font-serif text-sky-600 text-lg text-center">
            {/* <PopupButton
              url="https://calendly.com/risingindia/"
              rootElement={document.getElementById("root")}
              text="Schedule time with us"
              className='mt-4 h-10 px-4 opacity-200 font-semibold text-base text-gray-50 dark:text-gray-50 shadow-sm hover:shadow hover:text-gray-100 transition-colors duration-200 transform bg-sky-500 rounded-3xl hover:bg-sky-600  hover:border-gray-500 uppercase'
              /> */}
              <a 
                href='https://docs.google.com/forms/d/e/1FAIpQLSdhI82rxSMKkqfckzt4wV3qhyavva8fFKHRPr9IzAhC-0Hv9g/viewform'
                target='_blank'
                style={{paddingTop: '0.7rem', paddingBottom: '0.7rem'}}
                className='mt-8 h-10 px-8 opacity-200 font-semibold text-base text-gray-50 dark:text-gray-50 shadow-sm hover:shadow hover:text-gray-100 transition-colors duration-200 transform bg-sky-500 rounded-3xl hover:bg-sky-600  hover:border-gray-500 uppercase'
              >
                Apply For SIP
              </a>
            </p>



        </div>
        </article>

        <Footer/>

    </div>
  )
}

export default SIP