import React, { useEffect, useState } from 'react'
import AdminService from '../../../services/AdminService';
import OrganizationService from '../../../services/OrganizationService';

import { Link, useNavigate } from 'react-router-dom';
import NotFound404 from '../../NotFound404/NotFound404';

function AdminDashboard() {

  let navigate = useNavigate();

  const [usersCount, setUsersCount] = useState(0);
  const [viewersCount, setViewersCount] = useState(0);
  const [isAuthorized, setIsAuthorized] = useState(false);

  useEffect(() => {
    AdminService.validateToken().then((response) => {
      if(response.data.status === 200) {
        AdminService.allViewers().then((response) => {
          setViewersCount(response.data.viewers.length);
        })
        AdminService.allUsers().then((response) => {
          setUsersCount(response.data.users.length);
        })
        setIsAuthorized(true);
      } else {
        setIsAuthorized(false);
      }
    })
  }, [])

  const updatePassword = () => {
    const newPassword = document.getElementById('newPassword').value;
    const oldPassword = document.getElementById('oldPassword').value;
    const updatePayload = {newPassword: newPassword, oldPassword: oldPassword}

    if(newPassword === ''){
      alert("Please enter the new password");
    } else if (oldPassword === '') {
      alert("Please enter the old password");
    } else {
      AdminService.updateAdminPassword(updatePayload).then((response) => {
        if(response.data.status === 200) {
            alert(response.data.message);
            window.location.reload();
        } else if(response.data.status) {
            alert(response.data.status + " : " + response.data.error);
        } else if(response.data.error) {
            alert(response.data.error);
        }
      })
    }    

  }

  const openModal = () => {
    document.getElementById('modal').classList.remove('hidden');
    document.getElementById('newPassword').focus();
  }

  const closeModal = () => {
      document.getElementById('modal').classList.add('hidden');
      document.getElementById('newPassword').value = '';
      document.getElementById('oldPassword').value = '';
  }

  const logout = () => {
    sessionStorage.removeItem('accessToken');
    navigate('/tahklogin');
  }

    const togglePassword = (id) => {
        
        if(document.getElementById(id).type === 'password'){
          document.getElementById(id).type = 'text';
          document.getElementById(id + 'ShowHide').innerHTML = 'Hide';
        } else {
          document.getElementById(id).type = 'password';
          document.getElementById(id + 'ShowHide').innerHTML = 'Show';
        }
    }

  return (
    <div>

    {

      isAuthorized?
        <div>
          <div className='container px-8 py-6 mt-8'>
            <div class="px-4 md:px-10 ">
                <div class="flex items-center justify-between font-serif">
                  <p tabindex="0" class="focus:outline-none text-3xl lg:text-5xl font-bold leading-normal text-gray-700">Admin Dashboard</p>
                    <button onClick={logout}><div class="p-3 flex items-center text-xl font-medium leading-none text-gray-200 bg-gray-700 hover:bg-gray-800 cursor-pointer rounded">
                      Logout                               
                    </div></button>  
                </div>
            </div>
            <div class="grid grid-cols-3 lg:grid-cols-2 gap-5 w-full p-4 mt-10">
              <Link to='/tahkfamily/allusers'><div class="bg-green-200 py-2 rounded-md ">
                <div class="flex">
                  <div>
                  <h2 class="text-base md:text-2xl lg:text-5xl px-2 whitespace-no-wrap text-gray-600 noselect">View Users</h2>
                  <h2 class="ltext-base md:text-2xl lg:text-5xl px-4 py-2 lg:px-8 text-gray-600">{usersCount}</h2>
                  </div>
                  <div class="flex justify-end items-end">
                    <svg
                      class="w-10 h-10 md:w-20 md:h-20 lg:w-36 lg:h-36 text-gray-600 "
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 640 512"
                    >
                      <path
                        d="M96 224c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm448 0c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm32 32h-64c-17.6 0-33.5 7.1-45.1 18.6 40.3 22.1 68.9 62 75.1 109.4h66c17.7 0 32-14.3 32-32v-32c0-35.3-28.7-64-64-64zm-256 0c61.9 0 112-50.1 112-112S381.9 32 320 32 208 82.1 208 144s50.1 112 112 112zm76.8 32h-8.3c-20.8 10-43.9 16-68.5 16s-47.6-6-68.5-16h-8.3C179.6 288 128 339.6 128 403.2V432c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48v-28.8c0-63.6-51.6-115.2-115.2-115.2zm-223.7-13.4C161.5 263.1 145.6 256 128 256H64c-35.3 0-64 28.7-64 64v32c0 17.7 14.3 32 32 32h65.9c6.3-47.4 34.9-87.3 75.2-109.4z"
                      ></path>
                    </svg>
                  </div>
                </div>
              </div></Link>
              <Link to='/tahkfamily/viewers' class="bg-yellow-200 py-2 rounded-md ">
                <div class="flex">
                  <div>
                  <h2 class="text-base md:text-2xl lg:text-5xl px-2 whitespace-no-wrap text-gray-600 noselect">Viewers</h2>
                  <h2 class="ltext-base md:text-2xl lg:text-5xl px-4 py-2 lg:px-8 text-gray-600">{viewersCount}</h2>
                  </div>
                </div>
              </Link>
              {/* <div class="bg-red-200 py-2 rounded-md">
                <div class="flex justify-center">
                  <div>
                    <h2 class="text-base md:text-2xl lg:text-5xl px-2 whitespace-no-wrap text-gray-600 noselect">Update Admin Password</h2>
                    
                  </div>
                </div>
              </div> */}
            </div>
              <div className='mx-auto mt-10 noselect'>
                <p class="mb-4 font-serif text-sky-600 text-lg italic text-center">
                  <button onClick={openModal}
                    className='mt-4 h-10 px-4 opacity-200 font-semibold text-base text-gray-50 dark:text-gray-50 shadow-sm hover:shadow hover:text-gray-100 transition-colors duration-200 transform bg-sky-500 rounded-3xl hover:bg-sky-600  hover:border-gray-500 uppercase'
                  >Update Admin Password</button>
                </p>
              </div>
              <div className='mx-auto mt-10 noselect'>
                <p class="mb-4 font-serif text-sky-600 text-lg italic text-center">
                  <Link to='/tahkfamily/addpost'
                    className='mt-10 h-10 px-4 py-[0.65rem] opacity-200 font-semibold text-base text-gray-50 dark:text-gray-50 shadow-sm hover:shadow hover:text-gray-100 transition-colors duration-200 transform bg-pink-600 rounded-3xl hover:bg-pink-700  hover:border-gray-500 uppercase'
                  >Add Post</Link>
                </p>
              </div>
          </div>
            <div id='modal' class="modal hidden h-screen w-full fixed left-0 top-0 flex justify-center items-center bg-black bg-opacity-50">
                
                        <div class="bg-white rounded shadow-lg w-10/12 md:w-1/3 noselect">
                            
                            <div class="border-b px-4 py-2 flex justify-between items-center">
                                <h3 class="font-semibold text-lg">Update Password</h3>
                                <button onClick={closeModal} class="text-black close-modal">X</button>
                            </div>
                            
                            <div class="p-3">
                                {/* <p >Enter <b>username</b>, <b>password</b> and <b>country</b> of new viewer:</p> */}
                                <label htmlFor='newPassword' className='font-medium'>New Password:</label>
                                <div class="relative w-full">
                                    <input type='password' id="newPassword" class="block w-full py-3 px-3 mt-2 
                                        text-gray-800 appearance-none
                                        border-2 border-gray-100
                                        focus:text-gray-800 focus:outline-none focus:border-gray-200 rounded-md" 
                                        placeholder='New Password' name = 'newPassword'
                                    />
                                        <div class="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
                                            <p id='newPasswordShowHide' class="font-semibold hover:cursor-pointer noselect" onClick={() => {togglePassword('newPassword')}} >Show</p>
                                        </div>
                                </div>
                                <label htmlFor='newPassword' className='mt-4 font-medium'>Old Password:</label>
                                <div class="relative w-full">
                                    <input type='password' id="oldPassword" class="block w-full py-3 px-3 mt-2 
                                        text-gray-800 appearance-none
                                        border-2 border-gray-100
                                        focus:text-gray-800 focus:outline-none focus:border-gray-200 rounded-md" 
                                        placeholder='Old Password' name = 'oldPassword'
                                    />
                                        <div class="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
                                            <p id='oldPasswordShowHide' class="font-semibold hover:cursor-pointer noselect" onClick={() => {togglePassword('oldPassword')}} >Show</p>
                                        </div>
                                </div>

                            </div>
                            <div class="flex justify-end items-center w-100 border-t p-3">
                                <button onClick={closeModal} class="bg-red-600 hover:bg-red-700 px-3 py-1 rounded text-white mr-1 close-modal">Cancel</button>
                                <button onClick={updatePassword} class="bg-blue-600 hover:bg-blue-700 px-3 py-1 rounded text-white">Update</button>
                            </div>
                        </div>
            </div>
        </div>

        :

          <NotFound404/>

    }  
    </div>
  )
}

export default AdminDashboard