import React from 'react';
import Footer from '../Footer/Footer';
import Navbar from '../Navbar/Navbar';
import $ from 'jquery';
import logo from '../../assets/logo.png'

import {Helmet} from "react-helmet";

function Corporates() {

  $(document).ready(() => {
    document.body.scrollTop = 0; 
    document.documentElement.scrollTop = 0;

  })

  return (
    <div className='noselect bg-sky-50'>

        <Helmet>
            <title>TAHK - Corporates</title>
            <meta
                name="description"
                content="Engage corporate to drive their efforts towards Net ZERO by integrating CSR and adequate Sustainability Reporting"
            />
            <meta property="og:url" content={document.location.href} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content="TAHK - Corporates" />
            <meta property="og:description" content="Engage corporate to drive their efforts towards Net ZERO by integrating CSR and adequate Sustainability Reporting" />
            <meta property="og:image" content={logo}/>
            <link rel="image_src" href={logo} />
        </Helmet>

        <div>
            <Navbar/>
        </div>

        
        <article class="py-12 px-4 ">
        <h1 class="text-4xl text-center mb-4 font-semibold font-heading text-sky-800">Welfare Engine for Corporate India</h1>
        {/* <p class="text-center">
            <span>October 22, by</span>
            <a class="ml-1 text-indigo-600 hover:underline" >Michael Scott</a>
        </p> */}
        
        <div class="max-w-3xl mx-auto">
            <p class="mb-3">
              <span className='text-emerald-600'>Being Social</span> Our intention of living for people drives us to thrive for good corporate governance that bring sustainable business. Consulting Cohort is a Corporate Social Responsibility and sustainability team who deliver expertly-crafted results for our ambitious clients. We are fixated on producing data-led transformational experiences that push the boundaries of sustainability domain
            </p>
            <p class="mb-4">
              Since 2015, our team has grown into a diverse group of talented people each bringing different, remarkable skills to the table. We put our clients at the heart of everything we do, creating value for their brands and helping them evolve into market leaders.
            </p>

            <h1 className='lg:mt-10 mt-8 text-2xl font-semibold mb-3 text-sky-600'>
              Organizational Overview
            </h1>
            <p class="mb-2">
              <span className='text-cyan-600 font-medium font-serif'>Vision</span> <span className='text-emerald-700'>Effective Productivity</span> Smart way Pursuit for impact lead us towards productive and effective utilization of CSR resources.
            </p>
            <p class="mb-2">
              <span className='text-cyan-600 font-medium font-serif'>Mission</span> <span className='text-emerald-700'>Inclusive Participation</span> Better together To rise quality of life and contribute to critical sectors, with inclusive participation by businesses by 2023.
            </p>
            <p class="mb-4">
              <span className='text-cyan-600 font-medium font-serif'>Values</span> <span className='text-emerald-700'>Ethical Compass and Moral values</span> Commitment to be responsible towards society and enhance credibility of India Inc.
            </p>
            <p class="mb-4">
              <span className='text-base font-medium text-sky-700 font-serif'>Tree plantation and environment: </span>CSR with Sustainability, we provide sustainable strategies for creating fruit orchards known to be oxygen parks across cities and employee engagement for organizations. Watch the TreeAndHumanKnot space to learn more about people's commitment to this sustainability movement
            </p>
            <p class="mb-4">
              <span className='text-base font-medium text-sky-700 font-serif'>Agriculture and rural conservation: </span>We strive to find new strategies for dealing with this challenge. Rural conservation is for cultural sustainability as development often considered to be infrastructural development and our team is working each and every day to retain & make a positive impact in many ways.
            </p>
            <p class="mb-4">
              <span className='text-base font-medium text-sky-700 font-serif'>Healthcare and hygeine: </span>We are dedicated to stepping up our efforts in healthcare and hygiene. Health Care is by no means an easy feat, but through cooperation and community empowerment we believe we can facilitate progress in this area. We are always striving to make a difference, and invite you to learn more and lend your support.
            </p>
            <p class="mb-4">
              <span className='text-base font-medium text-sky-700 font-serif'>Knowledge Enhancement - Education: </span>Facilitating in education sector to promote great opportunities for those in need. With access to the right resources, people can become empowered by their own abilities and gain the confidence to fulfill their potential. Learn more about our work by getting in touch with our team today.
            </p>

            <p class="mb-4 font-serif text-teal-600 text-[1.05rem] italic">
              India’s listed entity Navneet Education Limited joined the movement to tie a TreeAndHumanKnot during the last Monsoon.
            </p>

            <p class="mb-4">
              <span className='text-lg font-medium text-gray-700 font-serif'>Email: </span><a href='mailto:we@tahk.in' className='text-sky-600 hover:underline'>we@tahk.in</a>
            </p>
        </div>
        </article>

        <Footer/>

    </div>
  )
}

export default Corporates