import React from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';

import {Helmet} from "react-helmet";

// images
import logo from '../../assets/logo.png';
import education from '../../assets/coverImgs/education.png';
import gender from '../../assets/coverImgs/gender.jpeg';
import person from '../../assets/coverImgs/person.png';
import plantation from '../../assets/coverImgs/plantation.png';
import saveWater from '../../assets/coverImgs/saveWater.jpeg';
import saveBirds from '../../assets/coverImgs/saveBirds.jpeg';

import sdg1 from '../../assets/sdgs/sdg1.webp';
import sdg2 from '../../assets/sdgs/sdg2.webp';
import sdg3 from '../../assets/sdgs/sdg3.jpg';
import sdg4 from '../../assets/sdgs/sdg4.jpg';
import sdg5 from '../../assets/sdgs/sdg5.jpg';
import sdg6 from '../../assets/sdgs/sdg6.jpg';

import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';

function SDG() {
  
    $(document).ready(() => {
        document.body.scrollTop = 0; 
        document.documentElement.scrollTop = 0;

    })

    const sdgs = [
        {
            number: 1,
            img: sdg1,
            sdgName: 'NO POVERTY',
            url: '/sdg/1'
        },
        {
            number: 2,
            img: sdg2,
            sdgName: 'ZERO HUNGER',
            url: '/sdg/2'
        },
        {
            number: 3,
            img: sdg3,
            sdgName: 'GOOD HEALTH AND WELL-BEING',
            url: '/sdg/3'
        },
        {
            number: 4,
            img: sdg4,
            sdgName: 'QUALITY EDUCATION',
            url: '/sdg/4'
        },
        {
            number: 5,
            img: sdg5,
            sdgName: 'GENDER EQUALITY',
            url: '/sdg/5'
        },
        {
            number: 6,
            img: sdg6,
            sdgName: 'Clean water and sanitation',
            url: '/sdg/6'
        },
    ]

  return (
    <div className='bg-sky-50'>
      <div class="w-full  noselect">

            <Helmet>
                <title>TAHK - SDGs</title>
                <meta
                    name="description"
                    content="Simple solutions to achieve Sustainable Development Goals"
                />
                <meta property="og:url" content={document.location.href} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="TAHK - Sustainable Development Goals" />
                <meta property="og:description" content="Simple solutions to achieve Sustainable Development Goals" />
                <meta property="og:image" content={logo}/>
                <link rel="image_src" href={logo} />
            </Helmet>
        
        <div className=''>
            <Navbar/>
        </div>
        

            <div id='intrapreneurial'>
              <div class="bg-teal-200 text-center w-full py-10 shadow-sm">
                  <h1 class="font-bold text-3xl md:text-4xl lg:text-5xl font-heading text-sky-900 md:mb-2 lg:mb-2">
                    Sustainable Development Goals (SDG's)    
                  </h1>
                  <p className="text-sky-700 text-lg font-bold mx-10 lg:mx-40  mt-6">
                    Idea was conceived back in 2019, and realized that by working together we could overcome our challenges much more effectively with ideology of giving first, that led to emergence of <b className='text-gray-900'>T</b>ree<b className='text-gray-900'>A</b>nd<b className='text-gray-900'>H</b>uman<b className='text-gray-900'>K</b>not with pragmatic solutions for sustainability while enjoining love, pleasure, and compassion through strategically planting trees to make-people, communities and companies agents of-change, which is possible by simple solutions. That resonates with Sustainable Development Goals set by the United Nations. <br/>Tying a Tree And Human Knot works as an accelerator for achieving all 17 UN sustainable development goals.
                  </p>
              </div>
              <div class="cards mx-10 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-10 mt-6">

                  {sdgs.map((value, key) => {
                        return (
                            <Link to={value.url}>
                                <div class="box w-full h-96 bg-cover bg-no-repeat bg-center bg-gray-200 rounded-2xl sahdow-lg p-12 flex flex-col justify-center items-center transform hover:scale-105 transition duration-500" style={{backgroundImage: `url(${value.img})`}}>
                                    <div class="d text-center opacity-0">
                                        <p class="text-xl text-white font-bold mb-2 uppercase">{value.sdgName}</p>
                                        {/* <p class="text-base text-gray-400 font-normal">2022</p> */}
                                    </div>
                                </div>
                            </Link>
                        );
                   })}
                  
              </div>
            </div>
      </div>

      <div id='footer' className='mt-10'>
      <Footer/>
      </div>

    </div>
  )
}

export default SDG