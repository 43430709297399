import React from 'react';
import { Link } from 'react-router-dom';
import './Home.css';
import Navbar from '../Navbar/Navbar';
import WorldMap from '../WorldMap2/WorldMap2';
import $ from 'jquery';
import {Helmet} from "react-helmet";

import {FacebookIcon, FacebookShareButton} from 'react-share' 

// images
import logo from '../../assets/logo.png';
import leftimg from '../../assets/leftSide.jpeg';
import Footer from '../Footer/Footer';


function Home() {

  // var noselect;

  $(document).ready(() => {
    document.body.scrollTop = 0; 
    document.documentElement.scrollTop = 0;
  })

  return (
    <div 
      class='noselect'
    >

             <Helmet>
                <title>TAHK - TreeAndHumanKnot</title>
                <meta
                  name="description"
                  content="Reinventing Sustainability by connecting people with Nature"
                />

                <meta property="og:url" content={document.location.href} />
                <meta property="og:type" content="website" />
                <meta property="og:image" itemprop="image" content={logo}/>

                <meta name="msapplication-TileImage" content={logo}/>
 
                <meta property="og:site_name" content="TAHK - TreeAndHumanKnot"/>
                <meta property="og:title" content="TAHK - TreeAndHumanKnot"/>
                <meta property="og:description" content="Reinventing Sustainability by connecting people with Nature" />

                <meta property="og:image:type" content="image/png"/>

                <meta property="og:image:width" content="300"/>
                <meta property="og:image:height" content="300"/>

                <meta property="twitter:card" content="summary_large_image"/>
                <meta property="twitter:url" content={document.location.href}/>
                <meta property="twitter:title" content="TAHK - TreeAndHumanKnot"/>
                <meta property="twitter:description" content="Reinventing Sustainability by connecting people with Nature"/>
                <meta property="twitter:image" content={logo}/>

            </Helmet>

      <div id='navbar'>
      <Navbar/>
      </div>

      {/* <FacebookShareButton url="https://www.firstpost.com/world/explained-why-johnny-depp-remains-a-wife-beater-in-uk-but-wins-defamation-case-in-us-10748701.html"  hashtag='#tahk'>
        <FacebookIcon/>
      </FacebookShareButton>       */}

      {/* Container */}

      <div id='header' class="flex flex-wrap md items-center bg-gradient-to-b from-sky-300 via-sky-200 to-sky-100">
        
        <div class=" w-full md:w-1/2 h-screen bg-cover bg-no-repeat bg-center bg-gradient-to-b from-sky-300 via-sky-200 to-sky-100" style={{backgroundImage: `url(${leftimg})`}}>
          <div class="mx-auto relative">
            <div class="flex justify-center mt-40">
                {/* <!-- Logo --> */}
                
                    <h1 class="heading font-medium text-blue-500">
                      <span className='text-3xl font-semibold text-rose-400'>Coming Soon!</span>
                    </h1>
                    
            </div>

            <div className='text-center'>
              <h1 class="heading text-2xl font-semibold mt-32 md:mt-44 mx-12 md:mx-20  text-red-400">
                {/* <span className='text-[1.65rem] text-rose-400'>Coming Soon!</span><br/> */}
                
                A global initiative to reinforce sustainable living on planet earth by 2030 is now turning into World’s first Sustainability Network

                {/* <span className='text-[1.65rem] text-rose-400'>Coming Soon!</span> */}
              </h1>
            </div>            
            
          </div>
        </div>
        <div class=" w-full md:w-1/2 h-screen bg-gradient-to-b from-sky-300 via-sky-200 to-sky-100" >
          <div class="mx-auto">
            <div class="flex justify-center mt-14">
                {/* <!-- Logo --> */}
                
                    <h1 class="font-medium text-blue-500">
                        <img class="h-40 object-cover"
                            src={logo} alt="Logo"/>                        
                    </h1>
                    
            </div>

            <div className='text-center'>
              <h1 
                // id="text3d" 
                className='text-3xl bg-clip-text bg-gradient-to-tl from-amber-600 via-amber-400 to-amber-600 text-transparent font-serif font-medium mt-1 antialiased'>
              <span className='font-semibold'>T</span>ree<span className='font-semibold'>A</span>nd<span className='font-semibold'>H</span>uman<span className='font-semibold'>K</span>not
              </h1>
              <h1 class="heading text-[1.38rem] font-bold mt-2 mx-auto  text-amber-600">Re-Inventing Sustainability </h1> 
              {/* text-emerald-600 */}
              <h1 className='text-xl mx-1 font-normal mt-2 text-emerald-800'>
                “छायामन्यस्य कुर्वन्ति तिष्ठन्ति स्वयमातपे । फलान्यपि परार्थाय वृक्षाः सत्पुषा ईव ॥"
              </h1>
              
            </div>

            <div className='flex justify-center'>
              <div class=" mt-28 bg-transparent  rounded-3xl lg:max-w-sm ">
                          <Link  
                              to='/preregistration'
                              class="flex flex-col lg:flex-row"
                          >
                              
                              <button id="modal-switch" 
                                // onClick={modalSwitchClick} 
                                type="button" class="h-12 px-4 w-full opacity-200 font-bold md:text-lg text-gray-50 dark:text-gray-50 hover:shadow hover:text-gray-100 transition-colors duration-200 transform bg-lime-500 rounded-3xl hover:bg-sky-600  hover:border-gray-500 uppercase">
                                  Join the movement
                              </button>
                          </Link>
              </div>
            </div>
            
            <div class="relative mt-20 md:mt-44 md:flex md:justify-center">
              <div class="md:absolute md:bottom-0 mx-auto">
                <div>

                  <div class="flex flex-wrap justify-center gap-4">
                    
                    <a href='https://www.facebook.com/TreeAndHumanKnot' target='_blank' className='hover:text-white'>
                    <button id="facebook" class=" duration-500 w-10 md:w-12 h-10 md:h-12 transform hover:-translate-y-1 hover:shadow-md text-xl md:text-2xl rounded-full hover:text-gray-900 text-gray-600 ">
                          <i class="fab fa-facebook-f"></i>
                    </button></a>

                    <a href='https://instagram.com/TreeAndHumanKnot' target='_blank' className='hover:text-white'>
                    <button id="instagram" class=" duration-500 w-10 md:w-12 h-10 md:h-12 transform hover:-translate-y-1 hover:shadow-md text-xl md:text-2xl rounded-full hover:text-gray-900 text-gray-600 ">
                        <i class="fab fa-instagram"></i>
                    </button></a>

                    <a href='https://twitter.com/TAHK2030' target='_blank' className='hover:text-white'>
                    <button id="twitter" class=" duration-500 w-10 md:w-12 h-10 md:h-12 transform hover:-translate-y-1 hover:shadow-md text-xl md:text-2xl rounded-full hover:text-gray-900 text-gray-600 ">
                        <i class="fab fa-twitter"></i>                      
                    </button></a>

                    <a href='https://www.linkedin.com/company/treeandhumanknot' target='_blank' className='hover:text-white'>
                    <button id="linkedin" class=" duration-500 w-10 md:w-12 h-10 md:h-12 transform hover:-translate-y-1 hover:shadow-md text-xl md:text-2xl rounded-full hover:text-gray-900 text-gray-600 ">
                        <i class="fab fa-linkedin-in"></i>
                    </button></a>

                    <a href='https://www.youtube.com/channel/UCpjOZ_P3Jk6TYMQXnH7ByPg' target='_blank' className='hover:text-white'>
                    <button id="youtube" class=" duration-500 w-10 md:w-12 h-10 md:h-12 transform hover:-translate-y-1 hover:shadow-md text-xl md:text-2xl rounded-full hover:text-gray-900 text-gray-600 ">
                        <i class="fab fa-youtube"></i>
                    </button></a>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </div>
 
      <section id='map' className=' px-3  py-16 mt-4'>
                <div>
                    <h1 class="md:text-4xl text-3xl font-semibold text-cyan-600 mb-6 md:mb-10 text-center">Spreading Love for Nature Worldwide
                      <p className='text-base font-medium mt-2 text-gray-500'><i class="fas fa-map-marker-alt mr-2 text-lg text-blueGray-400"/>Click on the country to access their pages</p>
                    </h1>
                    
                    <WorldMap/>
                </div>
      </section>

      

     <div id='footer' className='mt-10'>
      <Footer/>
    </div>

    </div>
  )
}

export default Home