const countries = {
    "Peru": "Peru 🇵🇪",
    "United Kingdom": "UK 🇬🇧",
    "United States": "USA 🇺🇸",
    "Italy": "Italy 🇮🇹",
    "Turkey": "Turkey 🇹🇷",
    "India": "India 🇮🇳",
    "Pakistan": "Pakistan 🇵🇰",   
    "Australia": "Australia 🇦🇺"
}

export default countries;