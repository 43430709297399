import React from 'react';import $ from 'jquery';

import Footer from '../../Footer/Footer';

import Navbar from '../../Navbar/Navbar';

import img1 from '../../../assets/iprojects/O2.jpg'
import {Helmet} from "react-helmet";
import logo from '../../../assets/logo.png';

function TAHKO2Park() {

    $(document).ready(() => {
        document.body.scrollTop = 0; 
        document.documentElement.scrollTop = 0;

    })

  return (
    <div className='noselect'>
        <Helmet>
            <title>TAHK - TAHK O2 Park</title>
            <meta
                name="description"
                content="Creation of fruit orchards ot oxygen parks a dense mini forest of fruits, herbs and lowers trees based on O2 requirement of the region "
            />
            <meta property="og:url" content={document.location.href} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content="TAHK - TAHK O2 Park" />
            <meta property="og:description" content="Creation of fruit orchards ot oxygen parks a dense mini forest of fruits, herbs and lowers trees based on O2 requirement of the region " />
            <meta property="og:image" content={logo}/>
            <link rel="image_src" href={logo} />
        </Helmet>

        <Navbar/>

       
        <div class="py-28 bg-white">  
        <div class="container m-auto px-6 text-gray-600 md:px-12 xl:px-6">
            <div class="space-y-6 md:space-y-0 md:flex md:gap-6 lg:items-center lg:gap-12">
                <div class="md:w-5/12 lg:w-3/12">
                <img src={img1} className='rounded-md shadow-lg' alt="image" loading="lazy" width="" height=""/>
                </div>
                <div class="md:w-7/12 lg:w-9/12">
                <h2 class="text-2xl text-sky-800 font-bold md:text-4xl">TAHK <p className='text-sky-400 inline'>O2</p> Park</h2>
                <p class="mt-6 text-gray-600"><b>Fruit Orchard/TAHK Cluster (TAHK O2 Park)</b> a dense mini forest of fruit, herb and flower trees. We aim to establish fruit orchards every 5km across the country 
                    Fresh fruits to eat,
                    Cleaner air to breathe,
                    Reducing soil erosion,
                    Lesser number of deaths due to hunger,
                    Saving soil and so many other gains, all altogether 
                    Imagine that beautiful scenic view, 
                    and Join Now to make it possible.
                </p>
                {/* <p class="mt-4 text-gray-600"> Nobis minus voluptatibus pariatur dignissimos libero quaerat iure expedita at? Asperiores nemo possimus nesciunt dicta veniam aspernatur quam mollitia.</p> */}
                </div>
            </div>
        </div>
        </div>

        <div id='footer' className='mt-12'>
        <Footer/>
        </div>
    </div>
  )
}

export default TAHKO2Park