import './App.css';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Home from './components/Home/Home';
import SDG from './components/SDG/SDG';
import Briefs from './components/Briefs/Briefs';
import Intrapreneurial from './components/Intrapreneurial/Intrapreneurial';
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy';

// Country pages imports
import Italy from './components/Countries/Italy/Italy';
import India from './components/Countries/India/India';
import US from './components/Countries/US/US';
import Pakistan from './components/Countries/Pakistan/Pakistan';
import Turkey from './components/Countries/Turkey/Turkey';
import Peru from './components/Countries/Peru/Peru';
import UK from './components/Countries/UK/UK';

// Intrapreneurial Projects imports
import GreenBirthday from './components/IntrapreneurialProjects/GreenBirthday/GreenBirthday';
import TAHKO2Park from './components/IntrapreneurialProjects/TAHKO2Park/TAHKO2Park';
import WasteManagement from './components/IntrapreneurialProjects/WasteManagement/WasteManagement';
import SIPJunior from './components/IntrapreneurialProjects/SIPJunior/SIPJunior';

// SDG imports
import NoPoverty from './components/SDGs/NoPoverty/NoPoverty';
import ZeroHunger from './components/SDGs/ZeroHunger/ZeroHunger';
import GoodHealth from './components/SDGs/GoodHealth/GoodHealth';
import QualityEducation from './components/SDGs/QualityEducation/QualityEducation';
import GenderEquality from './components/SDGs/GenderEquality/GenderEquality';
import CleanWater from './components/SDGs/CleanWater/CleanWater';

import Institutions from './components/Institutions/Institutions';
import FAQs from './components/FAQs/FAQs';
import SIP from './components/SIP/SIP';
import TIP from './components/TIP/TIP';
import Certification from './components/Certification/Certification';
import PreRegistration from './components/PreRegistration/PreRegistration';
import Corporates from './components/Corporates/Corporates';
import RegisteredUsers from './components/Organization/RegisteredUsers/RegisteredUsers';
import OrganizationLogin from './components/Organization/OrganizationLogin/OrganizationLogin';
import Viewers from './components/Organization/Viewers/Viewers';
import AdminDashboard from './components/Organization/AdminDashboard/AdminDashboard';
// import AddPost from './components/Test/AddPost/AddPost';
import AddPost from './components/Organization/AddPost/AddPost';
import AddFile from './components/Test/AddFile/AddFile';
import Posts from './components/Posts/Posts';
import Slider from './components/Test/Slider/Slider';
import Post from './components/Post/Post';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path='/' element={<Home/>} />
          <Route path='/diary' element={<Posts/>} />
          {/* <Route path='/diary/:id' element={(props) => (<Post id={props.match.params.id}/>)} /> */}
          <Route path='/diary/:postId' element={<Post/>} />
          <Route path='/sdg' element={<SDG/>} />
          <Route path='/briefs' element={<Briefs/>} />
          <Route path='/projects' element={<Intrapreneurial/>} />
          <Route path='/privacypolicies' element={<PrivacyPolicy/>} />
          <Route path='/institutions' element={<Institutions/>} />
          <Route path='/corporates' element={<Corporates/>} />
          <Route path='/faqs' element={<FAQs/>} />
          <Route path='/sip' element={<SIP/>} />
          <Route path='/tip' element={<TIP/>} />
          <Route path='/certification' element={<Certification/>} />
          <Route path='/preregistration' element={<PreRegistration/>} />

          <Route path='/tahkfamily/allusers' element={<RegisteredUsers/>} />
          <Route path='/tahkfamily/viewers' element={<Viewers/>} />
          <Route path='/admin/dashboard' element={<AdminDashboard/>} />
          <Route path='/tahklogin' element={<OrganizationLogin/>} />
          <Route path='/tahkfamily/addpost' element={<AddPost/>} />

          {/* Testing */}
          {/* <Route path='/addpost' element={<AddPost/>} /> */}
          {/* <Route path='/addfile' element={<AddFile/>} /> */}
          {/* <Route path='/slider' element={<Slider/>} />           */}
          {/* <Route path='/razorpay' element={<Razorpay1/>} /> */}

          {/* Country Pages */}
          <Route path='/italy' element={<Italy/>} />
          <Route path='/india' element={<India/>} />
          <Route path='/usa' element={<US/>} />
          <Route path='/uk' element={<UK/>} />
          <Route path='/pakistan' element={<Pakistan/>} />
          <Route path='/turkey' element={<Turkey/>} />
          <Route path='/peru' element={<Peru/>} />

          {/* Intrapreneurial Projects */}
          <Route path='/greenbirthday' element={<GreenBirthday/>} />
          <Route path='/o2' element={<TAHKO2Park/>} />
          <Route path='/investthewaste' element={<WasteManagement/>} />
          <Route path='/sipjr' element={<SIPJunior/>} />

          {/* SDGs */}
          <Route path='/sdg/1' element={<NoPoverty/>} />
          <Route path='/sdg/2' element={<ZeroHunger/>} />
          <Route path='/sdg/3' element={<GoodHealth/>} />
          <Route path='/sdg/4' element={<QualityEducation/>} />
          <Route path='/sdg/5' element={<GenderEquality/>} />
          <Route path='/sdg/6' element={<CleanWater/>} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
