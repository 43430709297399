import React from 'react';
import Footer from '../Footer/Footer';
import Navbar from '../Navbar/Navbar';
import $ from 'jquery';

import {InlineWidget, PopupModal, PopupButton, PopupWidget} from 'react-calendly'
import logo from '../../assets/logo.png'

import {Helmet} from "react-helmet";

function TIP() {

  $(document).ready(() => {
    document.body.scrollTop = 0; 
    document.documentElement.scrollTop = 0;

  })

  return (
    <div className='noselect bg-sky-50'>

      <Helmet>
            <title>TAHK - TIP</title>
            <meta
                name="description"
                content="Technology Internship Program for techies enthusiastic to develop world's first Sustainability Network and learn practical implication of their development skills"
            />
            <meta property="og:url" content={document.location.href} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content="TAHK - Technology Internship Program" />
            <meta property="og:description" content="Technology Internship Program for techies enthusiastic to develop world's first Sustainability Network and learn practical implication of their development skills" />
            <meta property="og:image" content={logo}/>
            <link rel="image_src" href={logo} />
        </Helmet>

        <div>
            <Navbar/>
        </div>

        
        <article class="py-12 px-4 ">
        <h1 class="text-4xl text-center mb-4 font-semibold font-heading capitalize text-sky-800">Technology Internship Programme (TIP)</h1>
        {/* <p class="text-center">
            <span>October 22, by</span>
            <a class="ml-1 text-indigo-600 hover:underline" >Michael Scott</a>
        </p> */}
        
        <div class="max-w-3xl mx-auto">
            <p class="mb-1">
              This platform visioned to become a Sustainability Network and needs to be developed accordingly<br/>
              If you have a mindset and passion to code, fill the form to make the technology programme an exciting learning journey
            </p>
            <ul class="mb-4 list-inside list-disc">            
              <li>Web/Application development</li>
              <li>Artificial Intelligence Machine learning</li>
              <li>Programming (Passionate Programmer)</li>
            </ul>

            <p class="mb-1">
              <span className='text-lg font-medium text-yellow-700'>Ideal Candidate:</span><br/>
            </p>
            <p class="mb-1">
              Post Graduate /Doctorate /Master aspirant or experienced professional who thrives for technology, have passion to code and work in dynamic environment with keen interest in one or more:
            </p>
            <ul className='mb-4 list-inside list-disc'>
              <li>PHP, SQL, C# HTML, CSS, NodeJS, React and Angular</li>
              <li>UG with Strong Willingness to learn sincerely may be considered</li>
              <li>Front-end web development</li>
              <li>Back-end web development</li>
              <li>Full-stack web development</li>
              <li>Python, JavaScript, C, C++</li>
              <li>UX/UI Design, App Development, AI/ML/DL</li>
            </ul>

            <p class="mb-1">
              <span className='text-lg font-medium text-yellow-700'>Duration:</span><br/>
            </p>
            <p class="mb-4">
              Strategically bifurcated into 3 phases to be exposed into various verticals starting with basic training and orientation program which will be mentored by founding team to assess and assign suitable profile (flexible tenure of 3 months to 12 months)
            </p>

            <p class="mb-4">
              {/* <span className='text-base font-semibold text-gray-800'>Duration: </span>Level 1 (240 Hours) to Sustainability Warrior (6 months) and leadership<br/> */}
              <span className='text-lg font-medium text-yellow-700'>Work Location: </span>Your laptop/machine (this is a virtual internship and would be location immune)<br/>
              <span className='text-lg font-medium text-yellow-700'>Work Timing: </span>Flexible (We fix timelines you decide timings)<br/>
              <span className='text-lg font-medium text-yellow-700'>Work Days: </span>Flexible (what you deliver is important)<br/>
            </p>

            <p class="mb-1">
              <span className='text-lg font-medium text-yellow-700'>Rewards Details:</span><br/>
            </p>
            <ul class="mb-4 list-inside list-disc"> 
              Serving Mother Earth as stipend not applicable but you will receive the following           
              <li>Letter and Certificate of Internship. Candidate may also be eligible to earn based on performance evaluation (at the discretion of Management)</li>
              <li>Letter of recommendation as per the execution for your internship</li>
              <li>Opportunity to join Next startups at RisingIndia ThinkTank based on evaluation process</li>
            </ul>

            <h1 className='lg:mt-10 mt-8 text-xl font-semibold mb-3 text-yellow-700'>
              Process Details (Rounds/Levels of selection process):
            </h1>
            <ol class="mb-4 list-inside list-decimal">            
              <li>Candidate need to fill up the application form carefully and next steps will be available through an automated email from tahk.tech@gmail.com</li>
              <li>(WHY) Describe reason for selecting a role</li>
              <li>(HOW) Mention competencies establishing suitability for the intended role/function</li>          
            </ol>

            <p class="mb-4 font-serif">
              In case you do not get a response after sending your white paper within 24 hours. You may please&nbsp;
              <PopupButton
                url="https://calendly.com/risingindia/"
                rootElement={document.getElementById("root")}
                text="schedule an interview"
                className='text-sky-600 hover:text-sky-700 hover:underline'
                />
                &nbsp;to connect with founding team.
            </p>

            <p class="mb-4 mt-12 font-serif text-sky-600 text-lg text-center ">
            {/* <PopupButton
              url="https://calendly.com/risingindia/"
              rootElement={document.getElementById("root")}
              text="Schedule time with us"
              className='mt-4 h-10 px-4 opacity-200 font-semibold text-base text-gray-50 dark:text-gray-50 shadow-sm hover:shadow hover:text-gray-100 transition-colors duration-200 transform bg-sky-500 rounded-3xl hover:bg-sky-600  hover:border-gray-500 uppercase'
              /> */}
              <a 
                href='https://docs.google.com/forms/d/e/1FAIpQLSf3Y4sdXzdTQcz_EUuY8DiRlsfzokepthfsX8YDB86uXeANAg/viewform'
                target='_blank'
                style={{paddingTop: '0.7rem', paddingBottom: '0.7rem'}}
                className='mt-8 h-10 px-8 opacity-200 font-semibold text-base text-gray-50 dark:text-gray-50 shadow-sm hover:shadow hover:text-gray-100 transition-colors duration-200 transform bg-sky-500 rounded-3xl hover:bg-sky-600  hover:border-gray-500 uppercase'
              >
                Apply For TIP
              </a>
            </p>

        </div>
        </article>

        <Footer/>

    </div>
  )
}

export default TIP
