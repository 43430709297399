import React from 'react';
import Navbar from '../../Navbar/Navbar';
import $ from 'jquery';

// images
import sdg4 from '../assets/sdg4.jpg';
import logo from '../../../assets/logo.png';
import {Helmet} from "react-helmet";

import Footer from '../../Footer/Footer';

function QualityEducation() {

    $(document).ready(() => {
        document.body.scrollTop = 0; 
        document.documentElement.scrollTop = 0;
    
      })

  return (
    <div className='bg-sky-50 noselect'>

        <Helmet>
            <title>TAHK - Quality Education</title>
            <meta
                name="description"
                content="Education in every country's region should be given at a international level of knowledge"
            />
            <meta property="og:url" content={document.location.href} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content="TAHK - Quality Education" />
            <meta property="og:description" content="Education in every country's region should be given at a international level of knowledge" />
            <meta property="og:image" content={logo}/>
            <link rel="image_src" href={logo} />
        </Helmet>

        <Navbar/>

        <section >
            <div class="max-w-7xl px-6 py-16 mx-0">
                <div class="items-center md:flex md:space-x-6">
                    <div class="md:w-1/2">
                        <div class="flex items-center justify-center">
                            <div class="max-w-md">
                                <img class="object-cover object-center w-full rounded-md shadow" style={{height: 500}}
                                    src={sdg4}/>
                            </div>
                        </div>
                    </div>
        
                    <div class="mt-8 md:mt-0 md:w-1/2">
                        <h2 class="max-w-xl text-2xl text-sky-800 font-bold md:text-4xl md:pl-4 uppercase">Quality Education</h2>
                        <p class="max-w-xl mt-4 text-lime-800 text-lg md:pl-4">SDG-4 aims to provide equitable quality education for all and promote lifelong learning opportunities for all. Trees play an important role in child development. Trees promote health and social well-being by reducing stress which in turn helps in development and learning.
                            TAHK's SIP aims to provide learning opportunities for students assisting in their development.
                        </p>
                        
                        
                    </div>
                </div>
            </div>
        </section>

        <div id='footer' className='mt-12'>
        <Footer/>
        </div>
    </div>
  )
}

export default QualityEducation