import React from 'react';
import Navbar from '../../Navbar/Navbar';
import $ from 'jquery';

// images
import sdg6 from '../assets/sdg6.jpg';
import logo from '../../../assets/logo.png';
import {Helmet} from "react-helmet";

import Footer from '../../Footer/Footer';

function CleanWater() {

    $(document).ready(() => {
        document.body.scrollTop = 0; 
        document.documentElement.scrollTop = 0;
    
      })

  return (
    <div className='bg-sky-50 noselect'>
        <Helmet>
            <title>TAHK - Clean Water</title>
            <meta
                name="description"
                content="Ensure access to water and sanitation for all"
            />
            <meta property="og:url" content={document.location.href} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content="TAHK - Clean Water" />
            <meta property="og:description" content="Ensure access to water and sanitation for all" />
            <meta property="og:image" content={logo}/>
            <link rel="image_src" href={logo} />
        </Helmet>

        <Navbar/>

        <section >
            <div class="max-w-7xl px-6 py-16 mx-0">
                <div class="items-center md:flex md:space-x-6">
                    <div class="md:w-1/2">
                        <div class="flex items-center justify-center">
                            <div class="max-w-md">
                                <img class="object-cover object-center w-full rounded-md shadow" style={{height: 500}}
                                    src={sdg6}/>
                            </div>
                        </div>
                    </div>
        
                    <div class="mt-8 md:mt-0 md:w-1/2">
                        <h2 class="max-w-xl text-2xl text-sky-800 font-bold md:text-4xl md:pl-4 uppercase">Clean water and sanitation</h2>
                        <p class="max-w-xl mt-4 text-lime-800 text-lg md:pl-4">
                            Clean water and sanitation goal 6 target is to “Ensure availability and sustainable management of water and sanitation for all”.Water scarcity affects more than 40% of the world’s population. The crisis is getting worse by the year and the expected demand for water will be 40% higher than the supply in 2030.SDG 6 demonstrates those strong links between people the economy and nature which is ultimately what sustainable development is all about.TAHK is inspiring people to reach this goal by providing a path towards it. Simple solutions could save lives and help give everyone access to clean water and sanitation by the year 2030.
                        </p>
                        
                        
                    </div>
                </div>
            </div>
        </section>

        <div id='footer' className='mt-12'>
        <Footer/>
        </div>
    </div>
  )
}

export default CleanWater