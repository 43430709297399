import React, {useState} from 'react'
import './Slider.css'
import BtnSlider from './BtnSlider'

export default function Slider({images, urls}) {

    const urlLength = urls ? urls.length : 0;
    // console.log(urls);
    // console.log(images);
    // console.log(Array.isArray(images));

    const [slideIndex, setSlideIndex] = useState(1)

    const nextSlide = () => {
        if(slideIndex !== images.length + urlLength){
            setSlideIndex(slideIndex + 1)
        } 
        else if (slideIndex === images.length + urlLength){
            setSlideIndex(1)
        }
    }

    const prevSlide = () => {
        if(slideIndex !== 1){
            setSlideIndex(slideIndex - 1)
        }
        else if (slideIndex === 1){
            setSlideIndex(images.length + urlLength)
        }
    }

    const moveDot = index => {
        setSlideIndex(index)
    }

    return (
        <div className="container-slider rounded-xl">
            {images.map((obj, index) => {
                return (
                    <div
                    key={obj.name}
                    className={slideIndex === index + 1 ? "slide active-anim" : "slide"}
                    >
                        <img 
                        src={obj.data} 
                        />
                    </div>
                )
            })}

            {
                urls &&
                urls.map((url, index) => {
                    return(
                        <div
                        key={url.key}
                        className={slideIndex === (images.length + index + 1) ? "slide active-anim" : "slide hidden"}
                        >
                            <div class="video-wrapper box w-full h-full bg-gray-200 rounded-2xl sahdow-lg p-12 flex flex-col justify-center items-center transform transition duration-500"  >
                                <iframe src={url.url} className='rounded-md' title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen='true'></iframe>
                                {/* <div class="d carousel-caption d-none d-md-block text-center opacity-0">
                                        <p class="text-xl text-white font-bold mb-2">{value.month} BRIEF</p>
                                        <p class="text-base text-white font-normal">{value.year}</p>
                                </div>                         */}
                            </div>
                        </div>
                    )
                })


            }

                            {/* <div class="slide video-wrapper box w-full h-80 bg-gray-200 rounded-2xl sahdow-lg p-12 flex flex-col justify-center items-center transform hover:scale-105 transition duration-500"  >
                                <iframe src='https://www.youtube.com/embed/5op3t4YpXf4' className='rounded-md' title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen='true'></iframe>
                                                      
                            </div> */}

            <BtnSlider moveSlide={nextSlide} direction={"next"} />
            <BtnSlider moveSlide={prevSlide} direction={"prev"}/>

            <div className="container-dots">
                {Array.from({length: images.length + urlLength}).map((item, index) => (
                    <div 
                    onClick={() => moveDot(index + 1)}
                    className={slideIndex === index + 1 ? "dot active hover:cursor-pointer" : "dot hover:cursor-pointer"}
                    ></div>
                ))}
            </div>
        </div>
    )
}
