import axios from "axios";


// const apiRazorpayUrl = "http://localhost:8080/razorpay";

const apiRazorpayUrl = "https://server.tahk.in/razorpay";

// const gcloudApiUserUrl = "https://tahk-server-dot-acoustic-spot-347802.el.r.appspot.com/admin";

const RazorpayService = {
    
    razorpayParams: () => {
        return axios.get(`${apiRazorpayUrl}/parameters`);
    },

    createOrder: () => {
        return axios.post(`${apiRazorpayUrl}/create-order`);
    }
    
}


export default RazorpayService;