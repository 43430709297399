import axios from "axios";


// const apiUserUrl = "http://localhost:8080/user";

const apiUserUrl = "https://server.tahk.in/user";

// const gcloudApiUserUrl = "https://tahk-server-dot-acoustic-spot-347802.el.r.appspot.com/user";

const UserService = {
    
    registerUser: (userData) => {
        return axios.post(`${apiUserUrl}/register`, userData);
    }
}


export default UserService;