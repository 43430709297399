import axios from "axios";


// const apiOrganizationUrl = "http://localhost:8080/organization";

const apiOrganizationUrl = "https://server.tahk.in/organization";

// const gcloudApiUserUrl = "https://tahk-server-dot-acoustic-spot-347802.el.r.appspot.com/admin";

const OrganizationService = {
    
    login: (userData) => {
        return axios.post(`${apiOrganizationUrl}/login`, userData);
    },

    allUsers: () => {
        return axios.get(`${apiOrganizationUrl}/allusers`, {
            headers: {
                accessToken: sessionStorage.getItem('accessToken')
            }
        });
    },

    validateToken: () => {
        return axios.get(`${apiOrganizationUrl}/validatetoken`, {
            headers: {
                accessToken: sessionStorage.getItem('accessToken')
            }
        })
    },

    logout: () => {
        sessionStorage.removeItem('accessToken');
    }

    
}


export default OrganizationService;