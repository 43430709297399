import React from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';

import education from '../../../assets/coverImgs/education.png';
import img1 from '../../../assets/countryImgs/UK/img1.png';
import ukMap from '../../../assets/countryImgs/UK/ukMap.png';
import logo from '../../../assets/logo.png';

import {Helmet} from "react-helmet";

import './UK.css';
import Footer from '../../Footer/Footer';
import Navbar from '../../Navbar/Navbar';

function UK() {

    $(document).ready(() => {
        document.body.scrollTop = 0; 
        document.documentElement.scrollTop = 0;

    })

  return (
    <div className='bg-sky-50 noselect'>

        <Helmet>
            <title>TAHK - UK</title>
            <meta
                name="description"
                content="Together towards re-inventing sustainability"
            />
            <meta property="og:url" content={document.location.href} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content="TAHK - UK" />
            <meta property="og:description" content="Together towards re-inventing sustainability" />
            <meta property="og:image" content={logo}/>
            <link rel="image_src" href={logo} />
        </Helmet>
        
        <Navbar/>
        <section id='header' class="bg-cover bg-no-repeat bg-center bg-blue-100 dark:bg-blue-100" >
            
            <div id='content' class="container flex flex-col px-6 py-10 mx-auto space-y-6 lg:h-[28rem] lg:py-16 lg:flex-row lg:items-center">
                <div class="w-full lg:w-1/2">
                    <div class="lg:max-w-3xl">
                        <h1 class="text-3xl font-bold tracking-wide text-zinc-800 lg:text-gray-700 lg:dark:text-gray-700 lg:text-5xl">
                            TAHK UK
                        </h1>
                        
                        <div class="mt-8 space-y-5">
                            <p class="flex items-center -mx-2 text-black lg:text-gray-700 lg:dark:text-gray-700">
                                <span class="text-xl mx-2">Together to re-invent sustainability</span>
                            </p>

                        </div>
                    </div>

                    
                </div>
        
                <div class="flex items-center justify-center w-full h-full lg:w-1/2">
                    <img class="w-auto h-full mx-auto rounded-md lg:max-w-2xl" src={ukMap} alt="glasses photo"/>
                </div>
            </div>
        </section>

        <section >
        <div class="max-w-7xl px-6 py-16 mx-auto">
            <div class="items-center md:flex md:space-x-6">
                <div class="md:w-1/2 pl-1">
                    {/* <h3 class="text-5xl font-bold text-gray-800">Di</h3> */}
                    <p class="max-w-xl mt-4 text-gray-600 text-lg">TreeAndHumanKnot is cultivated from the eternal bond between nature and humanity. We provide pragmatic solutions for sustainability while enjoying love, joy, and compassion through strategic tree planting. This is in order to turn people, communities, and companies into agents of change.
                    </p>
                    <p class="max-w-xl mt-4 text-gray-800 text-lg">Contact us at: <p className='inline hover:underline text-blue-600'><a href='mailto:uk@tahk.in'>uk@tahk.in</a></p></p>
                    <div class="md:flex md:justify-center">
                            <div class="mt-10 mx-auto">
                                <div>

                                <div class="flex flex-wrap justify-center gap-4">
                                    
                                    {/* <a href='https://www.facebook.com/TreeAndHumanKnot' target='_blank' className='hover:text-white'>
                                    <button id="facebook" class=" duration-500 w-10 md:w-12 h-10 md:h-12 transform hover:-translate-y-1 hover:shadow-md text-xl md:text-2xl rounded-full hover:text-gray-900 text-gray-600 ">
                                        <i class="fab fa-facebook-f"></i>
                                    </button></a> */}

                                    {/* <a href='https://instagram.com/TreeAndHumanKnot' target='_blank' className='hover:text-white'>
                                    <button id="instagram" class=" duration-500 w-10 md:w-12 h-10 md:h-12 transform hover:-translate-y-1 hover:shadow-md text-xl md:text-2xl rounded-full hover:text-gray-900 text-gray-600 ">
                                        <i class="fab fa-instagram"></i>
                                    </button></a> */}

                                    {/* <a href='https://twitter.com/TAHK2030' target='_blank' className='hover:text-white'>
                                    <button id="twitter" class=" duration-500 w-10 md:w-12 h-10 md:h-12 transform hover:-translate-y-1 hover:shadow-md text-xl md:text-2xl rounded-full hover:text-gray-900 text-gray-600 ">
                                        <i class="fab fa-twitter"></i>                      
                                    </button></a> */}

                                    {/* <a href='https://www.linkedin.com/company/treeandhumanknot' target='_blank' className='hover:text-white'>
                                    <button id="linkedin" class=" duration-500 w-10 md:w-12 h-10 md:h-12 transform hover:-translate-y-1 hover:shadow-md text-xl md:text-2xl rounded-full hover:text-gray-900 text-gray-600 ">
                                        <i class="fab fa-linkedin-in"></i>
                                    </button></a> */}


                                    {/* <a href='https://www.youtube.com/channel/UCpjOZ_P3Jk6TYMQXnH7ByPg' target='_blank' className='hover:text-white'>
                                    <button id="youtube" class=" duration-500 w-10 md:w-12 h-10 md:h-12 transform hover:-translate-y-1 hover:shadow-md text-xl md:text-2xl rounded-full hover:text-gray-900 text-gray-600 ">
                                        <i class="fab fa-youtube"></i>
                                    </button></a> */}

                                    <a href='mailto:uk@tahk.in' target='_blank' className='hover:text-white'>
                                    <button id="email" class=" duration-500 w-10 md:w-12 h-10 md:h-12 transform hover:-translate-y-1 hover:shadow-md text-xl md:text-2xl rounded-full hover:text-gray-900 text-gray-600 ">
                                        <i class="fas fa-envelope"></i>
                                    </button></a>

                                </div>
                                </div>
                            </div>
                            </div>
                </div>
    
                <div class="mt-8 md:mt-0 md:w-1/2">
                    <div class="flex items-center justify-center">
                        <div class="max-w-md">
                            <img class="object-cover object-center w-full rounded-md shadow mb-4" style={{height: "300px"}}
                                src={img1}/>
                            <span className='text-center mt-2 text-cyan-700'>This summer the movement will be ready to honor the world's top talents in United Kingdom</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </section>
    
        {/* <section >
            <div class="max-w-7xl px-6 py-16 mx-0">
                <div class="items-center md:flex md:space-x-6">
                    <div class="md:w-1/2">
                        <div class="flex items-center justify-center">
                            <div class="max-w-md">
                                <img class="object-cover object-center w-full rounded-md shadow" style={{height: 500}}
                                    src={education}/>
                            </div>
                        </div>
                    </div>
        
                    <div class="mt-8 md:mt-0 md:w-1/2">
                        <p class="max-w-xl mt-4 text-gray-600 text-lg md:pl-4">L'idea è stata concepita nel 2019, e ci siamo resi conto che lavorando insieme potremmo superare le nostre sfide in modo molto più efficace con l'ideologia del dare per primo, che ha portato alla nascita di TreeAndHumanKnot con soluzioni pragmatiche per la sostenibilità. 
                            L'amore, il piacere e la compassione attraverso la piantagione strategica di alberi ci aiutano a rendere persone, comunità e aziende agenti di cambiamento, che è possibile con soluzioni semplici. Questo è in linea con gli Obiettivi di Sviluppo Sostenibile (SDGs) fissati dalle Nazioni Unite.
                        </p>
                        <div class="md:flex md:justify-center">
                            <div class="mt-10 mx-auto">
                                <div>

                                <div class="flex flex-wrap justify-center gap-4">
                                    
                                    <a href='https://www.facebook.com/TreeAndHumanKnot' target='_blank' className='hover:text-white'>
                                    <button id="facebook" class=" duration-500 w-10 md:w-12 h-10 md:h-12 transform hover:-translate-y-1 hover:shadow-md text-xl md:text-2xl rounded-full hover:text-gray-900 text-gray-600 ">
                                        <i class="fab fa-facebook-f"></i>
                                    </button></a>

                                    <a href='https://instagram.com/TreeAndHumanKnot' target='_blank' className='hover:text-white'>
                                    <button id="instagram" class=" duration-500 w-10 md:w-12 h-10 md:h-12 transform hover:-translate-y-1 hover:shadow-md text-xl md:text-2xl rounded-full hover:text-gray-900 text-gray-600 ">
                                        <i class="fab fa-instagram"></i>
                                    </button></a>

                                    <a href='https://twitter.com/TAHK2030' target='_blank' className='hover:text-white'>
                                    <button id="twitter" class=" duration-500 w-10 md:w-12 h-10 md:h-12 transform hover:-translate-y-1 hover:shadow-md text-xl md:text-2xl rounded-full hover:text-gray-900 text-gray-600 ">
                                        <i class="fab fa-twitter"></i>                      
                                    </button></a>

                                    <a href='https://www.linkedin.com/company/treeandhumanknot' target='_blank' className='hover:text-white'>
                                    <button id="linkedin" class=" duration-500 w-10 md:w-12 h-10 md:h-12 transform hover:-translate-y-1 hover:shadow-md text-xl md:text-2xl rounded-full hover:text-gray-900 text-gray-600 ">
                                        <i class="fab fa-linkedin-in"></i>
                                    </button></a>


                                    <a href='https://www.youtube.com/channel/UCpjOZ_P3Jk6TYMQXnH7ByPg' target='_blank' className='hover:text-white'>
                                    <button id="youtube" class=" duration-500 w-10 md:w-12 h-10 md:h-12 transform hover:-translate-y-1 hover:shadow-md text-xl md:text-2xl rounded-full hover:text-gray-900 text-gray-600 ">
                                        <i class="fab fa-youtube"></i>
                                    </button></a>

                                    <a href='https://www.youtube.com/channel/UCpjOZ_P3Jk6TYMQXnH7ByPg' target='_blank' className='hover:text-white'>
                                    <button id="youtube" class=" duration-500 w-10 md:w-12 h-10 md:h-12 transform hover:-translate-y-1 hover:shadow-md text-xl md:text-2xl rounded-full hover:text-gray-900 text-gray-600 ">
                                        <i class="fas fa-envelope"></i>
                                    </button></a>

                                </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </section> */}

        <hr class="w-2/5 my-14 border-4 rounded-md sm:mx-0 mx-auto" />

            <Footer/>
    </div>
  )
}

export default UK