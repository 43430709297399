import React from 'react'
import Footer from '../Footer/Footer'
import Navbar from '../Navbar/Navbar';
import { Formik, Form, Field, ErrorMessage, FormEvent } from "formik";
import $ from 'jquery';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import UserService from '../../services/UserService';
import countryList from '../../services/CountryList';

// image
import plantback1 from '../../assets/coverImgs/plantback1.jpg';
import plantback2 from '../../assets/coverImgs/plantback2.jpg';
import plantback3 from '../../assets/coverImgs/plantback3.jpg';
import logo from '../../assets/logo.png'

import {Helmet} from "react-helmet";

function PreRegistration() {

  $(document).ready(() => {
    document.body.scrollTop = 0; 
    document.documentElement.scrollTop = 0;
  })

  let navigate = useNavigate();

    const initialValues = {
      name: "",
      email: "",
      country: "",
      phone: "",
      pin: "",
      sos: ""
	  };
  
	  const onSubmit = (data) => {
      UserService.registerUser(data).then((response) => {
        if(response.data.emailError) {
          document.getElementById('emailError').innerHTML = response.data.emailError;
          document.getElementById('emailError').classList.remove('hidden');
        } else if(response.data.phoneError) {
          document.getElementById('phoneError').innerHTML = response.data.phoneError;
          document.getElementById('phoneError').classList.remove('hidden');
        } else {
          console.log(response.data);
          alert("You are registered successfully!");
          navigate('/');
        }
      })
	  }
  
	  const validationSchema = Yup.object().shape({  
   
      name: Yup.string(),
      email: Yup.string().email('Must be a valid email').max(255).required('Please enter an email'),
      country: Yup.string(),
      phone: Yup.string().matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        "Phone number is not valid"),
      pin: Yup.string().min(4, "PIN/ZIP must be atleast of 4 digit").max(6, "PIN/ZIP must be atmost of 6 digit").matches(/^\d+$/,"PIN/ZIP should contain digits only")
	  });

    const onChange = (event) => {
      if(event._reactName === 'onChange'){
        if(event.target.name === 'email'){
          if(!document.getElementById('emailError').classList.contains('hidden')) {
            document.getElementById('emailError').classList.add('hidden');
          }
        }
        if(event.target.name === 'phone'){
          if(!document.getElementById('phoneError').classList.contains('hidden')) {
            document.getElementById('phoneError').classList.add('hidden');
          }
        }
        // if(event.target.name === 'country'){
        //   console.log(event.target.value);
        //   if(event.target.value !== ""){
        //     document.getElementById('country').list='countries';
        //   }
        // }
      }
    }

  return (
    <div className='bg-gray-400 noselect'>

        <Helmet>
            <title>TAHK - PreRegistration</title>
            <meta
                name="description"
                content="Join the movement"
            />
            <meta property="og:url" content={document.location.href} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content="TAHK - PreRegistration" />
            <meta property="og:description" content="Join the movement" />
            <meta property="og:image" content={logo}/>
            <link rel="image_src" href={logo} />
        </Helmet>

        <Navbar/>

        {/* <!-- component --> */}
        <body class="bg-gray-400 bg-cover bg-no-repeat bg-center" style={{backgroundImage: `url(${plantback1})`}}>
            {/* <!-- Container --> */}
            <div class="container mx-auto">
              <div class="flex justify-center px-6 ">
                {/* <!-- Row --> */}
                <div class="w-full xl:w-3/4 lg:w-11/12 flex my-12">
                  {/* <!-- Col --> */}
                  <div
                    class="w-full h-auto bg-gray-400 hidden lg:block lg:w-5/12 bg-cover rounded-l-lg"
                    style={{backgroundImage: `url(${plantback3})`}}
                  ></div>
                  {/* <!-- Col --> */}
                  <div class="w-full lg:w-7/12 bg-white px-5 py-3 rounded-lg lg:rounded-l-none">
                    <h3 class="pt-4 text-2xl lg:text-3xl text-center font-serif lg:font-normal text-sky-700">Pre Registration</h3>
                    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
                    <Form onChange={onChange} class="md:px-8 px-1 pt-6 pb-8 mb-4 bg-white rounded">
                        <div class="mb-2">
                        <label class="block mb-2 text-sm font-bold text-pink-500" for="name">
                          Name
                        </label>
                        <Field
                          class="w-full px-3 py-2 mb-3 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                          id="name" name='name'
                          type="text"
                          placeholder="Name" 
                        />
                        <p class="text-xs italic text-red-500"><ErrorMessage name="name" component="span"/></p>
                      </div>
                      <div class="mb-2">
                        <label class="block mb-2 text-sm font-bold text-pink-500" for="email">
                          Email<span className='text-red-600'> *</span>
                        </label>
                        <Field
                          class="w-full px-3 py-2 mb-3 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                          id="email" name='email'
                          type="email"
                          placeholder="Email" 
                        />
                        <p class="text-xs italic text-red-500"><ErrorMessage name="email" component="span"/></p>
                        <p id='emailError' class="text-xs italic text-red-500 hidden"></p>
                      </div>
                      <div class="mb-2">
                        <label class="block mb-2 text-sm font-bold text-pink-500" for="dob">
                          Green Birthday (D.O.B.)
                        </label>
                        <Field
                          class="w-full px-3 py-2 mb-3 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                          id="dob" name='dob'
                          type="date"
                          placeholder="GreenBirthday" 
                        />
                        <p class="text-xs italic text-red-500"><ErrorMessage name="dob" component="span"/></p>
                      </div>
                      <div class="mb-2">
                        <label class="block mb-2 text-sm font-bold text-pink-500" for="country">
                          Country
                        </label>
                        <Field
                          class="w-full px-3 py-2 mb-3 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                          id="country" name='country'
                          type="country"
                          placeholder="Country" list='countries'
                        />
                        <datalist id='countries'>
                          {countryList.map((value, key) => {
                              return (
                                <option value={value}></option>
                              );
                          })}                          
                        </datalist>
                        <p class="text-xs italic text-red-500"><ErrorMessage name="country" component="span"/></p>
                      </div>
                      
                      <div class=" md:flex md:justify-between">
                        <div class="mb-4 md:mr-2 md:mb-0">
                          <label class="block mb-2 text-sm font-bold text-pink-500" for="phone">
                            Phone
                          </label>
                          <Field
                            class="w-full px-3 py-2 mb-3 text-sm leading-tight text-gray-700 border border-red-500 rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                            id="phone" name='phone'
                            type="text"
                            placeholder="Phone"
                          />
                          <p class="text-xs italic text-red-500"><ErrorMessage name="phone" component="span"/></p>
                          <p id='phoneError' class="text-xs italic text-red-500 hidden"></p>
                        </div>
                        <div class="md:ml-2">
                          <label class="block mb-2 text-sm font-bold text-pink-500" for="pin">
                            PIN/ZIP
                          </label>
                          <Field
                            class="w-full px-3 py-2 mb-3 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                            id="PIN" name='pin'
                            type="text"
                            placeholder="PIN/ZIP"
                          />
                          <p class="text-xs italic text-red-500"><ErrorMessage name="pin" component="span"/></p>
                        </div>
                      </div>

                      <div class="mb-2">
                        <label class="block mb-2 text-sm font-bold text-pink-500" for="sos">
                        Your SoS (Statement of Sustainability)
                        </label>
                        <Field as="textarea"
                          class="w-full h-16 px-3 py-2 mb-3 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                          id="sos" name='sos'
                          type="text"
                          placeholder="Statement of Sustainability"
                        />
                      </div>

                      <div class="mb-6 text-center">
                        <button
                          class="w-full md:w-2/5 text-xl px-4 py-2 font-semibold text-white bg-blue-500 rounded-full hover:bg-blue-700 focus:outline-none focus:shadow-outline"
                          type="submit"
                        >
                          Register
                        </button>
                      </div>
                      {/* <hr class="mb-6 border-t" />
                      <div class="text-center">
                        <a
                          class="inline-block text-sm text-blue-500 align-baseline hover:text-blue-800"
                          href="#"
                        >
                          Forgot Password?
                        </a>
                      </div>
                      <div class="text-center">
                        <a
                          class="inline-block text-sm text-blue-500 align-baseline hover:text-blue-800"
                          href="./index.html"
                        >
                          Already have an account? Login!
                        </a>
                      </div> */}
                    </Form>
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </body>

          <Footer/>
    </div>
  )
}

export default PreRegistration