import React from 'react';
import './WorldMap2.css';
import { Link } from 'react-router-dom';

// images
import background from './Assets/background1.png';
import worldMap from './Assets/worldMap2.png';
import combined from './Assets/combined3.png';
import pakistan from './Assets/pakistan4.png';
import usa from './Assets/usa5.png';
import india from './Assets/india6.png';
import turkey from './Assets/turkey7.png';
import peru from './Assets/peru8.png';
import italy from './Assets/italy9.png';


function WorldMap() {

    // const highlightCountry = (countryName) => {
    //     console.log('mouseEntered');
    //     // console.log(countryName);
    //     // const country = document.getElementById(countryName);
    //     // console.log(country);
    //     // country.classList.add('shadow-xl');
    //     document.getElementById('marker' + countryName).classList.remove('hidden');
    // }

    // const unHighlightCountry = (countryName) => {
    //     console.log('mouseLeft');
    //     // console.log(countryName);
    //     // const country = document.getElementById(countryName);
    //     // console.log(country);
    //     // country.classList.remove('shadow-xl');
    // }

  return (
    <div >
        

<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" className='rounded-lg shadow-md mx-4' viewBox="0 0 3300 1750">
  
    <image id="Background" width="3300" height="1750" xlinkHref={background} />
    <image x="-187" y="-136" width="3663" height="2004" xlinkHref={worldMap} />
    <image x="1321" y="691" width="730" height="269" xlinkHref={combined} />
    <Link to='/pakistan'><image className='country' x="2073" y="716" width="192" height="151" xlinkHref={pakistan} /></Link>
    <Link to='/usa'><image className='country' x="378" y="617" width="536" height="276" xlinkHref={usa} /></Link>
    <Link to='/india'><image className='country' id="india" x="2142" y="720" width="239" height="267" xlinkHref={india} /></Link>
    <Link to='/turkey'><image className='country' x="1727" y="634" width="173" height="87" xlinkHref={turkey} /></Link>
    <Link to='/peru'><image className='country' x="760" y="1142" width="123" height="179" xlinkHref={peru} /></Link>
    <Link to='/italy'><image className='country' x="1559" y="578" width="92" height="109" xlinkHref={italy} /></Link>
    
</svg>

    </div>
  )
}

export default WorldMap