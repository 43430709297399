import React, { useEffect, useState } from 'react';
import OrganizationService from '../../../services/OrganizationService';
import NotFound404 from '../../NotFound404/NotFound404';

import { Link, useNavigate } from 'react-router-dom';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';  
import AdminService from '../../../services/AdminService';
import ViewerService from '../../../services/ViewerService';

function RegisteredUsers() {

    let navigate = useNavigate();

    const jwtDecode = (token) => {
        var base64Payload = token.split('.')[1];
        var payload = atob(base64Payload);
        return JSON.parse(payload.toString());
    }

    const [listOfUsers, setListOfUsers] = useState([]);
    const [isAuthorized, setIsAuthorized] = useState(false);

    useEffect(() => {
        OrganizationService.validateToken().then((response) => {
            if(response.data.status === 200) {
                const accessToken = sessionStorage.getItem('accessToken');
                const payload = jwtDecode(accessToken);
                if(payload.role === 'admin') {
                    AdminService.allUsers().then((response) => {
                        setListOfUsers(response.data.users);
                    })
                } else if(payload.role === 'viewer') {
                    ViewerService.countryWiseUsers().then((response) => {
                        setListOfUsers(response.data.users);
                    })
                }                
                setIsAuthorized(true);
            } else {
                setIsAuthorized(false);
            }
        })
    }, [])

    const logout = () => {
        sessionStorage.removeItem('accessToken');
        navigate('/tahklogin');
    }

    

  return (
    <div className='bg-gradient-to-b from-sky-100 to-sky-50'>

        {
            isAuthorized ?
                <div class="sm:px-12 w-full text-center py-16 ">
                    <div class="px-4 md:px-10 ">
                        <div class="flex items-center justify-between font-serif">
                            <p tabindex="0" class="focus:outline-none text-3xl lg:text-4xl font-bold leading-normal text-gray-800">Registered Users({listOfUsers.length})</p>
                            <button onClick={logout}><div class="p-3 flex items-center text-xl font-medium leading-none text-gray-200 bg-gray-700 hover:bg-gray-800 cursor-pointer rounded">
                                Logout                               
                            </div></button> 
                        </div>
                    </div>

                    <div class="flex items-center px-4 mt-2">
                            <button class="rounded-full focus:outline-none focus:ring-2  focus:bg-indigo-50 focus:ring-indigo-800" href=" javascript:void(0)">
                                
                                <ReactHTMLTableToExcel 
                                     className="btn  py-2 px-4 rounded-full bg-sky-800 hover:bg-sky-900 active:bg-sky-700 text-white shadow hover:shadow-md"
                                     table="users"  
                                     filename="Registered Users"  
                                     sheet="Sheet"  
                                     buttonText="Export ⬇️"
                                />
                                
                            </button>                            
                    </div>

                    <div class="mt-7 overflow-y-auto shadow-md rounded-lg bg-slate-100">
                    <table id='users' class="text-left w-full max-w-full">
                        <thead class="bg-gray-700  flex text-white w-full rounded-t-md">
                            <tr class="flex w-full max-w-full mb-1">
                                <th class="px-4 py-3 w-2/12">Name</th>
                                <th class="px-4 py-3 w-2/12">Email</th>
                                <th class="px-4 py-3 w-1/12 overflow-y-auto whitespace-nowrap">DOB(dd/mm/yyyy)</th>
                                <th class="px-4 py-3 w-2/12">Phone</th>
                                <th class="px-4 py-3 w-2/12">Country</th>
                                <th class="px-4 py-3 w-1/12">PIN</th>
                                <th class="px-4 py-3 w-2/12">SOS</th>
                            </tr>
                        </thead>
                    
                        <tbody class="bg-grey-light border-2 border-gray-300 flex flex-col overflow-y-scroll w-full h-[68vh]">
                            
                            {listOfUsers.map((value, key) => {
                                    return (
                                        <tr class="flex w-full  max-w-[1340px] border-b-2 border-gray-200">
                                            <td class="border-r-2 border-gray-200 p-3 w-2/12 overflow-y-auto max-w-2/12 whitespace-nowrap">{value.name}</td>
                                            <td class="border-r-2 border-gray-200 p-3 w-2/12 overflow-y-auto max-w-2/12 whitespace-nowrap">{value.email}</td>
                                            {
                                                value.dob !== null ?
                                                    <td class="border-r-2 border-gray-200 p-3 w-1/12 overflow-y-auto whitespace-nowrap">{new Date(value.dob).getDate().toString()}/{(new Date(value.dob).getMonth()+1).toString()}/{new Date(value.dob).getFullYear().toString()} </td>
                                                :
                                                    <td class="border-r-2 border-gray-200 p-3 w-1/12 overflow-y-auto whitespace-nowrap"> </td>

                                            }
                                            {/* <td class="border-r-2 border-gray-200 p-3 w-1/12 overflow-y-auto whitespace-nowrap">{new Date(value.dob).getDate().toString()}/{(new Date(value.dob).getMonth()+1).toString()}/{new Date(value.dob).getFullYear().toString()} </td> */}
                                            <td class="border-r-2 border-gray-200 p-3 w-2/12">{value.phone}</td>
                                            <td class="border-r-2 border-gray-200 p-3 w-2/12 overflow-y-auto whitespace-nowrap">{value.country}</td>
                                            <td class="border-r-2 border-gray-200 p-3 w-1/12">{value.pin}</td>
                                            <td class="p-3 w-2/12 overflow-y-auto max-w-2/12 whitespace-nowrap">{value.sos}</td>
                                        </tr>
                                    );
                            })}
                            
                        </tbody>
                    </table>
                    </div>   

                    <div className='mx-auto mt-10 noselect'>
                        <p class="mb-4 font-serif text-sky-600 text-lg italic text-center">
                        <Link to='/tahkfamily/addpost'
                            className='mt-10 h-10 px-4 py-[0.65rem] opacity-200 font-semibold text-base text-gray-50 dark:text-gray-50 shadow-sm hover:shadow hover:text-gray-100 transition-colors duration-200 transform bg-pink-600 rounded-3xl hover:bg-pink-700  hover:border-gray-500 uppercase'
                        >Add Post</Link>
                        </p>
                    </div>  

                    </div>
            :
                <NotFound404/>
        
        }


        

        
    </div>
  )
}

export default RegisteredUsers