import './Intrapreneurial.css';
import { Link } from 'react-router-dom';
import Navbar from '../Navbar/Navbar';
import $ from 'jquery';

import {Helmet} from "react-helmet";

// images
import logo from '../../assets/logo.png';
import sipLogo from '../../assets/SIPLogo.png';
import tahko2 from '../../assets/iprojects/TAHKO2.png';
import wastemanagement from '../../assets/iprojects/Wastemanagementtahk.png';
import gbdaycover from '../../assets/iprojects/GreenBirthdayCover.jpeg';

import Footer from '../Footer/Footer';

function Intrapreneurial() {

    $(document).ready(() => {
        document.body.scrollTop = 0; 
        document.documentElement.scrollTop = 0;

    })

  return (

        // BRIEFS

      <div class="w-full bg-sky-50 noselect">

            <Helmet>
                <title>TAHK - Intrapreneurial Projects</title>
                <meta
                    name="description"
                    content="Innovative projects for Sustainable Living on Planet Earth"
                />
                <meta property="og:url" content={document.location.href} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="TAHK - Intrapreneurial Projects " />
                <meta property="og:description" content="Innovative projects for Sustainable Living on Planet Earth" />
                <meta property="og:image" content={logo}/>
                <link rel="image_src" href={logo} />
            </Helmet>
          
          <div className=''>
              <Navbar/>
          </div>
          

            {/* Intrepreneurial Projects */}
            <div id='intrapreneurial'>
              <div class="bg-teal-200 text-center w-full py-10 shadow-sm">
                  <h1 class="font-bold text-3xl md:text-4xl lg:text-5xl font-heading text-sky-900 md:mb-2 lg:mb-2">
                    Intrapreneurial Projects      
                  </h1>
                  <p className="text-sky-700 text-lg font-bold mx-10 lg:mx-40 mt-6">
                      We are one team, everyone including the Idea founder are volunteers to the Mother Earth and accordingly everyone can come up with new Ideas at TAHK to experiment and explore contribution towards Sustainability
                  </p>
              </div>
              <div class="cards mx-10 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-10 mt-6">

                  <Link to='/greenbirthday'>
                    <div class="box w-full h-96 bg-cover bg-no-repeat bg-center bg-gray-200 rounded-2xl sahdow-lg p-12 flex flex-col justify-center items-center transform hover:scale-105 transition duration-500" style={{backgroundImage: `url(${gbdaycover})`}} >
                      <div class="d text-center opacity-0 ">
                          <p class="text-xl text-white font-bold mb-2 ">Green Birthday</p>
                          <p class="text-base text-gray-400 font-normal">October 2020</p>
                      </div>
                    </div>
                  </Link>

                  <Link to='/o2'>
                  <div class="box w-full h-96 bg-cover bg-no-repeat bg-center bg-gray-200 rounded-2xl sahdow-lg p-12 flex flex-col justify-center items-center transform hover:scale-105 transition duration-500"  style={{backgroundImage: `url(${tahko2})`}}>
                      <div class="d text-center opacity-0">
                          <p class="text-xl text-white font-bold mb-2">TAHK O2 Park</p>
                          {/* <p class="text-base text-gray-400 font-normal">2022</p> */}
                      </div>
                  </div>
                  </Link>

                  <Link to='/investthewaste'>
                  <div class="box w-full h-96 bg-cover bg-no-repeat bg-center bg-gray-200 rounded-2xl sahdow-lg p-12 flex flex-col justify-center items-center transform hover:scale-105 transition duration-500"  style={{backgroundImage: `url(${wastemanagement})`}}> 
                      <div class="d text-center opacity-0" >
                          <p class="text-xl text-white font-bold mb-2">Waste Management</p>
                          {/* <p class="text-base text-gray-400 font-normal">2022</p> */}
                      </div>
                  </div>
                  </Link>

                  <Link to='/sipjr'>
                  <div class="box w-full h-96 bg-cover bg-no-repeat bg-center bg-gray-300 rounded-2xl sahdow-lg p-12 flex flex-col justify-center items-center transform hover:scale-105 transition duration-500" style={{backgroundImage: `url(${sipLogo})`}}>
                      <div class="d text-center opacity-0">
                          <p class="text-xl text-white font-bold mb-2">SIP Jr.</p>
                          {/* <p class="text-base text-gray-400 font-normal">2022</p> */}
                      </div>
                  </div>
                  </Link>
                  
              </div>
              </div>

              <div id='footer' className='mt-10'>
      <Footer/>
    </div>
      </div>

  );
}

export default Intrapreneurial;
