import React from 'react';
import Navbar from '../../Navbar/Navbar';
import $ from 'jquery';

// images
import sdg2 from '../assets/sdg2.webp';
import logo from '../../../assets/logo.png';
import {Helmet} from "react-helmet";

import Footer from '../../Footer/Footer';

function ZeroHunger() {

    $(document).ready(() => {
        document.body.scrollTop = 0; 
        document.documentElement.scrollTop = 0;
    
      })

  return (
    <div className='bg-sky-50 noselect'>

        <Helmet>
            <title>TAHK - Zero Hunger</title>
            <meta
                name="description"
                content="Provide food for the most at-risk regions and change the global system of Agriculture"
            />
            <meta property="og:url" content={document.location.href} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content="TAHK - Zero Hunger" />
            <meta property="og:description" content="Provide food for the most at-risk regions and change the global system of Agriculture" />
            <meta property="og:image" content={logo}/>
            <link rel="image_src" href={logo} />
        </Helmet>

        <Navbar/>

        <section >
            <div class="max-w-7xl px-6 py-16 mx-0">
                <div class="items-center md:flex md:space-x-6">
                    <div class="md:w-1/2">
                        <div class="flex items-center justify-center">
                            <div class="max-w-md">
                                <img class="object-cover object-center w-full rounded-md shadow" style={{height: 500}}
                                    src={sdg2}/>
                            </div>
                        </div>
                    </div>
        
                    <div class="mt-8 md:mt-0 md:w-1/2">
                        <h2 class="max-w-xl text-2xl text-sky-800 font-bold md:text-4xl md:pl-4 uppercase">Zero Hunger </h2>
                        <p class="max-w-xl mt-4 text-lime-800 text-lg md:pl-4">
                            Hunger is the leading cause of death in the world. 
                            Our planet has provided us with tremendous resources, 
                            but unequal access and inefficient handling leaves millions of people malnourished. 
                            We can sustain the whole world’s population with sustainable agriculture with modern technologies and fair distribution systems, and make sure that nobody will ever suffer from hunger again.
                        </p>
                        
                        
                    </div>
                </div>
            </div>
        </section>

        <div id='footer' className='mt-12'>
        <Footer/>
        </div>
    </div>
  )
}

export default ZeroHunger