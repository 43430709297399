import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AdminService from '../../../services/AdminService'
import NotFound404 from '../../NotFound404/NotFound404';

import countryList from '../../../services/CountryList';


function Viewers() {

    let navigate = useNavigate();

    const [viewers, setViewers] = useState([]);
    const [isAuthorized, setIsAuthorized] = useState(false);
    const [username, setUsername] = useState('');
    const [newViewer, setNewViewer] = useState({});
    const [action, setAction] = useState('');

    useEffect(() => {
        AdminService.validateToken().then((response) => {
            if(response.data.status === 200) {
                AdminService.allViewers().then((response) => {
                    setViewers(response.data.viewers);
                })
                setIsAuthorized(true);
            } else {
                setIsAuthorized(false);
            }
        })
    }, [])

    const warnDeleteViewer = (username) => {
        if(window.confirm(`Are you sure? \nYou want to delete \'${username}\'?`)) {
            // console.log('delete: ' + username);
            setUsername(username);
            setAction('del');
            openModal1();
        }        
    }

    const addViewerConfirm = () => {
        const username = document.getElementById('username').value;
        const country = document.getElementById('country').value;
        const password = document.getElementById('password').value;

        if(username !== '' && country !== '' && password !== ''){
            const newViewer = {username: username, country: country, password: password};
            setNewViewer(newViewer);
            closeModal2();
            setAction('add');
            openModal1();
        } else {
            alert('All fields are mandatory!');
        }        
    }

    const addDelViewer = (flag) => {

        const adminPassword = document.getElementById('adminPassword').value;

        if(flag === 'add') {            
            const addPayload = { username: newViewer.username, password: newViewer.password, country: newViewer.country, adminPassword: adminPassword};

            if(adminPassword !== ''){
                AdminService.registerViewer(addPayload).then((response) => {
                    if(response.data.status === 200) {
                        alert(response.data.message);
                        window.location.reload();
                    } else if(response.data.status) {
                        alert(response.data.status + ": " + response.data.error);
                    } else if(response.data.error) {
                        alert(response.data.error);
                    }

                })
            } else {
                alert("Password can not be empty!");
            }
            
        } else if(flag === 'del') {
            const deletePayload = { username: username, adminPassword: adminPassword};

            if(adminPassword !== ''){
                AdminService.deleteViewer(deletePayload).then((response) => {
                    if(response.data.status === 200) {
                        alert(response.data.message);
                        window.location.reload();
                    } else if(response.data.status) {
                        alert(response.data.status + " : " + response.data.error);
                    } else if(response.data.error) {
                        alert(response.data.error);
                    }

                })
            } else {
                alert("Password can not be empty!");
            }            
        }
        
    } 


    const openModal1 = () => {
        document.getElementById('modal1').classList.remove('hidden');
        document.getElementById('adminPassword').focus();
    }

    const closeModal1 = () => {
        document.getElementById('modal1').classList.add('hidden');
        document.getElementById('adminPassword').value = '';
        setAction('');
        setNewViewer({});
        setUsername('');
    }

    const openModal2 = () => {
        document.getElementById('modal2').classList.remove('hidden');
        document.getElementById('username').focus();
    }

    const closeModal2 = () => {
        document.getElementById('modal2').classList.add('hidden');
        document.getElementById('username').value = '';
        document.getElementById('country').value = '';
        document.getElementById('password').value = '';
    }

    const logout = () => {
        sessionStorage.removeItem('accessToken');
        navigate('/tahklogin');
    }

    var showPassword = false;
    const togglePassword = () => {
        showPassword = !showPassword;
        
        // showPassword ? (document.getElementById('password').type = 'text') : (document.getElementById('password').type = 'password')

        if(showPassword){
        document.getElementById('password').type = 'text';
        document.getElementById('showHide').innerHTML = 'Hide';
        } else {
        document.getElementById('password').type = 'password';
        document.getElementById('showHide').innerHTML = 'Show';
        }
    }

  return (
    <div>

        {
            isAuthorized ?
                <div>
                    <section class="container mx-auto p-6 font-mono">

                        <div class="px-4 md:px-10 font-serif py-6">
                            <div class="flex items-center justify-between">
                                    <p class="focus:outline-none text-3xl lg:text-4xl font-bold leading-normal text-gray-800">Registered Viewers({viewers.length})</p>
                                    <button onClick={logout}><div class="p-3 flex items-center text-xl font-medium leading-none text-gray-200 bg-gray-700 hover:bg-gray-800 cursor-pointer rounded">
                                        Logout                               
                                    </div></button> 
                            </div>
                        </div>

                        <div class="flex items-center px-4">
                            <button onClick={openModal2} class="rounded-full focus:outline-none focus:ring-2  focus:bg-indigo-50 focus:ring-indigo-800" href=" javascript:void(0)">
                                <div class="py-2 px-4 bg-indigo-100 hover:bg-indigo-200 text-indigo-700 rounded-full">
                                    <p>Add New Viewer</p>
                                </div>
                            </button>                            
                        </div>

                    <div class="w-full mb-8 overflow-hidden rounded-lg shadow-lg mt-10">

                        <div class="w-full overflow-x-auto">
                        <table class="w-full">
                            <thead>
                                <tr class="text-md font-semibold tracking-wide text-left text-gray-900 bg-gray-100 uppercase border-b border-gray-600 noselect">
                                    <th class="px-4 py-3 w-3/12">Username</th>
                                    <th class="px-4 py-3 w-4/12">Country</th>
                                    <th class="px-4 py-3 w-2/12">Date Created</th>
                                    <th class="px-4 py-3 w-3/12"></th>
                                </tr>
                            </thead>
                            <tbody class="bg-white">

                                {viewers.map((value, key) => {
                                    return (
                                        <tr class="text-gray-700">
                                            <td class="px-4 py-3 border">
                                                <div class="flex items-center text-lg">
                                                    <p class="font-medium text-black">{value.username}</p>
                                                </div>
                                            </td>
                                            <td class="px-4 py-3 text-ms font-semibold border overflow-y-auto whitespace-nowrap">{value.country}</td>
                                            <td class="px-4 py-3 text-sm border">{new Date(value.createdAt).getDate().toString()}/{(new Date(value.createdAt).getMonth() + 1).toString()}/{new Date(value.createdAt).getFullYear().toString()}</td>
                                            <td class="px-4 py-3 text-xs border">
                                                <button onClick={() => {warnDeleteViewer(value.username)}} class="px-2 py-1 font-semibold text-base leading-tight text-red-700 bg-red-100 hover:bg-red-200 rounded-md"> Delete </button>
                                            </td>                    
                                        </tr>
                                    );
                                })}

                            </tbody>
                        </table>
                        </div>
                    </div>
                    </section>

                    <div id='modal1' class="modal hidden h-screen w-full fixed left-0 top-0 flex justify-center items-center bg-black bg-opacity-50">
                
                        <div class="bg-white rounded shadow-lg w-10/12 md:w-1/3 noselect">
                            
                            <div class="border-b px-4 py-2 flex justify-between items-center">
                                <h3 class="font-semibold text-lg">Confirm</h3>
                                <button onClick={closeModal1} class="text-black close-modal">X</button>
                            </div>
                            
                            <div class="p-3">
                                <label htmlFor='adminPassword'>Please enter admin password to confirm:</label>
                                <input id='adminPassword' type='password' placeholder='Password' class="block w-full mt-2
                                  text-gray-800 appearance-none
                                  border-2 border-gray-300
                                  focus:text-gray-500 focus:outline-none focus:border-gray-200 rounded-md" />
                            </div>
                            <div class="flex justify-end items-center w-100 border-t p-3">
                                <button onClick={closeModal1} class="bg-red-600 hover:bg-red-700 px-3 py-1 rounded text-white mr-1 close-modal">Cancel</button>
                                <button onClick={() => {addDelViewer(action)}} class="bg-blue-600 hover:bg-blue-700 px-3 py-1 rounded text-white">Submit</button>
                            </div>
                        </div>
                    </div>

                    <div id='modal2' class="modal hidden h-screen w-full fixed left-0 top-0 flex justify-center items-center bg-black bg-opacity-50">
                
                        <div class="bg-white rounded shadow-lg w-10/12 md:w-1/3 noselect">
                            
                            <div class="border-b px-4 py-2 flex justify-between items-center">
                                <h3 class="font-semibold text-lg">New Viewer</h3>
                                <button onClick={closeModal2} class="text-black close-modal">X</button>
                            </div>
                            
                            <div class="p-3">
                                <p >Enter <b>username</b>, <b>password</b> and <b>country</b> of new viewer:</p>
                                
                                <div class="relative w-full">
                                    <input type='text' id="username" class="block w-full py-3 px-3 mt-2 
                                        text-gray-500 appearance-none
                                        border-2 border-gray-100
                                        focus:text-gray-800 focus:outline-none focus:border-gray-200 rounded-md" 
                                        placeholder='Username' name = 'username' autoComplete='off'
                                    />
                                </div>
                                <div class="relative w-full">
                                    <input type='text' id="country" class="block w-full py-3 px-3 mt-2 
                                        text-gray-800 appearance-none
                                        border-2 border-gray-100
                                        focus:text-gray-800 focus:outline-none focus:border-gray-200 rounded-md" 
                                        placeholder='Country' name = 'country' list='countries'
                                    />
                                    <datalist id='countries'>
                                    {countryList.map((value, key) => {
                                        return (
                                            <option value={value}></option>
                                        );
                                    })}                          
                                    </datalist>
                                </div>
                                <div class="relative w-full">
                                    <input type='password' id="password" class="block w-full py-3 px-3 mt-2 
                                        text-gray-800 appearance-none
                                        border-2 border-gray-100
                                        focus:text-gray-800 focus:outline-none focus:border-gray-200 rounded-md" 
                                        placeholder='Password' name = 'password'
                                    />
                                        <div class="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
                                            <p id='showHide' class="font-semibold hover:cursor-pointer noselect" onClick={togglePassword} >Show</p>
                                        </div>
                                </div>

                            </div>
                            <div class="flex justify-end items-center w-100 border-t p-3">
                                <button onClick={closeModal2} class="bg-red-600 hover:bg-red-700 px-3 py-1 rounded text-white mr-1 close-modal">Cancel</button>
                                <button onClick={addViewerConfirm} class="bg-blue-600 hover:bg-blue-700 px-3 py-1 rounded text-white">Register</button>
                            </div>
                        </div>
                    </div>
                </div>

                

            :

                <NotFound404/>
        }
    </div>
  )
}

export default Viewers