import React from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';

// images
import education from '../../../assets/coverImgs/education.png';
import img1 from '../../../assets/countryImgs/India/img1.jpeg';
import img2 from '../../../assets/countryImgs/India/img2.png';
import coverimg from '../../../assets/countryImgs/India/coverImg.png';
import logo from '../../../assets/logo.png';

import {Helmet} from "react-helmet";

import './India.css';
import Footer from '../../Footer/Footer';
import Navbar from '../../Navbar/Navbar';

function India() {

    $(document).ready(() => {
        document.body.scrollTop = 0; 
        document.documentElement.scrollTop = 0;

    })

  return (
    <div className='bg-sky-50 noselect'>


        <Helmet>
            <title>TAHK - India</title>
            <meta
                name="description"
                content="The movement for Mother Nature begins from Mother India"
            />
            <meta property="og:url" content={document.location.href} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content="TAHK - India" />
            <meta property="og:description" content="The movement for Mother Nature begins from Mother India" />
            <meta property="og:image" content={logo}/>
            <link rel="image_src" href={logo} />
        </Helmet>

        <Navbar/>
        <section id='header' class="bg-cover bg-no-repeat bg-center bg-blue-100 dark:bg-blue-100" >
            
            <div id='content' class="container flex flex-col px-6 py-10 mx-auto space-y-6 lg:h-[23rem] lg:py-16 lg:flex-row lg:items-center">
                <div class="w-full lg:w-1/2">
                    <div class="lg:max-w-3xl">
                        <h1 class="text-3xl font-bold tracking-wide text-zinc-800 lg:text-gray-700 lg:dark:text-gray-700 lg:text-5xl">
                            TAHK India - भारत
                        </h1>
                        
                        <div class="mt-8 space-y-5">
                            <p class="flex items-center -mx-2 text-black lg:text-gray-700 lg:dark:text-gray-700">
                                <span class="text-xl mx-2">“छायामन्यस्य कुर्वन्ति तिष्ठन्ति स्वयमातपे । फलान्यपि परार्थाय वृक्षाः सत्पुषा ईव ॥"</span>
                            </p>

                        </div>
                    </div>

                    
                    
                </div>
        
                <div class="flex items-center justify-center w-full h-80 lg:w-1/2">
                    <img class="h-full mx-auto rounded-md lg:max-w-2xl" src={coverimg} alt="cover"/>
                </div>
            </div>
        </section>

        <section >
        <div class="max-w-7xl px-6 py-16 mx-auto">
            <div class="items-center md:flex md:space-x-6">
                <div class="md:w-1/2 pl-1">
                    {/* <h3 class="text-5xl font-bold text-gray-800">Di</h3> */}
                    <p class="max-w-xl mt-4 text-gray-600 text-lg">
                        TreeAndHumanKnot, प्रकृति और मानव जाति के बीच शाश्वत बंधन से बनी हैं। April 2019 में ईस विचार की कल्पना की गई थी और महसूस किया  गया था कि एक साथ काम करके हम "पहले देने" की विचारधारा के साथ अपनी चुनौतियों को अधिक प्रभावी ढंग से दूर कर सकते हैं और इससे TreeAndHumanKnot का उद्भव हुआ। TreeAndHumanKnot को अगस्त 2020 में पूर्व-शुभारंभ किया गया जो अब एक वैश्विक आंदोलन में बदल गया है। तीन महाद्वीपों में उपस्थिति के साथ वैश्विक आंदोलन प्रकृति एवं मानव के इस अनूठे संबंध से पृथ्वी पर स्थिरता को फिर से लागू और अपनी अभिनव परियोजनाओं के साथ विकास के लिए पनपने की दृष्टि रखता है।                    
                    </p>
                    
                </div>
    
                <div class="mt-8 md:mt-0 md:w-1/2">
                    <div class="flex items-center justify-center">
                        <div class="max-w-md">
                            <img class="object-center w-full rounded-md shadow" style={{height: "500px"}}
                                src={img1}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </section>
    
        <section >
            <div class="max-w-7xl px-6 py-16 mx-6">
                <div class="items-center md:flex md:space-x-6">
                    <div class="md:w-1/2">
                        <div class="flex items-center justify-center">
                            <div class="max-w-2xl w-10/12">
                                <img class=" object-center w-full rounded-md shadow" style={{height: 290}}
                                    src={img2}/>
                            </div>
                        </div>
                    </div>
        
                    <div class="mt-8 md:mt-0 md:w-1/2">
                        <p class="max-w-xl mt-4 text-gray-600 text-lg md:pl-4">
                            यह दूर दृष्टि एक सुनहरे भविष्य के पुनःनिर्माण की 
                            रणनीतिक रूप से पेड़ लगाने के माध्यम से प्रकृति के लिए प्रेम, खुशी और करुणा का आनंद लेते हुए स्थिरता के लिए व्यावहारिक और सरल समाधानों के साथ हम लोगों और समुदायों को एक उज्जवल भविष्य की और प्रेरित कर सकते हैं और आंदोलन में शामिल होने के लिए आगे ला सकते हैं।
                            यह संयुक्त राष्ट्र द्वारा निर्धारित सतत विकास लक्ष्यों के साथ भी प्रतिध्वनित होता हैं। 
                            
                            <p class="max-w-xl mt-4 text-gray-800 text-lg ">
                            <p className='inline text-red-500'>दृष्टि:</p> <p className='inline text-green-600'> &nbsp;ग्रह पृथ्वी पर स्थायी जीवन को फिर से लागू करें</p>
                            <br/>
                            <p className='inline text-red-500'>उद्देश्य:</p> <p className='inline text-green-600'> &nbsp;2030 तक 1 लाख मानव प्रकृति से जुड़े </p>
                            <br/>
                            <p className='inline text-red-500'>मूल्य:</p> <p className='inline text-green-600'> &nbsp;प्रतिबद्धता, स्थिरता, और प्रकृति की देखभाल </p>
                            </p>                       
                        </p>
                        <p class="max-w-xl mt-4 text-gray-800 text-lg md:pl-4">Contact us at: <p className='inline hover:underline text-blue-600'><a href='mailto:india@tahk.in'>india@tahk.in</a></p></p>
                        <div class="md:flex md:justify-center">
                            <div class="mt-10 mx-auto">
                                <div>

                                <div class="flex flex-wrap justify-center gap-4">
                                    
                                    <a href='https://www.facebook.com/TreeAndHumanKnot' target='_blank' className='hover:text-white'>
                                    <button id="facebook" class=" duration-500 w-10 md:w-12 h-10 md:h-12 transform hover:-translate-y-1 hover:shadow-md text-xl md:text-2xl rounded-full hover:text-gray-900 text-gray-600 ">
                                        <i class="fab fa-facebook-f"></i>
                                    </button></a>

                                    <a href='https://instagram.com/TreeAndHumanKnot' target='_blank' className='hover:text-white'>
                                    <button id="instagram" class=" duration-500 w-10 md:w-12 h-10 md:h-12 transform hover:-translate-y-1 hover:shadow-md text-xl md:text-2xl rounded-full hover:text-gray-900 text-gray-600 ">
                                        <i class="fab fa-instagram"></i>
                                    </button></a>

                                    {/* <a href='https://twitter.com/TAHK2030' target='_blank' className='hover:text-white'>
                                    <button id="twitter" class=" duration-500 w-10 md:w-12 h-10 md:h-12 transform hover:-translate-y-1 hover:shadow-md text-xl md:text-2xl rounded-full hover:text-gray-900 text-gray-600 ">
                                        <i class="fab fa-twitter"></i>                      
                                    </button></a> */}

                                    {/* <a href='https://www.linkedin.com/company/treeandhumanknot' target='_blank' className='hover:text-white'>
                                    <button id="linkedin" class=" duration-500 w-10 md:w-12 h-10 md:h-12 transform hover:-translate-y-1 hover:shadow-md text-xl md:text-2xl rounded-full hover:text-gray-900 text-gray-600 ">
                                        <i class="fab fa-linkedin-in"></i>
                                    </button></a> */}


                                    {/* <a href='https://www.youtube.com/channel/UCpjOZ_P3Jk6TYMQXnH7ByPg' target='_blank' className='hover:text-white'>
                                    <button id="youtube" class=" duration-500 w-10 md:w-12 h-10 md:h-12 transform hover:-translate-y-1 hover:shadow-md text-xl md:text-2xl rounded-full hover:text-gray-900 text-gray-600 ">
                                        <i class="fab fa-youtube"></i>
                                    </button></a> */}

                                    <a href='mailto:india@tahk.in' target='_blank' className='hover:text-white'>
                                    <button id="email" class=" duration-500 w-10 md:w-12 h-10 md:h-12 transform hover:-translate-y-1 hover:shadow-md text-xl md:text-2xl rounded-full hover:text-gray-900 text-gray-600 ">
                                        <i class="fas fa-envelope"></i>
                                    </button></a>

                                </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </section>

        <hr class="w-2/5 my-14 border-4 rounded-md sm:mx-0 mx-auto" />

            <Footer/>
    </div>
  )
}

export default India