import './Briefs.css';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import $ from 'jquery';
import logo from '../../assets/logo.png'

import {Helmet} from "react-helmet";

function Briefs() {
    $(document).ready(() => {
        document.body.scrollTop = 0; 
        document.documentElement.scrollTop = 0;

    })

    const briefs = [
        {
            month: 'August',
            year: '2020',
            embededVideoUrl: 'https://www.youtube.com/embed/5op3t4YpXf4'
        },
        {
            month: 'September',
            year: '2020',
            embededVideoUrl: 'https://www.youtube.com/embed/AC35fC3l3mY'
        },
        {
            month: 'October',
            year: '2020',
            embededVideoUrl: 'https://www.youtube.com/embed/XZ2EzJlrexg'
        },
        {
            month: 'November',
            year: '2020',
            embededVideoUrl: 'https://www.youtube.com/embed/C8HWTo19dX8'
        },
        {
            month: 'December',
            year: '2020',
            embededVideoUrl: 'https://www.youtube.com/embed/ZXDZPsyZKeY'
        },
        {
            month: 'January',
            year: '2021',
            embededVideoUrl: 'https://www.youtube.com/embed/666YmYcJQh4'
        },
        {
            month: 'February',
            year: '2021',
            embededVideoUrl: 'https://www.youtube.com/embed/-EB70qxNHic'
        },
        {
            month: 'March',
            year: '2021',
            embededVideoUrl: 'https://www.youtube.com/embed/8l2pCCyuyaY'
        },
        {
            month: 'April',
            year: '2021',
            embededVideoUrl: 'https://www.youtube.com/embed/wUT49buiFMI'
        },
        {
            month: 'May',
            year: '2021',
            embededVideoUrl: 'https://www.youtube.com/embed/WLhNt-Aq0WU'
        },
        {
            month: 'June',
            year: '2021',
            embededVideoUrl: 'https://www.youtube.com/embed/qPQFCYojBgY'
        },
        {
            month: 'July',
            year: '2021',
            embededVideoUrl: 'https://www.youtube.com/embed/MxurnTw7fAU'
        },
        {
            month: 'August',
            year: '2021',
            embededVideoUrl: 'https://www.youtube.com/embed/V6xXqV9DNjo'
        },
        {
            month: 'September',
            year: '2021',
            embededVideoUrl: 'https://www.youtube.com/embed/Z3vzc7Pvgs8'
        },
        {
            month: 'October',
            year: '2021',
            embededVideoUrl: 'https://www.youtube.com/embed/1tVuX2UbI8g'
        },
        {
            month: 'November',
            year: '2021',
            embededVideoUrl: 'https://www.youtube.com/embed/kQa-w60BfwE'
        },
        {
            month: 'December',
            year: '2021',
            embededVideoUrl: 'https://www.youtube.com/embed/AuC8ZB9BmPg'
        },
        {
            month: 'January',
            year: '2022',
            embededVideoUrl: 'https://www.youtube.com/embed/bHOtbYuOHeo'
        },
        {
            month: 'February',
            year: '2022',
            embededVideoUrl: 'https://www.youtube.com/embed/S7rpqwxWApc'
        },
        {
            month: 'March',
            year: '2022',
            embededVideoUrl: 'https://www.youtube.com/embed/mjR2_YFxCpE'
        },
        {
            month: 'April',
            year: '2022',
            embededVideoUrl: 'https://www.youtube.com/embed/7RuDxd1BmvM'
        },
        {
            month: 'May',
            year: '2022',
            embededVideoUrl: 'https://www.youtube.com/embed/gm8Hds74o3c'
        },
    ]


  return (

      <div class="w-full bg-sky-50 noselect">

        <Helmet>
            <title>TAHK - Monthly Briefs</title>
            <meta
                name="description"
                content="Featuring selfless nature lovers for their relentless work towards Nature"
            />
            <meta property="og:url" content={document.location.href} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content="TAHK - Monthly Briefs" />
            <meta property="og:description" content="Featuring selfless nature lovers for their relentless work towards Nature" />
            <meta property="og:image" itemprop="image" content={logo}/>
            <link rel="image_src" href={logo} />

                <meta name="msapplication-TileImage" content={logo}/>
 
                <meta property="og:site_name" content="TAHK - TreeAndHumanKnot"/>

                <meta property="og:image:type" content="image/png"/>

                <meta property="og:image:width" content="300"/>
                <meta property="og:image:height" content="300"/>

                <meta property="twitter:card" content="summary_large_image"/>
                <meta property="twitter:url" content={document.location.href}/>
                <meta property="twitter:title" content="TAHK - Monthly Briefs"/>
                <meta property="twitter:description" content="Featuring selfless nature lovers for their relentless work towards Nature"/>
                <meta property="twitter:image" content={logo}/>

        </Helmet>
          
          <div className=''>
              <Navbar/>
          </div>
          

            {/* Briefs */}
            <div id='briefs'>
              <div class="bg-teal-200 text-center w-full py-10 shadow-sm">
                  <h1 class="font-bold text-3xl md:text-4xl lg:text-5xl font-heading text-sky-900 md:mb-2 lg:mb-2">
                      Monthly Briefs         
                  </h1>
                  <p className="text-sky-700 text-lg font-bold mx-10 lg:mx-40 mt-6">
                    TreeAndHumanKnot is cultivated from the eternal bond between Nature & Mankind.  We bring pragmatic solutions for sustainability while enjoining love, pleasure, and compassion through strategically planting trees to make-people, communities and companies agents-of-change.
                    <br/>We all are volunteers to Mother Earth and should Tie yet another TreeAndHumanKnot.  
                    <br/>Giving first with Social Enterprise TreeAndHumanKnot, which is cultivated from the eternal bond between Nature & Mankind.                   
                  </p>
               </div>
              <div class="cards mx-10 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-10 mt-6">

                
                    {briefs.slice(0).reverse().map((value, key) => {
                        return (
                            <div class="video-wrapper box w-full h-80 bg-gray-200 rounded-2xl sahdow-lg p-12 flex flex-col justify-center items-center transform hover:scale-105 transition duration-500"  >
                                <iframe src={value.embededVideoUrl} className='rounded-md' title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen='true'></iframe>
                                <div class="d carousel-caption d-none d-md-block text-center opacity-0">
                                        <p class="text-xl text-white font-bold mb-2">{value.month} BRIEF</p>
                                        <p class="text-base text-white font-normal">{value.year}</p>
                                </div>                        
                            </div>
                        );
                    })}                    
                  
              </div>
              </div>

              <div id='footer' className='mt-10'>
                <Footer/>
                </div>
      </div>

  );
}

export default Briefs;
