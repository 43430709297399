import React, { useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import OrganizationService from '../../../services/OrganizationService';

function OrganizationLogin() {

  let navigate = useNavigate();
  
  const jwtDecode = (token) => {
    var base64Payload = token.split('.')[1];
    var payload = atob(base64Payload);
    return JSON.parse(payload.toString());
  }

    const initialValues = {
      username: "",
      password: ""
	  };

    const validationSchema = Yup.object().shape({     
      username: Yup.string().required('Username is required'),
      password: Yup.string().required('Password is required')
	  });

    const onSubmit = (data) => {
      
      OrganizationService.login(data).then((response) => {
        if(response.data.errorName){
          document.getElementById(response.data.errorName).innerHTML = response.data.error;
          document.getElementById(response.data.errorName).classList.remove('hidden');
        } else if (response.data.accessToken) {

          const accessToken = response.data.accessToken
          sessionStorage.setItem('accessToken', accessToken);
          
            const payload = jwtDecode(accessToken);
            if(payload.role === 'admin'){
              navigate('/admin/dashboard');
            } else if (payload.role === 'viewer'){
              navigate('/tahkfamily/allusers');
            }
                    
        }
      })
	  }

  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://unpkg.com/alpinejs@3.2.3/dist/cdn.min.js";
    script.async = true;
    
    document.body.appendChild(script);
  })

  const onChange = (event) => {
    if(event._reactName === 'onChange'){
      if(event.target.name === 'username'){
        if(!document.getElementById('usernameError').classList.contains('hidden')) {
          document.getElementById('usernameError').classList.add('hidden');
        }
      }
      if(event.target.name === 'password'){
        if(!document.getElementById('passwordError').classList.contains('hidden')) {
          document.getElementById('passwordError').classList.add('hidden');
        }
      }
    }
  }

  var showPassword = false;
  const togglePassword = () => {
    showPassword = !showPassword;
    
    // showPassword ? (document.getElementById('password').type = 'text') : (document.getElementById('password').type = 'password')

    if(showPassword){
      document.getElementById('password').type = 'text';
      document.getElementById('showHide').innerHTML = 'Hide';
    } else {
      document.getElementById('password').type = 'password';
      document.getElementById('showHide').innerHTML = 'Show';
    }
  }

  return (
    <div>
        <div class="flex flex-col h-screen bg-gradient-to-b from-[#063970] to-blue-200">
              <div class="grid place-items-center mx-2 my-20 sm:my-auto" x-data="{ showPass: true }">
                  <div class="w-11/12 p-12 sm:w-8/12 md:w-6/12 lg:w-5/12 2xl:w-4/12
                      px-6 py-10 sm:px-10 sm:py-6
                      bg-white rounded-lg shadow-md lg:shadow-lg">
                      <div class="text-center mb-4">
                          <h6 class="font-semibold text-[#063970] text-xl uppercase">TAHK Login</h6>
                      </div>
                      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
                      <Form onChange={onChange} class="space-y-5">
                          <div>
                              <Field id="username" type="text" class="block w-full py-3 px-3 mt-2
                                  text-gray-800 appearance-none
                                  border-2 border-gray-100
                                  focus:text-gray-500 focus:outline-none focus:border-gray-200 rounded-md"
                                  placeholder='Username' name = 'username'
                               />
                          </div>
                          <p class="text-xs italic text-red-500"><ErrorMessage name="username" component="span"/></p>
                          <p id='usernameError' class="text-xs italic text-red-500 hidden"></p>

                          <div class="relative w-full">
                              <Field type='password' id="password" class="block w-full py-3 px-3 mt-2 
                                  text-gray-800 appearance-none
                                  border-2 border-gray-100
                                  focus:text-gray-500 focus:outline-none focus:border-gray-200 rounded-md" 
                                  placeholder='Password' name = 'password'
                              />
                                  <div class="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
                                      <p id='showHide' class="font-semibold hover:cursor-pointer noselect" onClick={togglePassword} >Show</p>
                                  </div>
                          </div>
                          <p class="text-xs italic text-red-500"><ErrorMessage name="password" component="span"/></p>
                          <p id='passwordError' class="text-xs italic text-red-500 hidden"></p>

                          <button type="submit" class="w-full py-3 mt-10 bg-[#063970] rounded-md
                              font-medium text-white uppercase
                              focus:outline-none hover:shadow-none">
                              Login
                          </button>
                      </Form>
                      </Formik>
                  </div>
          </div>
      </div>
    </div>
  )
}

export default OrganizationLogin;