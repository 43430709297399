import React from 'react';
import Navbar from '../../Navbar/Navbar';
import $ from 'jquery';

// images
import sdg3 from '../assets/sdg3.jpg';
import logo from '../../../assets/logo.png';
import {Helmet} from "react-helmet";

import Footer from '../../Footer/Footer';

function GoodHealth() {

    $(document).ready(() => {
        document.body.scrollTop = 0; 
        document.documentElement.scrollTop = 0;
    
      })

  return (
    <div className='bg-sky-50 noselect'>

        <Helmet>
            <title>TAHK - Good Health</title>
            <meta
                name="description"
                content="Ensure healthy lives and promote well-being for all at all ages"
            />
            <meta property="og:url" content={document.location.href} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content="TAHK - Good Health" />
            <meta property="og:description" content="Ensure healthy lives and promote well-being for all at all ages" />
            <meta property="og:image" content={logo}/>
            <link rel="image_src" href={logo} />
        </Helmet>

        <Navbar/>

        <section >
            <div class="max-w-7xl px-6 py-16 mx-0">
                <div class="items-center md:flex md:space-x-6">
                    <div class="md:w-1/2">
                        <div class="flex items-center justify-center">
                            <div class="max-w-md">
                                <img class="object-cover object-center w-full rounded-md shadow" style={{height: 500}}
                                    src={sdg3}/>
                            </div>
                        </div>
                    </div>
        
                    <div class="mt-8 md:mt-0 md:w-1/2">
                        <h2 class="max-w-xl text-2xl text-sky-800 font-bold md:text-4xl md:pl-4 uppercase">GOOD HEALTH AND WELL-BEING</h2>
                        <p class="max-w-xl mt-4 text-lime-800 text-lg md:pl-4">
                            Exposure to forests and trees boosts immunity. Research has reflected gardening reduces stress, lowers blood pressure, and improves mood. More trees can reduce harmful pollutants present in our air and helps to avoid asthma-related or heart diseases Creating fruit orchards across the country will help for good well-being and health.
                        </p>
                        
                        
                    </div>
                </div>
            </div>
        </section>

        <div id='footer' className='mt-12'>
        <Footer/>
        </div>
    </div>
  )
}

export default GoodHealth