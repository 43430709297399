import axios from "axios";


// const apiViewerUrl = "http://localhost:8080/viewer";

const apiViewerUrl = "https://server.tahk.in/viewer";


// const gcloudApiUserUrl = "https://tahk-server-dot-acoustic-spot-347802.el.r.appspot.com/admin";

const OrganizationService = {

    countryWiseUsers: () => {
        return axios.get(`${apiViewerUrl}/users`, {
            headers: {
                accessToken: sessionStorage.getItem('accessToken')
            }
        });
    },

    
}


export default OrganizationService;