import React from 'react';
import { Link } from 'react-router-dom';

// images
import logo from '../../assets/logo.png';

function Navbar() {

    window.onscroll = function() {scrollFunction()};

    function scrollFunction() {
        const navbar = document.getElementById('navbar');

        if(document.body.scrollTop > (navbar.offsetTop + navbar.offsetHeight) || document.documentElement.scrollTop > (navbar.offsetTop + navbar.offsetHeight)){
        navbar.classList.add( 'fixed', 'top-0', 'left-0', 'right-0', 'z-10');
        } else {
        navbar.classList.remove( 'fixed', 'top-0', 'left-0', 'right-0', 'z-10');
        }
    }

  return (
    <div>
        <div class="flex flex-col bg-gradient-to-b from-sky-500 via-sky-400 to-sky-200">
            {/* <!-- Page Scroll Progress --> */}
            {/* <div class="fixed inset-x-0 top-0 z-50 h-0.5 mt-0.5
                bg-blue-500" 
                // style={{width: `${percent}%`}}
            ></div> */}

            {/* // <!-- Navbar --> */}
            <nav id='navbar' class="flex justify-around py-2 bg-white/80
                w-full transition backdrop-blur-md shadow-md
                
                navbar navbar-expand-lg navbar-light">

            <div class="container-fluid">

                {/* <!-- Logo Container --> */}
                <div class="flex items-center ml-6">
                    {/* <!-- Logo --> */}
                    <Link to='/' class="cursor-pointer">
                        <h1 class="text-2xl font-medium text-blue-500">
                            <img class="h-20 object-cover"
                                src={logo} alt="Logo"/>
                        </h1>
                    </Link>
                </div>

                <button
                        class="navbar-toggler bg-gray-400 opacity-50"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarContent"
                        aria-controls="navbarContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                        >
                        <span class="navbar-toggler-icon"></span>
                </button>

                {/* <!-- Links Section --> */}
                <div id="navbarContent" class="items-center space-x-4 lg:space-x-8 flex collapse navbar-collapse">  
                    {/* overflow-y-auto md:max-w-md xl:max-w-5xl 2xl:max-w-7xl lg:max-w-3xl whitespace-nowrap */}
                    <ul class="navbar-nav lg:mx-auto mb-2 mb-lg-0 mt-2 lg:mt-0">
                    <li><Link to='/' class="flex text-gray-700 hover:text-blue-500
                        font-medium cursor-pointer transition-colors duration-300 mx-[1.2rem]">
                        Home
                    </Link></li>

                    <li><Link to='/briefs' class="flex text-gray-700 hover:text-blue-500
                        cursor-pointer transition-colors duration-300
                        font-medium mx-[1.2rem]">
                        Monthly Briefs
                    </Link></li>

                    <li><Link to='/sdg'
                        class="flex text-gray-600 hover:text-blue-500
                        font-medium cursor-pointer transition-colors duration-300 mx-[1.2rem]">
                        SDG's
                    </Link></li>

                    <li><Link to='/projects' class="flex text-gray-600 hover:text-blue-500
                        font-medium cursor-pointer transition-colors duration-300 mx-[1.2rem]">
                        Intrapreneurial Projects
                    </Link></li>   

                    <li><Link to='/certification' class="flex text-gray-700 hover:text-blue-500
                        cursor-pointer transition-colors duration-300
                        font-medium mx-[1.2rem]">
                        Certification
                    </Link></li>   

                    <li><a class="flex text-gray-600 hover:text-gray-600
                        font-medium cursor-pointer transition-colors duration-300 mx-[1.2rem]">
                        <div class="group inline-block">
                        <button
                        class="  flex items-center min-w-32"
                        >
                        <span class="pr-1 font-medium flex-1 hover:text-blue-500">World Wide</span>
                        <span>
                            <svg
                            class="fill-current h-4 w-4 transform group-hover:-rotate-180
                                transition duration-150 ease-in-out"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            >
                            <path
                                d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                            />
                            </svg>
                        </span>
                        </button>
                        <ul
                        class="bg-white border rounded transform scale-0 group-hover:scale-100 absolute 
                        transition duration-150 ease-in-out origin-top min-w-32 z-10"
                        >
                        <Link to='/india'><li class="rounded-sm px-3 py-1 hover:bg-gray-100">India</li></Link>
                        <Link to='/italy'><li class="rounded-sm px-3 py-1 hover:bg-gray-100">Italy</li></Link>
                        <Link to='/peru'><li class="rounded-sm px-3 py-1 hover:bg-gray-100">Peru</li></Link>
                        <Link to='/turkey'><li class="rounded-sm px-3 py-1 hover:bg-gray-100">Turkey</li></Link>
                        <Link to='/pakistan'><li class="rounded-sm px-3 py-1 hover:bg-gray-100">Pakistan</li></Link>
                        <Link to='/usa'><li class="rounded-sm px-3 py-1 hover:bg-gray-100">USA</li></Link>
                        <Link to='/uk'><li class="rounded-sm px-3 py-1 hover:bg-gray-100">UK</li></Link>
                        </ul>
                    </div>
                    </a></li>

                    <li><a class="flex text-gray-600 hover:text-gray-600
                        font-medium cursor-pointer transition-colors duration-300 mx-[1.2rem]">
                        <div class="group inline-block">
                        <button
                        class="  flex items-center min-w-32"
                        >
                        <span class="pr-1 font-medium flex-1 hover:text-blue-500">Join Us</span>
                        <span>
                            <svg
                            class="fill-current h-4 w-4 transform group-hover:-rotate-180
                                transition duration-150 ease-in-out"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            >
                            <path
                                d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                            />
                            </svg>
                        </span>
                        </button>
                        <ul
                        class="bg-white border rounded transform scale-0 group-hover:scale-100 absolute 
                        transition duration-150 ease-in-out origin-top min-w-32 z-10"
                        >
                        <Link to='/sip'><li class="rounded-sm px-3 py-1 hover:bg-gray-100">Sustainable Internship Program</li></Link>
                        <Link to='/tip'><li class="rounded-sm px-3 py-1 hover:bg-gray-100">Technology Internship Program</li></Link>
                        
                        </ul>
                    </div>
                    </a></li>

                    <li><Link to='/diary' class="flex text-gray-700 hover:text-blue-500
                        font-medium cursor-pointer transition-colors duration-300 mx-[1.2rem]">
                        Diary
                    </Link></li>
                    
                </ul>
                </div>            
                </div>
            </nav>
        </div>
    </div>
  )
}

export default Navbar