import React from 'react';
import Navbar from '../../Navbar/Navbar';
import $ from 'jquery';

// images
import sdg5 from '../assets/sdg5.jpg';

import Footer from '../../Footer/Footer';
import logo from '../../../assets/logo.png';
import {Helmet} from "react-helmet";

function GenderEquality() {

    $(document).ready(() => {
        document.body.scrollTop = 0; 
        document.documentElement.scrollTop = 0;
    
      })

  return (
    <div className='bg-sky-50 noselect'>

        <Helmet>
            <title>TAHK - Gender Equality</title>
            <meta
                name="description"
                content="Achieve gender equality and empower all women and girls"
            />
            <meta property="og:url" content={document.location.href} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content="TAHK - Gender Equality" />
            <meta property="og:description" content="Achieve gender equality and empower all women and girls" />
            <meta property="og:image" content={logo}/>
            <link rel="image_src" href={logo} />
        </Helmet>

        <Navbar/>

        <section >
            <div class="max-w-7xl px-6 py-16 mx-0">
                <div class="items-center md:flex md:space-x-6">
                    <div class="md:w-1/2">
                        <div class="flex items-center justify-center">
                            <div class="max-w-md">
                                <img class="object-cover object-center w-full rounded-md shadow" style={{height: 500}}
                                    src={sdg5}/>
                            </div>
                        </div>
                    </div>
        
                    <div class="mt-8 md:mt-0 md:w-1/2">
                        <h2 class="max-w-xl text-2xl text-sky-800 font-bold md:text-4xl md:pl-4 uppercase">Gender Equality</h2>
                        <p class="max-w-xl mt-4 text-lime-800 text-lg md:pl-4">
                            Gender inequality doesn't make sense on any level by marginalizing the rights of women we deny ourselves the opportunity to lift millions of men, women, and children out of poverty, not to mention the chance of a just and fair world. To turn promises into action we need to step it up now. We need public action to tackle inequalities and discrimination. Be it education, health, protection, or participation, all girls deserve equal opportunities and an empowering environment to thrive. TAHK provides the perfect platform for people across the globe to join hands towards this motive.
                        </p>
                        
                        
                    </div>
                </div>
            </div>
        </section>

        <div id='footer' className='mt-12'>
        <Footer/>
        </div>
    </div>
  )
}

export default GenderEquality